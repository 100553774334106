import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { TaskPerformanceService } from './task-performance.service';
import * as ObjectActions from './task-performance.actions';
import * as oeeAppReducer from '../../oee.reducer';
import { Store } from '@ngrx/store';
import { switchMap, catchError, map } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import {
  ITaskPerformanceResponse,
  ITaskPerformanceRequestParams,
  ITaskPerformanceFilter,
  ITaskPerformanceData,
  ITaskPerformanceDataAndCurrentShifts,
  ITaskPerformanceActivityHistory,
  ITaskPerformanceSite,
  IRequestBody,
  IRequestParamMap,
  ITaskPerformanceLine,
} from './task-performance.model';
import { HttpParams } from '@angular/common/http';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import * as _ from 'lodash';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { emptyAction } from '../../../../constants';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class TaskPerformanceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public service: TaskPerformanceService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getTaskPerformanceData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TASK_PERFORMANCE_DATA_LOADING),
      switchMap((objectData: ObjectActions.TaskPerformanceDataLoading) => {
        const requestParams: ITaskPerformanceRequestParams = TaskPerformanceEffects.generateRequestHttpParams(
          objectData.params,
        );

        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getActivityHistoryData(requestParams.activityHistoryParams).pipe(
          switchMap((response: GetManyResponseInterface<ITaskPerformanceActivityHistory>) => {
            return of(new ObjectActions.TaskPerformanceFetchData(requestParams, response.total));
          }),
          catchError((err: any) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  fetchData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TASK_PERFORMANCE_FETCH_DATA),
      switchMap((objectData: ObjectActions.TaskPerformanceFetchData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service
          .getTaskPerformanceData(
            HelperService.cloneDeep(objectData.params),
            HelperService.cloneDeep(objectData.activityHistoryTotalCount),
          )
          .pipe(
            map((objectData: ITaskPerformanceResponse) => {
              objectData.lines.forEach((line: ITaskPerformanceLine) => {
                this.entityTranslatorService.translate(line);
              });
              objectData.activityHistories.forEach((activityHistory: ITaskPerformanceActivityHistory) => {
                this.entityTranslatorService.translate(activityHistory);
              });

              return objectData;
            }),
            map(TaskPerformanceEffects.mapResponseData),
            switchMap((payload: ITaskPerformanceDataAndCurrentShifts) => {
              return of(new ObjectActions.TaskPerformanceDataLoaded(payload), new AppActions.HideLoader());
            }),
            catchError((err: any) => {
              return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  taskPerformanceDownloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TASK_PERFORMANCE_DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.TaskPerformanceDownloadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(HelperService.cloneDeep(objectData.worksheetsColumnDefinitions), objectData.excelData);

        return emptyAction;
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  private static generateRequestHttpParams(params: ITaskPerformanceFilter): ITaskPerformanceRequestParams {
    const httpParams: HttpParams = new HttpParams();

    const siteParams: HttpParams = httpParams
      .append('fields', 'preRunPhaseName,runPhaseName,postRunPhaseName')
      .append(
        's',
        JSON.stringify({
          id: { $in: params.siteId },
        }),
      )
      .append('useReplicaForGetIfAvailable', true);
    const lineParams: IRequestBody = TaskPerformanceEffects.getQueryBodyOfLine(params, 1, 5000);
    const activityHistoryParams: IRequestBody = TaskPerformanceEffects.getQueryBodyOfActivityHistory(params, 1, 1);
    let currentShiftParams: HttpParams = httpParams
      .append('siteIds', JSON.stringify(params.siteId))
      .append('useReplica', true);

    if (Array.isArray(params.lineIds)) {
      currentShiftParams = currentShiftParams.append('lineIds', JSON.stringify(params.lineIds));
    }

    return {
      lineParams,
      activityHistoryParams,
      siteParams,
      shiftParams: currentShiftParams,
      siteId: _.first(params.siteId),
    };
  }

  private static mapResponseData(payload: ITaskPerformanceResponse): ITaskPerformanceDataAndCurrentShifts {
    const taskPerformanceData: ITaskPerformanceData[] = [];
    const siteInfo: ITaskPerformanceSite = payload.site;
    const preRunPhaseNameLabel =
      siteInfo.preRunPhaseName && siteInfo.preRunPhaseName.trim() !== '' ? siteInfo.preRunPhaseName : null;
    const runPhaseNameLabel =
      siteInfo.runPhaseName && siteInfo.runPhaseName.trim() !== '' ? siteInfo.runPhaseName : null;
    const postRunPhaseNameLabel =
      siteInfo.postRunPhaseName && siteInfo.postRunPhaseName.trim() !== '' ? siteInfo.postRunPhaseName : null;

    for (const lineData of payload.lines) {
      taskPerformanceData.push({
        taskId: lineData.currentTask?.id,
        taskName: lineData.currentTask?.title,
        activityId: lineData.currentActivity?.id,
        activityName: lineData.currentActivity?.name,
        lineId: lineData.id,
        lineTitle: lineData.title,
        productId: lineData.currentWorkOrder?.product?.productId,
        productDescription: lineData.currentWorkOrder?.product?.description,
        phaseId: lineData.selectedPhaseId,
        phaseName:
          lineData.selectedPhaseId === 1
            ? preRunPhaseNameLabel
            : lineData.selectedPhaseId === 2
            ? postRunPhaseNameLabel
            : lineData.selectedPhaseId === 3
            ? runPhaseNameLabel
            : null,
        shiftDay: null,
        startDate: lineData.timer,
        endDate: null,
        duration: null,
        woNumber: lineData.currentWorkOrder?.woNumber,
        ucl: lineData.currentTask?.ucl,
        lcl: lineData.currentTask?.lcl,
        activitySequenceId: lineData.currentActivity?.activitySequenceId,
        taskTarget: lineData.currentTask?.target,
        workOrderId: lineData.currentWorkOrder?.id,
      });
    }

    for (const activityHistoryData of payload.activityHistories) {
      taskPerformanceData.push({
        taskId: activityHistoryData.task?.id,
        taskName: activityHistoryData.task?.title,
        activityId: activityHistoryData.activity?.id,
        activityName: activityHistoryData.activity?.name,
        lineId: activityHistoryData.line?.id,
        lineTitle: activityHistoryData.line?.title,
        productId: activityHistoryData.workOrder?.product?.productId,
        productDescription: activityHistoryData.workOrder?.product?.description,
        phaseId: activityHistoryData.phaseId,
        phaseName:
          activityHistoryData.phaseId === 1
            ? preRunPhaseNameLabel
            : activityHistoryData.phaseId === 2
            ? postRunPhaseNameLabel
            : activityHistoryData.phaseId === 3
            ? runPhaseNameLabel
            : null,
        shiftDay: activityHistoryData.shiftDay,
        startDate: activityHistoryData.start,
        endDate: activityHistoryData.end,
        duration: null,
        woNumber: activityHistoryData.workOrder?.woNumber,
        ucl: activityHistoryData.task?.ucl,
        lcl: activityHistoryData.task?.lcl,
        activitySequenceId: activityHistoryData.activitySequenceId,
        taskTarget: activityHistoryData.task?.target,
        workOrderId: activityHistoryData.workOrder?.id,
      });
    }

    return { taskPerformanceData, shifts: payload.shifts };
  }

  private static getQueryBodyOfLine(params: ITaskPerformanceFilter, page: number, limit: number): IRequestBody {
    const requestParamMap: IRequestParamMap[] = [
      {
        property: 'lineIds',
        condition: '$in',
        entityProperty: 'id',
      },
      {
        property: 'selectedActivity',
        condition: '$in',
        entityProperty: 'activityIds',
      },
      {
        property: 'equipmentIds',
        condition: '$in',
        entityProperty: 'currentTask.equipment.id',
      },
      {
        property: 'taskTitles',
        condition: '$in',
        entityProperty: 'currentTask.title',
      },
      {
        property: 'productId',
        condition: '$in',
        entityProperty: 'currentWorkOrder.product.id',
      },
    ];
    const andConditions: object[] = [{ timer: { $lt: params.dateRange.endDate } }];

    for (const reqParam of requestParamMap) {
      const data = params[reqParam.property];

      if (data !== -1) {
        andConditions.push({ [reqParam.entityProperty]: { [reqParam.condition]: data } });
      }
    }

    return {
      page,
      offset: limit,
      search: {
        $and: andConditions,
      },
      fields: ['title', 'timer', 'selectedPhaseId'],
      join: [
        { field: 'currentTask', select: ['title', 'ucl', 'lcl', 'target'] },
        { field: 'currentTask.equipment', select: ['id'] },
        { field: 'currentWorkOrder', select: ['woNumber'] },
        { field: 'currentWorkOrder.product', select: ['productId', 'description'] },
        { field: 'currentActivity', select: ['name'] },
      ],
      useReplicaForGetIfAvailable: true,
    };
  }

  private static getQueryBodyOfActivityHistory(
    params: ITaskPerformanceFilter,
    page: number,
    limit: number,
  ): IRequestBody {
    const requestParamMap: IRequestParamMap[] = [
      {
        property: 'lineIds',
        condition: '$in',
        entityProperty: 'line',
      },
      {
        property: 'activityIds',
        condition: '$in',
        entityProperty: 'activityId',
      },
      {
        property: 'equipmentIds',
        condition: '$in',
        entityProperty: 'task.equipment.id',
      },
      {
        property: 'shiftIds',
        condition: '$in',
        entityProperty: 'shiftId',
      },
      {
        property: 'siteId',
        condition: '$in',
        entityProperty: 'site',
      },
      {
        property: 'taskTitles',
        condition: '$in',
        entityProperty: 'task.title',
      },
      {
        property: 'productId',
        condition: '$in',
        entityProperty: 'workOrder.product.id',
      },
    ];
    const andConditions: object[] = [
      { start: { $lt: params.dateRange.endDate } },
      { end: { $gt: params.dateRange.startDate } },
    ];

    for (const reqParam of requestParamMap) {
      const data = params[reqParam.property];

      if (data !== -1) {
        andConditions.push({ [reqParam.entityProperty]: { [reqParam.condition]: data } });
      }
    }

    return {
      page,
      offset: limit,
      search: {
        $and: andConditions,
      },
      fields: ['start,end,phaseId,shiftDay'],
      join: [
        { field: 'task', select: ['title', 'ucl', 'lcl', 'target'] },
        { field: 'line', select: ['title'] },
        { field: 'activity', select: ['name'] },
        { field: 'workOrder', select: ['woNumber'] },
        { field: 'workOrder.product', select: ['productId', 'description'] },
        { field: 'task.equipment', select: ['id'] },
      ],
      useReplicaForGetIfAvailable: true,
    };
  }
}
