import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, ICurrentShift, ShiftDetailInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { DateRangeFilterInterface } from '../../../view/reports/check-in-logs/check-in-logs.model';
import { Observable } from 'rxjs';
import { ResponseArrayInterface } from '../../model/interface/generic-api-response.model';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ShiftService implements FilterServiceInterface {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly helperService: HelperService,
  ) {}

  getData(): Promise<ShiftDetailInterface[]> {
    return this.getShifts();
  }

  getBusinessShiftData(
    dateRange?: DateRangeFilterInterface,
    siteIds?: number[],
    lineIds?: number[],
  ): Promise<ShiftDetailInterface[]> {
    return this.getShifts(dateRange, siteIds, lineIds);
  }

  getShifts(
    dateRange?: DateRangeFilterInterface,
    siteIds?: number[],
    lineIds?: number[],
  ): Promise<ShiftDetailInterface[]> {
    let params = new HttpParams();

    if (dateRange) {
      params = params.set(
        'dateRange',
        `["${this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(
          dateRange.startDate,
        )}", "${this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(dateRange.endDate)}"]`,
      );
    }

    if (siteIds && siteIds.length > 0) {
      params = params.set('siteIds', JSON.stringify(siteIds));
    }

    if (lineIds && lineIds.length > 0) {
      params = params.set('lineIds', JSON.stringify(lineIds));
    }

    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/scheduler/shifts/shift-details`, { params })
        .subscribe((response: GetManyResponseInterface<ShiftDetailInterface>) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
            return response.data;
          }
          reject();
          return null;
        });
    });
  }

  getShiftsData(): Observable<GetManyResponseInterface<ShiftDetailInterface>> {
    return this.http.get<GetManyResponseInterface<ShiftDetailInterface>>(
      `${this.baseUrl}/scheduler/shifts/shift-details`,
    );
  }

  getCurrentShifts(params?: HttpParams): Observable<ResponseArrayInterface<ICurrentShift>> {
    return this.http.get<ResponseArrayInterface<ICurrentShift>>(`${this.baseUrl}/lines/current-shifts`, { params });
  }
}
