<div class="row">
  <div class="col-12">
    <div class="text-right mb-2" [hidden]="!showTaskListModeButtons">
      <scw-mat-button-group
        size="xs"
        class="d-block"
        [buttons]="listModeButtons"
        [inputModel]="listMode"
        (inputModelChange)="onListModeChanged($event)"
      ></scw-mat-button-group>
    </div>
  </div>
  <div class="col-12">
    <scw-mat-search
      placeholder="{{ 'main.equipmentTaskSelection.search' | translate }}"
      [minLength]="0"
      [(inputModel)]="searchText"
      (onSearch)="onSearched()"
    ></scw-mat-search>
  </div>
  <div class="col-12" *ngIf="selectedNodes.length > 0">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mt-2">
        <li class="breadcrumb-item">
          <a (click)="onRootIconClicked()" class="cursor-pointer"><em class="fas fa-home"></em></a>
        </li>
        <li
          class="breadcrumb-item"
          *ngFor="let node of selectedNodes; let i = index"
          [class.active]="i === selectedNodes.length - 1"
        >
          <a (click)="onBreadcrumbItemClicked(node, i)" class="cursor-pointer"
            ><em [class]="node.icon"></em> {{ node.title }}</a
          >
        </li>
      </ol>
    </nav>
  </div>
</div>
<h5 *ngIf="nodeGroups.length === 0 && isLoaded$; else nodeSelection" class="scw-header text-center text-secondary mt-3">
  {{ 'main.equipmentTaskSelection.noTaskAssigned' | translate }}
</h5>
<ng-template #nodeSelection>
  <div *ngFor="let group of nodeGroups" class="row mt-2">
    <div *ngIf="nodeGroups.length > 1" class="col-12 mb-2">
      <h5> {{ group.name | translate }}</h5>
    </div>
    <div *ngFor="let node of group.nodes" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
      <scw-mat-button type="standard" size="lg" [colors]="{ background: node.meta?.colors.background , text: node.meta?.colors.text, hover: node.meta?.colors.hover }" [block]="true" (onClick)="onNodeItemClicked(node)">
        <h5 class="node-button-text">
          <em [class]="node.icon"></em>
          {{
          node.title
          }}
        </h5>
        <div
          *ngIf="(listMode === 'task_based' || searchText) && node.subTitle"
          class="node-button-text node-button-info-text"
        >
          <em [class]="node.subIcon"></em>
          {{ node.subTitle }}
        </div>
      </scw-mat-button>
    </div>
  </div>
</ng-template>
