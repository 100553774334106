/**
 * This helper service only contains methods that are used in multiple places and doesn't belong to any name spaces.
 * Please do not define any module related methods here.
 */
import * as _ from 'lodash';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { NonEmptyArray } from '../model/interface/generic.model';
import { WINDOW } from '../../window.providers';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../store/oee.reducer';
import { User } from '../../store/user/model';
import { DecimalHelper } from '../helper/decimal/decimal-helper';
import { HelperService } from './helper.service';
import { TDecimalSeparator, TThousandSeparator } from '../../../constants.model';
import { IDatatableFetchParams } from '../component/datatable/datatable.model';

@Injectable({ providedIn: 'root' })
export class GenericHelperService {
  public isTouchDevice: boolean;
  public windowWidth: number;

  private decimalSeparator: TDecimalSeparator;
  private thousandSeparator: TThousandSeparator;

  constructor(@Inject(WINDOW) private readonly window: Window, private readonly store: Store<OeeAppState>) {
    this.isTouchDevice = 'ontouchstart' in document.documentElement;
    this.windowWidth = window.innerWidth;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });

    this.store.select('user').subscribe((state: User) => {
      this.decimalSeparator = state.decimalSeparator;
      this.thousandSeparator = state.thousandSeparator;
    });
  }

  public static allElementsDefined<T>(array: readonly (T | undefined)[]): array is T[] {
    return !array.includes(undefined);
  }

  public static mergeArrayObjectsBy<X, Y>(originalArray: X[], updateArray: Y[], iterator: string): (X & Partial<Y>)[] {
    return HelperService.cloneDeep(originalArray).map((item) => {
      const updateArrayIndex = updateArray.find((element) => element[iterator] === item[iterator]);
      return updateArrayIndex ? Object.assign({}, item, HelperService.cloneDeep(updateArrayIndex)) : item;
    });
  }

  public static transformToBoolean(value: unknown): boolean | unknown {
    if (_.isNil(value) || _.isBoolean(value)) {
      return value;
    }

    try {
      const parsedValue = JSON.parse(String(value));

      return [1, 0, true, false].includes(parsedValue) ? Boolean(parsedValue) : value;
    } catch {
      return value;
    }
  }

  public static enumToArray<T extends object>(enumeration: T): T[keyof T][] {
    return Object.keys(enumeration)
      .filter((key: string) => isNaN(Number(key)))
      .map((key: string) => enumeration[key]);
  }

  public static numberToBooleanString(value: string | number): string {
    return String(Boolean(Number(value)));
  }

  public static selectOrUnselectAllCheckboxes(
    isSelectAll: boolean,
    elementRef: ElementRef,
    componentName: string,
    pageData: { id: number | string }[],
  ): void {
    if (elementRef.nativeElement.querySelector(`[id^="${componentName}-checkbox-"]`) !== null) {
      pageData.forEach((item) => {
        const checkbox = _.head(
          document.getElementById(`${componentName}-checkbox-${item.id}`)?.getElementsByTagName('input'),
        );

        if (!checkbox) {
          return;
        }

        if ((isSelectAll && !checkbox?.checked) || (!isSelectAll && checkbox?.checked)) {
          checkbox?.click();
        }
      });
    }
  }

  public static nest<T>(seq: unknown, keys: NonEmptyArray<string>): T {
    if (!keys.length) {
      return seq as T;
    }

    const first: string = keys[0];
    const rest: string[] = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
      return GenericHelperService.nest(value, rest as NonEmptyArray<string>);
    });
  }

  public formatNumber(value: number): number | string {
    if (_.isNil(value) || typeof value !== 'number') {
      return null;
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 20,
    });

    const [intPart, decimalPart] = formatter.format(value).split('.');
    return [intPart.replace(/[,]/g, this.thousandSeparator), ...(decimalPart ? [decimalPart] : [])].join(
      this.decimalSeparator,
    );
  }

  public static dropdownOptionCompareFunction(
    item1: { name?: string; title?: string },
    item2: { name?: string; title?: string },
  ): number {
    return `${item1.name ?? item1.title}`.localeCompare(`${item2.name ?? item2.title}`, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  /**
   * Lodash _.get function with stricter truthiness checking.
   */
  public static strictGet(
    object: unknown,
    path: string,
    defaultValue: unknown,
    transformer: (value: unknown) => unknown = (value: unknown) => value,
  ): unknown {
    const result: unknown = _.get(object, path, defaultValue);

    if (!_.isNumber(result) && _.isEmpty(result)) {
      return defaultValue;
    }

    return transformer(result);
  }

  public static sumByProperties<T, Y extends Iterable<string>>(
    collection: T[],
    properties: Y,
    sumAsNumber: boolean = false,
  ): // @ts-ignore
  Record<Y[number], any> {
    // @ts-ignore
    let result = _.zipObject(properties) as Record<Y[number], any>;

    result = (collection ?? []).reduce((total, item) => {
      for (const property of properties) {
        if (sumAsNumber) {
          total[property] = (total[property] ?? 0) + Number(item[property] ?? 0);
          continue;
        }

        total[property] = DecimalHelper.add1(
          _.isNil(total[property]) ? '0' : String(total[property]),
          _.isNil(item[property]) ? '0' : String(item[property]),
        );
      }

      return total;
    }, result);

    return result;
  }

  public static moveItemAsLastInArray<T>(array: T[], element: T): T[] {
    const index = array.indexOf(element);

    if (index === -1) {
      return array;
    }

    return [...array.slice(0, index), ...array.slice(index + 1), element];
  }

  public static percentageOfChange(value: number, min: number, max: number): number {
    const mid: number = (min + max) / 2;
    const changePercentage: number = ((value - mid) / mid) * 100;

    return _.round((changePercentage + 100) / 2 / 100, 4) * 100;
  }

  public resetPage = <T extends IDatatableFetchParams>([oldParameters, newParameters]: [T, T]): T => {
    if (
      (oldParameters.searchText || '') !== (newParameters.searchText || '') ||
      !_.isEqual(oldParameters.filterCard, newParameters.filterCard)
    ) {
      newParameters.pagination.page = 1;
    }

    return newParameters;
  };

  public static tryParseJson<T>(
    jsonString: string | null | undefined,
    fallbackValue: any = false,
  ): T | null | undefined {
    if (_.isNil(jsonString)) {
      return jsonString as null | undefined;
    }

    try {
      return JSON.parse(jsonString);
    } catch {
      return fallbackValue;
    }
  }
}
