import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { IElementVisibilityTranslations, PageHeaderInterface } from '../../service/page-header/page-header.model';
import { LayoutConstants } from '../../../layout/layout-constants';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import {
  EHeaderExceptionType,
  IHeaderExceptionIndicator,
  PageHeaderStateInterface,
  pageHeaderExceptionCustomClasses,
} from '../../../store/page-header/page-header.model';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends LayoutConstants implements OnDestroy, OnChanges {
  @Input() pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
    showPageConfiguration: false,
    showCountDownButton: false,
    showFilterBarVisibilityButton: false,
    showPrintFunctionalityButton: false,
    showModeSwitchButton: false,
    breadcrumbs: [],
    showGeneralConfiguration: false,
    showElementVisibilityButton: false,
    elementVisibilityTranslations: null,
  };
  private readonly subscriptions: Subscription[] = [];
  public mode: boolean = false;

  /**
   * @deprecated - use showPageConfigButton instead on {{component}}-routing.module.ts
   */
  public readonly pageConfigPages: string[] = [
    'scheduler_scenario',
    'tasks',
    'activity_logs',
    'production_review',
    'kpi_view',
    'labor_logs_report',
    'asset_logs_report',
    'shift_summary',
    'asset_view',
    'alerts',
    'maintenance_performance',
  ];

  /**
   * @deprecated - use showFilterBarVisibilityButton instead on {{component}}-routing.module.ts
   */
  public readonly pagesWithFilterBarVisibilityButton: string[] = [
    'work_orders',
    'production_review',
    'asset_view',
    'activity_timeline',
    'production_view',
    'task_performance_report',
  ];

  /**
   * @deprecated - use showCountDownButton instead on {{component}}-routing.module.ts
   */
  public readonly pagesWithCountdownButton: string[] = [
    'work_orders',
    'production_review',
    'asset_view',
    'activity_timeline',
    'production_view',
  ];

  /**
   * @deprecated - use showPrintFunctionalityButton instead on {{component}}-routing.module.ts
   */
  public readonly pagesWithPrintFunctionality: string[] = [
    'root_cause_analysis_report',
    'work_order_performance_report',
    'deep_dive_analysis_report',
    'task_performance_report',
    'capacity_report_title',
    'oee_calendar_report',
    'my_report',
    'job-performance',
  ];

  public readonly pagesWithModeSwitchButton: string[] = ['my_report'];
  public readonly pagesWithDashboardModeButton: string[] = ['activity_logs', 'activity_timeline_report'];
  public readonly pagesWithGeneralConfiguration: string[] = ['line_view'];

  public showPageConfig: boolean = false;
  public showFilterBarVisibilityButton: boolean = false;
  public showCountDownButton: boolean = false;
  public showPrintFunctionalityButton: boolean = false;
  public showModeSwitchButton: boolean = false;
  public hideHeaderActions: boolean = false;
  public isHeaderVisible: boolean = true;
  public showDashboardModeButton: boolean = true;
  public headerExceptionIndicator: IHeaderExceptionIndicator | null = null;
  public customHeaderStyles: string[] = [];
  public showGeneralConfiguration: boolean = false;
  public showElementVisibilityButton: boolean = false;
  public elementVisibilityTranslations: IElementVisibilityTranslations | null = null;

  constructor(private readonly store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('pageHeaderStore').subscribe((state: PageHeaderStateInterface) => {
        this.hideHeaderActions = state.hideHeaderActions;
        this.isHeaderVisible = state.isHeaderVisible;
        this.mode = state.isDashboardMode;
        this.headerExceptionIndicator = state.headerExceptionIndicator;
        this.addExceptionsStylesToCustomStyles();
        this.updateShowFilterBarVisibilityButton();
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('pageHeader')) {
      const pageTitle: string = this.pageHeader.titleKey;

      this.showPageConfig = this.pageHeader.showPageConfiguration || this.pageConfigPages.includes(pageTitle);
      this.showCountDownButton =
        (this.pageHeader.showCountDownButton || this.pagesWithCountdownButton.includes(pageTitle)) && this.mode;
      this.showPrintFunctionalityButton =
        this.pageHeader.showPrintFunctionalityButton || this.pagesWithPrintFunctionality.includes(pageTitle);
      this.showModeSwitchButton =
        this.pageHeader.showModeSwitchButton || this.pagesWithModeSwitchButton.includes(pageTitle);
      this.showDashboardModeButton =
        this.pageHeader.showDashboardModeButton || this.pagesWithDashboardModeButton.includes(pageTitle);
      this.updateShowFilterBarVisibilityButton();
      this.showGeneralConfiguration =
          this.pageHeader.showGeneralConfiguration || this.pagesWithGeneralConfiguration.includes(pageTitle);
      this.showElementVisibilityButton = this.pageHeader.showElementVisibilityButton;
      this.elementVisibilityTranslations = this.pageHeader.elementVisibilityTranslations;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });

    this.headerExceptionIndicator = null;
    this.customHeaderStyles = [];
  }

  private addExceptionsStylesToCustomStyles(): void {
    if (!this.headerExceptionIndicator) {
      this.removeHeaderExceptionStylesFromCustomHeaderStyles(EHeaderExceptionType.WARNING);
      return;
    }

    switch (this.headerExceptionIndicator.type) {
      case EHeaderExceptionType.WARNING:
        this.customHeaderStyles.push(...pageHeaderExceptionCustomClasses.warning);
        this.pageHeader.icon = 'fas fa-exclamation-circle';
        break;
    }
  }

  private removeHeaderExceptionStylesFromCustomHeaderStyles(exceptionType: EHeaderExceptionType): void {
    this.customHeaderStyles = this.customHeaderStyles.filter((value: string) => {
      return pageHeaderExceptionCustomClasses[exceptionType].indexOf(value) === -1;
    });
  }

  private updateShowFilterBarVisibilityButton(): void {
    const pageTitle: string = this.pageHeader.titleKey;

    this.showFilterBarVisibilityButton =
      (this.pageHeader.showFilterBarVisibilityButton || this.pagesWithFilterBarVisibilityButton.includes(pageTitle)) &&
      !(this.mode && pageTitle === 'production_review') &&
      !(!this.mode && pageTitle === 'line_view');
  }
}
