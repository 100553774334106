import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivityLogsResponseInterface, IActivityLogsRequest } from '../root-cause-analysis/root-cause-analysis.model';
import {
  IOpcMessageDataResponseInterface,
  IOpcMessageInspectionActivityLogsInterface,
  IOpcMessageInspectionFilter,
  IOpcMessageInspectionTableData,
} from '../../../view/reports/opc-message-inspection/opc-message-inspection.model';
import * as moment from 'moment-timezone';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './opc-message-inspection.actions';
import { Observable, Subject } from 'rxjs';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ValueType } from 'exceljs';
import { takeUntil } from 'rxjs/operators';
import { IOpcTagListResponse } from '../../../shared/service/filter/service.class';

@Injectable({
  providedIn: 'root',
})
export class OpcMessageInspectionService {
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly routes = {
    activityLogs: `${this.api}/activity-histories/activity-logs`,
    opcMessageInspection: `${this.api}/lines/opc-message-inspection`,
    opcTagList: `${this.api}/lines/opc-tag-list`,
  };
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  constructor(
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly excelHelper: ExcelHelperService,
    private readonly translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat$ = state.dateFormat;
          this.timeFormat$ = state.dateTimeFormat;

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getActivityLogsData(
    params: IOpcMessageInspectionActivityLogsInterface,
  ): Observable<BaseOneResponseInterface<ActivityLogsResponseInterface>> {
    const activityLogsParams: IActivityLogsRequest = {
      isBusinessDate: false,
      start: params.dateRange.startDate.format(mysqlTimestampFormat),
      end: params.dateRange.endDate.format(mysqlTimestampFormat),
      sites: params.siteId,
      lines: params.lineId,
      lineTypes: -1,
      activities: params.activityIds,
      products: -1,
      rootCauseGroups: -1,
      shifts: -1,
      advancedFilterPage: 'opc-message-inspection',
      advancedFilterParams: JSON.stringify({ $and: [] }),
      workOrders: [-1],
      tasks: params.taskTitles,
    };

    return this.http.post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(
      `${this.routes.activityLogs}`,
      activityLogsParams,
      { headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }) },
    );
  }

  public getOpcTagList(options?: HttpParams): Observable<IOpcTagListResponse> {
    return this.http.get<IOpcTagListResponse>(`${this.routes.opcTagList}`, { params: options });
  }

  public getOpcMessageInspectionData(
    params: IOpcMessageInspectionFilter,
  ): Observable<BaseOneResponseInterface<IOpcMessageDataResponseInterface>> {
    const opcMessageInspectionParams: HttpParams = new HttpParams()
      .set('startDate', params.dateRange.startDate.format(mysqlTimestampFormat))
      .set('endDate', params.dateRange.endDate.format(mysqlTimestampFormat))
      .set('siteId', params.siteId)
      .set('lineId', params.lineId)
      .set('opcTags', params.opcTag.join(','));

    return this.http.get<BaseOneResponseInterface<IOpcMessageDataResponseInterface>>(
      `${this.routes.opcMessageInspection}`,
      {
        params: opcMessageInspectionParams,
      },
    );
  }

  private getOpcExcelColumns(withErrorColumn: boolean): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('opcMessageInspection.excel.column.opcId'),
          key: 'opcId',
          width: 30,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('opcMessageInspection.excel.column.opcTag'),
          key: 'opcTag',
          width: 55,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('opcMessageInspection.excel.column.opcUnit'),
          key: 'opcUnit',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('opcMessageInspection.excel.column.opcValue'),
          key: 'opcValue',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('opcMessageInspection.excel.column.opcTime'),
          key: 'opcTime',
          width: ExcelColumnWidthEnum.DATE_TIME,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('opcMessageInspection.excel.column.arrivalTime'),
          key: 'opcArrivalTime',
          width: ExcelColumnWidthEnum.DATE_TIME,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);
    return excelColumns;
  }

  public formatExcelValues(excelData: IOpcMessageInspectionTableData[] = []): IOpcMessageInspectionTableData[] {
    return excelData.map((row: IOpcMessageInspectionTableData) => ({
      ...row,
      opcId: String(row.opcId),
      opcTag: String(row.opcTag),
      opcUnit: String(row.opcUnit),
      opcValue: String(row.opcValue),
      opcTime: String(row.opcTime),
      opcArrivalTime: String(row.opcArrivalTime),
    }));
  }

  public downloadExcel(data: IOpcMessageInspectionTableData[]): void {
    const sheetTitle: string = this.translate.instant('excel.opcMessageInspection');
    const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;

    const excelOptions: CreateExcelInterface = this.getOpcExcelColumns(false);

    excelOptions.data = this.formatExcelValues(data);

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('excel.opcMessageInspection'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelOptions,
        withData: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: excelOptions.data.length + 1,
      },
    ];

    this.excelHelper.createExcel(excelName, { name: 'opcMessageInspection', withData: true }, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
      () => {
        this.store.dispatch(new ObjectActions.DownloadOpcMessageInspectionExcelCompleted());
      },
      () => {
        this.store.dispatch(new ObjectActions.OpcMessageInspectionFetchError([]));
      },
    );
  }
}
