import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { UpdateData, serverTimestamp } from 'firebase/firestore';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  Observable,
  catchError,
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import { defaultAfter } from 'src/app/shared/helper/rxjs-utils';
import {
  INotificationFeedItem,
  IUpdatedDocument,
} from 'src/app/shared/service/notification-feed/notification-feed.model';
import { NotificationFeedService } from 'src/app/shared/service/notification-feed/notification-feed.service';
import * as NotificationFeedActions from 'src/app/store/notification-feed/notification-feed.actions';
import { selectNotificationOnlyShowUnread } from 'src/app/store/notification-feed/notification-feed.selectors';
import { selectUserLanguage, selectUserTimezone } from 'src/app/store/user/user.selectors';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';

interface INotificationFeedLocalState {
  error: boolean;
  modalOpen: boolean;
}

@Injectable()
export class NotificationFeedStore extends ComponentStore<INotificationFeedLocalState> {
  public readonly allNotifications$: Observable<readonly INotificationFeedItem[]> = this.service
    .getNotifications()
    .pipe(distinctUntilChanged(_.isEqual));

  public readonly onlyShowUnread$: Observable<boolean> = this.store.select(selectNotificationOnlyShowUnread);

  public readonly visibleNotifications$: Observable<readonly INotificationFeedItem[]> = combineLatest([
    this.onlyShowUnread$,
    this.allNotifications$,
  ]).pipe(
    map(([onlyShowUnread, notifications]: [boolean, readonly INotificationFeedItem[]]) =>
      onlyShowUnread
        ? notifications.filter((notification: INotificationFeedItem) => !notification.readAt)
        : notifications,
    ),
    catchError(() => {
      this.setError(true);
      return of([]);
    }),
  );

  public readonly notificationsAndTimezone$: Observable<[readonly INotificationFeedItem[], string]> = this.select(
    (state) => state.modalOpen,
  ).pipe(
    filter((modalOpen) => modalOpen),
    switchMap(() => this.visibleNotifications$),
    combineLatestWith(
      this.store.select(selectUserTimezone).pipe(filter((timezone): timezone is string => timezone !== undefined)),
    ),
  );

  public readonly todayNotifications$: Observable<readonly INotificationFeedItem[]> =
    this.notificationsAndTimezone$.pipe(
      map(([notifications, timezone]) =>
        this.filterNotificationsByTime(notifications, { lower: this.startOfToday(timezone) }),
      ),
    );

  public readonly yesterdayNotifications$: Observable<readonly INotificationFeedItem[]> =
    this.notificationsAndTimezone$.pipe(
      map(([notifications, timezone]) =>
        this.filterNotificationsByTime(notifications, {
          lower: this.startOfYesterday(timezone),
          upper: this.startOfToday(timezone),
        }),
      ),
    );

  public readonly olderNotifications$: Observable<readonly INotificationFeedItem[]> =
    this.notificationsAndTimezone$.pipe(
      map(([notifications, timezone]) =>
        this.filterNotificationsByTime(notifications, { upper: this.startOfYesterday(timezone) }),
      ),
    );

  public readonly unopenedNotifications$: Observable<readonly INotificationFeedItem[]> =
    this.visibleNotifications$.pipe(
      map((notificationItems: readonly INotificationFeedItem[]) =>
        notificationItems.filter((item: INotificationFeedItem) => !item.openedAt),
      ),
    );

  public readonly unreadNotifications$: Observable<readonly INotificationFeedItem[]> = this.visibleNotifications$.pipe(
    map((notifications: readonly INotificationFeedItem[]) =>
      notifications.filter((notification: INotificationFeedItem) => !notification.readAt),
    ),
  );

  public readonly error$: Observable<boolean> = this.select((state: INotificationFeedLocalState) => state.error);

  public readonly userLanguage$: Observable<string> = this.select(
    this.store.select(selectUserLanguage).pipe(defaultAfter(1000, 'en-US')),
    (value) => value,
  );

  public setModalOpen = this.updater<boolean>((state, modalOpen) => {
    if (modalOpen) {
      this.markAllAsOpened();
    }

    return { ...state, modalOpen };
  });

  private readonly setError = this.updater((state: INotificationFeedLocalState, error: boolean) => ({
    ...state,
    error,
  }));

  constructor(private readonly service: NotificationFeedService, private readonly store: Store<OeeAppState>) {
    super({ error: false, modalOpen: false });
  }

  public markAllAsRead(): void {
    this.unreadNotifications$.pipe(take(1)).subscribe((notifications) => {
      const docs: readonly IUpdatedDocument[] = notifications.map((notification: INotificationFeedItem) => {
        const updateData: UpdateData<INotificationFeedItem> = { readAt: serverTimestamp() };

        if (notification.openedAt === null) {
          updateData.openedAt = serverTimestamp();
        }

        return { id: notification.docId, updateData };
      });
      this.service.bulkUpdate(docs);
    });
  }

  public setOnlyShowUnread(onlyShowUnread: boolean): void {
    this.store.dispatch(NotificationFeedActions.setOnlyShowUnread({ onlyShowUnread }));
  }

  public setRead(notification: INotificationFeedItem, read: boolean): void {
    const updateData: UpdateData<INotificationFeedItem> = { readAt: read ? serverTimestamp() : null };

    if (read && notification.openedAt === null) {
      updateData.openedAt = serverTimestamp();
    }

    this.service.update(notification.docId, updateData);
  }

  private filterNotificationsByTime(
    notifications: readonly INotificationFeedItem[],
    limits: { lower?: Moment; upper?: Moment },
  ): readonly INotificationFeedItem[] {
    return notifications.filter((notification) => {
      const createdAt: Moment = moment(notification.createdAt.toDate());
      return (!limits.lower || createdAt >= limits.lower) && (!limits.upper || createdAt < limits.upper);
    });
  }

  private markAllAsOpened(): void {
    this.unopenedNotifications$.pipe(take(1)).subscribe((notifications) => {
      const updateData: UpdateData<INotificationFeedItem> = { openedAt: serverTimestamp() };
      const docs: readonly IUpdatedDocument[] = notifications.map((notification: INotificationFeedItem) => ({
        id: notification.docId,
        updateData,
      }));
      this.service.bulkUpdate(docs);
    });
  }

  private startOfToday(timezone: string): Moment {
    return moment().tz(timezone).startOf('d');
  }

  private startOfYesterday(timezone: string): Moment {
    return this.startOfToday(timezone).subtract(1, 'd');
  }
}
