import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LaborSchedulerService } from '../../shared/service/labor-scheduler/labor-scheduler.service';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as LaborSchedulerActions from './labor-scheduler.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import * as AppActions from '../app/actions';
import {
  AllSettledResolvedInterface,
  ResponseArrayInterface,
} from '../../shared/model/interface/generic-api-response.model';
import {
  FormattedSaveLaborSchedulerItemInterface,
  LaborSchedulerGanttAllItemsResponseInterface,
  ILaborSchedulerItemResponse,
  ITeamsResponse,
} from '../../shared/service/labor-scheduler/labor-scheduler.interface';

@Injectable()
export class LaborSchedulerEffects {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly http: HttpClient,
    private readonly service: LaborSchedulerService,
  ) {}

  $loadLaborSchedulerItems = createEffect(() =>
    this.actions$.pipe(
      ofType(LaborSchedulerActions.LaborSchedulerActionTypes.LoadLaborSchedulerItems),
      switchMap((objectData: LaborSchedulerActions.LoadLaborSchedulerItems) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.loadLaborSchedulerItems(objectData.scenarioId).pipe(
          switchMap((response: ResponseArrayInterface<ILaborSchedulerItemResponse>) => {
            return of(new LaborSchedulerActions.LaborSchedulerItemsLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadLaborSchedulerGanttAllItems = createEffect(() =>
    this.actions$.pipe(
      ofType(LaborSchedulerActions.LaborSchedulerActionTypes.LoadLaborSchedulerGanttAllItems),
      switchMap((objectData: LaborSchedulerActions.LoadLaborSchedulerGanttAllItems) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.loadLaborSchedulerGanttAllItems(objectData.scenarioId, objectData.checkInQuery).pipe(
          switchMap((response: LaborSchedulerGanttAllItemsResponseInterface) => {
            return of(
              new LaborSchedulerActions.LaborSchedulerGanttAllItemsLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $saveAndDeployLaborSchedulerItems = createEffect(() =>
    this.actions$.pipe(
      ofType(LaborSchedulerActions.LaborSchedulerActionTypes.SaveAndDeployLaborSchedulerItems),
      switchMap((objectData: LaborSchedulerActions.SaveAndDeployLaborSchedulerItems) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        const formattedSaveLaborSchedulerItems: FormattedSaveLaborSchedulerItemInterface[] =
          this.service.formattedSaveLaborSchedulerItems(objectData.saveLaborSchedulerItems, objectData.taskType);
        return this.service
          .saveLaborSchedulerItems(
            objectData.scenarioId,
            formattedSaveLaborSchedulerItems,
            objectData.lineIds,
            objectData.isDeploy,
          )
          .pipe(
            switchMap((response: AllSettledResolvedInterface[]) => {
              return of(
                new LaborSchedulerActions.SaveAndDeployLaborSchedulerItemsCompleted(response),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((error) => {
              return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideTopLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadTeams = createEffect(() =>
    this.actions$.pipe(
      ofType(LaborSchedulerActions.LaborSchedulerActionTypes.LoadTeams),
      switchMap((objectData: LaborSchedulerActions.LoadTeams) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params: HttpParams = new HttpParams()
          .append('join', 'teamSites')
          .append('join', 'teamSites.teamLines')
          .append('join', 'teamUsers')
          .append('join', 'teamUsers.user')
          .append(
            's',
            JSON.stringify({
              'teamSites.teamLines.siteId': { $in: [objectData.siteId] },
              'teamSites.teamLines.lineId': { $in: objectData.lineIds },
              statusId: { $eq: 1 },
            }),
          );

        return this.service.loadTeams(params).pipe(
          switchMap((response: ResponseArrayInterface<ITeamsResponse>) => {
            return of(new LaborSchedulerActions.TeamsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new LaborSchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
