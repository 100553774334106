import { Action } from '@ngrx/store';
import {
  ILinesReport,
  IFilterData,
  ISiteLineActivityFilterData,
  ITableColumn,
  ELineViewEnhancedResponseMode,
} from './line-view-enhanced.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import {
  EWidgetType,
  WidgetConfigurationInterface
} from '../../../shared/component/page-configuration/page-configuration.model';
import { TGeneralConfigurationSection } from '../../../shared/component/general-configuration/general-configuration.model';

export const LINE_VIEW_ENHANCED_FILTER_DATA_LOADING = '[LINE_VIEW_ENHANCED] LINE VIEW FILTER DATA LOADING';
export const LINE_VIEW_ENHANCED_FILTER_DATA_LOADED = '[LINE_VIEW_ENHANCED] LINE VIEW FILTER DATA LOADED';
export const LINE_VIEW_ENHANCED_FILTER_DATA_GENERATED = '[LINE_VIEW_ENHANCED] FILTER DATA GENERATED';
export const LINE_VIEW_ENHANCED_SET_SELECTED_SITE_FILTERS = '[LINE_VIEW_ENHANCED] SET SELECTED SITE FILTERS';
export const LINE_VIEW_ENHANCED_SET_SELECTED_LINE_FILTERS = '[LINE_VIEW_ENHANCED] SET SELECTED LINE FILTERS';
export const LINE_VIEW_ENHANCED_SET_SELECTED_ACTIVITY_FILTERS = '[LINE_VIEW_ENHANCED] SET SELECTED ACTIVITY FILTERS';
export const LINE_VIEW_ENHANCED_SET_SELECTED_TABLE_COLUMN_SETTINGS =
  '[LINE_VIEW_ENHANCED] SET SELECTED TABLE COLUMN SETTINGS';
export const SET_SELECTED_WIDGET_SETTINGS = '[LINE_VIEW_ENHANCED] SET SELECTED WIDGET SETTINGS';
export const LINE_VIEW_ENHANCED_DATA_LOADING = '[LINE_VIEW_ENHANCED] GET LINE VIEW DATA STARTED';
export const LINE_VIEW_ENHANCED_DATA_LOADED = '[LINE_VIEW_ENHANCED] LINE VIEW DATA LOADED';
export const LINE_VIEW_ENHANCED_START_FILTERS_AND_SETTINGS_SAVING =
  '[LINE_VIEW_ENHANCED] START FILTERS AND SETTINGS SAVING';
export const LINE_VIEW_ENHANCED_FILTERS_AND_SETTINGS_SAVED = '[LINE_VIEW_ENHANCED]  FILTERS AND SETTINGS SAVED';
export const LINE_VIEW_ENHANCED_FETCH_DATA_ERROR = '[LINE_VIEW_ENHANCED] FETCH DATA ERROR';
export const LINE_VIEW_ENHANCED_CANCEL_LOADING = '[LINE_VIEW_ENHANCED] CANCEL LOADING';
export const SET_TABLE_SETTINGS = '[LINE_VIEW_ENHANCED] SET TABLE SETTINGS';
export const LINE_VIEW_ENHANCED_SET_FILTER_SETTINGS = '[LINE_VIEW_ENHANCED] SET FILTER SETTINGS';
export const LINE_COUNT_OF_SITES_LOADING = '[LINE_VIEW_ENHANCED] LINE COUNT OF SITES LOADING';
export const LINE_COUNT_OF_SITES_LOADED = '[LINE_VIEW_ENHANCED] LINE COUNT OF SITES LOADED';
export const SET_GENERAL_CONFIGURATION = '[LINE_VIEW_ENHANCED] SET GENERAL CONFIGURATION';
export const SET_SORT_ORDER_SETTINGS = '[LINE_VIEW_ENHANCED] SET SORT ORDER SETTINGS';

export class LineViewEnhancedFilterDataLoading implements Action {
  readonly type = LINE_VIEW_ENHANCED_FILTER_DATA_LOADING;
}

export class LineViewEnhancedFilterDataLoaded implements Action {
  readonly type = LINE_VIEW_ENHANCED_FILTER_DATA_LOADED;

  constructor(public payload: { data: IFilterData }) {}
}

export class SetFilterDataGenerated implements Action {
  readonly type = LINE_VIEW_ENHANCED_FILTER_DATA_GENERATED;

  constructor(public payload: ISiteLineActivityFilterData) {}
}

export class SetSelectedSiteFilters implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_SELECTED_SITE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedLineFilters implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_SELECTED_LINE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedActivityFilters implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_SELECTED_ACTIVITY_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedTableColumnSettings implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_SELECTED_TABLE_COLUMN_SETTINGS;

  constructor(public payload: ITableColumn[]) {}
}

export class SetSelectedWidgetSettings implements Action {
  readonly type = SET_SELECTED_WIDGET_SETTINGS;

  constructor(public payload: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[]) {}
}

export class LineViewEnhancedDataLoading implements Action {
  readonly type = LINE_VIEW_ENHANCED_DATA_LOADING;

  constructor(public mode: ELineViewEnhancedResponseMode) {}
}

export class LineViewEnhancedDataLoaded implements Action {
  readonly type = LINE_VIEW_ENHANCED_DATA_LOADED;

  constructor(public payload: ILinesReport, public mode: ELineViewEnhancedResponseMode) {}
}

export class StartFiltersAndSettingsSaving implements Action {
  readonly type = LINE_VIEW_ENHANCED_START_FILTERS_AND_SETTINGS_SAVING;
}

export class FiltersAndSettingsSaved implements Action {
  readonly type = LINE_VIEW_ENHANCED_FILTERS_AND_SETTINGS_SAVED;
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class SetFilterSettings implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_FILTER_SETTINGS;

  constructor(
    public siteIds: number[],
    public lineIds: number[],
    public activityIds: number[],
    public setAsDefault: boolean,
  ) {}
}

export class LineCountOfSitesLoading implements Action {
  readonly type = LINE_COUNT_OF_SITES_LOADING;

  constructor(public selectedSites: number[]) {}
}

export class LineCountOfSitesLoaded implements Action {
  readonly type = LINE_COUNT_OF_SITES_LOADED;

  constructor(public payload: number) {}
}

export class FetchDataError implements Action {
  readonly type = LINE_VIEW_ENHANCED_FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export class CancelLoading implements Action {
  readonly type = LINE_VIEW_ENHANCED_CANCEL_LOADING;
}

export class SetGeneralConfiguration implements Action {
  readonly type = SET_GENERAL_CONFIGURATION;

  constructor(public payload: TGeneralConfigurationSection[], public setAsDefault: boolean = false) {}
}

export class SetSortOrderSettings implements Action {
  readonly type = SET_SORT_ORDER_SETTINGS;

  constructor(public payload) {}
}

export type LineViewEnhancedEnhancedActions =
  | LineViewEnhancedFilterDataLoading
  | LineViewEnhancedFilterDataLoaded
  | SetFilterDataGenerated
  | SetSelectedSiteFilters
  | SetSelectedLineFilters
  | SetSelectedActivityFilters
  | LineViewEnhancedDataLoading
  | LineViewEnhancedDataLoaded
  | SetSelectedTableColumnSettings
  | SetSelectedWidgetSettings
  | StartFiltersAndSettingsSaving
  | FiltersAndSettingsSaved
  | SetTableSettings
  | SetFilterSettings
  | LineCountOfSitesLoading
  | LineCountOfSitesLoaded
  | FetchDataError
  | CancelLoading
  | SetGeneralConfiguration
  | SetSortOrderSettings;
