<div [style.cursor]="isLoading ? 'progress' : 'default'">
  <div class="row table-progressbar m-t-5">
    <mat-progress-bar mode="indeterminate" color="primary" [hidden]="!isLoading"></mat-progress-bar>
  </div>
  <div>
    <ng-content *ngTemplateOutlet="topLeftContent"></ng-content>
    <div class="float-right">
      <ng-content *ngTemplateOutlet="topRightContent"></ng-content>
    </div>
    <div *ngIf="search" class="float-right">
      <form #tableFilter="ngForm" name="tableFilter" action="return false;">
        <div class="datatable-items" [ngClass]="paginationCustomizer">
          <div class="input-group no-margin">
            <div class="search_box-container">
              <input
                type="text"
                name="search"
                class="form-control search-input"
                maxlength="{{ InputLimit.TABLE_SEARCH_MAX_LENGTH }}"
                [class.is-invalid]="search.invalid"
                [placeholder]="searchPlaceholder"
                [(ngModel)]="searchValue"
                (keyup)="onChangeDataSearch(tableFilter)"
                #search="ngModel"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </div>
            <button
              class="add-button"
              *ngIf="addButton"
              [disabled]="!!addButtonCustomizer.disabled"
              [ngClass]="addButtonCustomizer.buttonClass"
              (click)="onAddButtonClickEvent()"
            >
              <em *ngIf="addButtonCustomizer.iconClass" [ngClass]="addButtonCustomizer.iconClass"></em>
              {{ addButtonCustomizer.text }}
            </button>
            <div class="invalid-feedback" *ngIf="search.invalid && (search.dirty || search.touched)">
              <div class="messages text-danger" *ngIf="search.errors['maxlength']">
                {{ 'general.inputErrorMessages.maxLength' | translate : { value: InputLimit.TABLE_SEARCH_MAX_LENGTH } }}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div #scrollingView class="table-responsive">
    <table
      class="table table-striped table-bordered full-data-table tiny-table-td"
      [ngClass]="tableCustomizer"
      [class.height-mode-fill]="heightMode === 'fill'"
      [class.height-mode-fixed]="heightMode === 'fixed'"
      [ngStyle]="tableStyle"
      [style.pointer-events]="isLoading ? 'none' : 'all'"
    >
      <thead #tableHead>
        <ng-content *ngTemplateOutlet="headerContent"></ng-content>
        <tr>
          <th
            id="{{ 'header-' + i }}"
            scope="col"
            class="header-cell"
            *ngFor="let header of headers; index as i; trackBy: datatableHeaderTrackBy"
            [style.width]="header.width ? header.width : 'auto'"
            [style.pointer-events]="
              (header.tooltip || header.sortable !== false || header.buttons || header.checkbox) &&
              !isLoading &&
              !pending
                ? 'all'
                : 'none'
            "
            [style.cursor]="header.sortable === false ? 'default' : undefined"
            (click)="onChangeDataSort(header, i)"
            [ngClass]="header.class"
            [class.sticky-left-col-head]="stickyLeadColumn && i === 0"
            [class.sticky-right-col-header]="stickyLastColumn && i === headers.length - 1"
            [class.first-not-sticky]="!stickyLeadColumn && i === 0"
          >
            <p class="mb-0" *ngIf="header.subTitle">{{ header.subTitle }}</p>
            <em
              *ngIf="header.tooltip"
              class="fas fa-info-circle cursor-pointer pr-1"
              [placement]="header.tooltipPlacement ?? 'top'"
              triggers="click hover"
              container="body"
              [ngbTooltip]="header.tooltip"
              [tooltipClass]="header.tooltipClass"
            ></em>
            <span *ngIf="header.icon">
              <i [class]="header.icon"></i>
            </span>
            <div class="d-inline-block header-name-container">
              {{ header.name }}
            </div>
            <span *ngIf="header.buttons">
              <scw-mat-small-button
                class="header-button"
                *ngFor="let button of header.buttons"
                (onClick)="onClickHeaderButton.emit(button)"
              >
                <span [innerHTML]="button.text"></span>
              </scw-mat-small-button>
            </span>
            <span class="sort-icon-container" *ngIf="header.sortable !== false">
              <em class="fas fa-long-arrow-alt-up" [class.asc]="header.sort.sortIconClass === 'asc'"></em>
              <em class="fas fa-long-arrow-alt-down" [class.desc]="header.sort.sortIconClass === 'desc'"></em>
            </span>
            <ng-template [ngIf]="header.checkbox">
              <div
                class="d-flex align-items-center justify-content-center header-checkbox-container"
                [class.m-b-5]="header.checkbox?.location === 'bottom'"
                [class.m-l-5]="header.checkbox?.location === 'right'"
              >
                <scw-mat-checkbox
                  [disabled]="header.checkbox?.disabled"
                  [value]="header.value"
                  [(inputModel)]="header.checkbox.value"
                  (inputModelChange)="onHeaderCheckboxClickEvent($event, header.value)"
                ></scw-mat-checkbox>
              </div>
            </ng-template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="noDataText && items?.length <= 0">
          <td [attr.colspan]="headers?.length">
            {{ noDataText }}
          </td>
        </tr>
        <ng-template [ngIf]="clientSide && !justDataTable && !nestedTable">
          <tr
            *ngFor="
              let item of (currentSort === 'none' ? items : sortedItems)
                | slice : (currentPage - 1) * rowsPerPage : currentPage * rowsPerPage;
              index as i;
              trackBy: datatableItemTrackBy
            "
            (click)="onClickRowTrigger({ item: item, event: $event })"
            [class.sticky-left-cell]="stickyLeadColumn"
            [class.sticky-right-cell]="stickyLastColumn"
          >
            <ng-container
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            ></ng-container>
          </tr>
        </ng-template>
        <ng-template [ngIf]="(serverSide || justDataTable) && !nestedTable">
          <tr
            *ngFor="
              let item of clientSide && currentSort !== 'none' ? sortedItems : items;
              index as i;
              trackBy: datatableItemTrackBy
            "
            (click)="onClickRowTrigger({ item: item, event: $event })"
            [class.sticky-left-cell]="stickyLeadColumn"
            [class.sticky-right-cell]="stickyLastColumn"
          >
            <ng-container
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            ></ng-container>
          </tr>
        </ng-template>
        <ng-template [ngIf]="nestedTable">
          <ng-container
            *ngFor="let item of currentSort !== 'none' ? sortedItems : items; index as i; trackBy: datatableItemTrackBy"
            (click)="onClickRowTrigger({ item: item, event: $event })"
          >
            <ng-container
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            ></ng-container>
          </ng-container>
        </ng-template>
      </tbody>
      <ng-content *ngTemplateOutlet="footerContent"></ng-content>
    </table>
  </div>
  <div class="row align-items-center">
    <div class="col-4 col-xl-5 m-auto">
      <div [hidden]="!shouldPaginate">
        <label class="label-control rows-per-page-select-text">
          <span class="mr-2" [hidden]="items?.length" [innerHTML]="'datatable.show' | translate"></span>
          <span
            class="mr-2"
            [hidden]="!items?.length"
            [innerHTML]="'datatable.paginationPrior' | translate : { total: totalNumberOfItems }"
          ></span>
          <select
            [style.pointer-events]="isLoading ? 'none' : 'all'"
            class="rows-per-page-select"
            [disabled]="pending"
            [(ngModel)]="rowsPerPage"
            (change)="onChangePaginationTrigger(1)"
          >
            <option *ngFor="let item of rowsPerPageItems">
              {{ item.value }}
            </option>
          </select>
          <span class="ml-2" [hidden]="items?.length">
            {{ 'datatable.entries' | translate }}
          </span>
          <br class="d-xl-none" />
          <span
            class="d-inline-block mt-1 mt-xl-0 ml-0 ml-xl-2"
            *ngIf="items?.length"
            [innerHTML]="
              'datatable.paginationPosterior'
                | translate
                  : {
                      upperBound: entryRangeUpperBound,
                      lowerBound: entryRangeLowerBound,
                      total: totalNumberOfItems
                    }
            "
          ></span>
        </label>
      </div>
    </div>
    <div class="col-8 col-xl-7">
      <div class="pg-right">
        <ngb-pagination
          [style.pointer-events]="isLoading ? 'none' : 'all'"
          [collectionSize]="serverSide ? itemsCount : items.length"
          [pageSize]="rowsPerPage"
          [maxSize]="5"
          [disabled]="pending"
          [boundaryLinks]="true"
          [rotate]="true"
          [(page)]="currentPage"
          (pageChange)="onChangePaginationTrigger($event)"
          [hidden]="!shouldPaginate"
          appPaginationCustomizer
          [additionalClassName]="paginationCustomizer"
        ></ngb-pagination>
      </div>
    </div>
  </div>
</div>
