<ng-template #image_preview_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 *ngIf="!isOpenedFromCommentLogs; else commentLogsHeader" class="modal-title scw-header">
      {{ 'fileUpload.imagePreview.header' | translate }}
    </h3>
    <ng-template #commentLogsHeader>
      <h3 *ngIf="isOpenedFromCommentLogs" class="modal-title scw-header">
        {{ 'fileUpload.filePreview.header' | translate }}
      </h3>
    </ng-template>

    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onCloseModal()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-carousel *ngIf="sortedFiles?.length" (slide)="slideChange($event)" [wrap]="false" [interval]="0">
      <ng-template ngbSlide *ngFor="let file of sortedFiles; let i = index">
        <ng-container *ngIf="file.type === EFileType.IMAGE">
          <button (click)="showDeleteItemModal(delete_confirmation_modal)" class="delete-button-images">
            <i class="fas fa-trash fa-xs" style="color: #ffffff"></i>
          </button>
          <img [src]="file.original" class="image" />
        </ng-container>
      </ng-template>
    </ngb-carousel>
    <div class="col-12" *ngIf="isOpenedFromCommentLogs">
      <div class="row mr-1">
        <ng-container *ngFor="let file of pdfFiles">
          <div class="col-md-6 w-100 p-0" *ngIf="file.type === EFileType.PDF">
            <div class="d-flex border rounded mt-2 mr-2">
              <div class="d-flex align-items-center m-1">
                <i class="fas fa-file-pdf fa-lg mx-2"></i>
                <div class="row">
                  <span class="col-12 truncate-span filename-text" (click)="downloadPdf(file)">
                    {{ file.fileOriginalName }}</span
                  >
                  <span class="col-12 size-text"> {{ file.size }} MB</span>
                </div>
                <button (click)="showDeleteItemModal(delete_confirmation_modal, file)" class="delete-button-file">
                  <i class="fas fa-trash" style="color: #2a2828"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button
        type="danger"
        [hidden]="!isOpenedFromCommentLogs"
        (onClick)="showDeleteItemModal(bulk_delete_confirmation_modal)"
      >
        {{ 'general.deleteAll' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="onCloseModal()">
        {{ 'general.close' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #delete_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'fileUpload.delete.header' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="onCloseDeleteModal()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 information-text">
        <h6 class="scw-header mt-3">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          {{ 'fileUpload.delete.info' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="onCloseDeleteModal()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onDelete()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #bulk_delete_confirmation_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">{{ 'fileUpload.deleteAll.header' | translate }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="onCloseDeleteModal()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 information-text">
        <h6 class="scw-header mt-3">
          <em class="fas fa-exclamation-circle"></em>&nbsp; {{ 'fileUpload.deleteAll.info' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="onCloseDeleteModal()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onBulkDelete()">
        {{ 'general.deleteAll' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
