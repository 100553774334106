import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseArrayInterface } from '../../../model/interface/generic-api-response.model';
import { IActivityHistory } from '../../activity-history/activity-history.model';
import { ICurrentLine } from '../../../../store/line/model';
import {
  IActivityTimeLineLineResponse,
  IActivityTimelineRequestInterface,
} from '../../../../store/reports/activity-timeline/activity-timeline.model';
import { HelperService } from '../../helper.service';
import { ActivityTypes } from '../../../model/enum/activity-types';
import { ActivityTimelineInterface } from '../../../../view/reports/activity-timeline/activity-timeline.model';
import { LineCRUDInterface } from '../../../component/filter/filter.class';
import * as moment from 'moment';
import { mysqlTimestampFormat } from '../../../helper/date';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ActivityTimelineService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
  ) {}

  private readonly routes = {
    activityHistory: `${this.api}/activity-histories`,
    lines: `${this.api}/lines`,
  };

  public loadActivityHistoriesActivityTimeline(
    objectData: IActivityTimelineRequestInterface,
    page: number,
    limit: number,
  ): Observable<ResponseArrayInterface<IActivityHistory>> {
    const filter = {
      $and: [
        objectData.isBusinessDate
          ? {
              shiftDay: { $lte: objectData.endDate, $gte: objectData.startDate },
            }
          : {
              start: { $lte: objectData.endDate },
              end: { $gt: objectData.startDate },
            },
        objectData.siteId !== -1 && (objectData.siteId as Number[]).length !== 0
          ? {
              siteId: { $in: objectData.siteId },
            }
          : '',
        objectData.lineId !== -1 && (objectData.lineId as Number[]).length !== 0
          ? {
              lineId: { $in: objectData.lineId },
            }
          : '',
      ],
    };

    const queryParams: HttpParams = new HttpParams()
      .append('join', 'activity')
      .append('join', 'task')
      .append('join', 'workOrder||woNumber,completed')
      .append('join', 'workOrder.product')
      .append('join', 'user||userName')
      .append('fields', 'siteId,lineId,start,end,activitySequenceId')
      .append('useReplicaForGetIfAvailable', true)
      .append('s', JSON.stringify(filter))
      .append('page', page)
      .append('limit', limit)
      .append('isActivityTimeline', true);

    return this.http.get<ResponseArrayInterface<IActivityHistory>>(`${this.routes.activityHistory}`, {
      params: queryParams,
    });
  }

  public loadLinesActivityTimeline(
    objectData: IActivityTimelineRequestInterface,
    isGetAllLines: boolean = false,
  ): Observable<ResponseArrayInterface<ICurrentLine> | ResponseArrayInterface<LineCRUDInterface>> {
    const filter = {
      $and: [
        {
          timer: { $lte: objectData.endDate },
        },
        objectData.siteId !== -1 && (objectData.siteId as Number[]).length !== 0
          ? {
              siteId: { $in: objectData.siteId },
            }
          : '',
        objectData.lineId !== -1 && (objectData.lineId as Number[]).length !== 0
          ? {
              id: { $in: objectData.lineId },
            }
          : '',
      ],
    };

    let queryParams: HttpParams = new HttpParams().append('useReplicaForGetIfAvailable', true).append('limit', 1000);

    if (!isGetAllLines) {
      queryParams = queryParams
        .append('join', 'currentActivity')
        .append('join', 'currentTask')
        .append('join', 'currentTask.equipment')
        .append('join', 'currentWorkOrder')
        .append('join', 'currentWorkOrder.product')
        .append('fields', 'title,timer')
        .append('s', JSON.stringify(filter));
    }

    return this.http.get<ResponseArrayInterface<ICurrentLine> | ResponseArrayInterface<LineCRUDInterface>>(
      `${this.routes.lines}`,
      {
        params: queryParams,
      },
    );
  }

  public getActivityTimelineObservables(
    params: IActivityTimelineRequestInterface,
    totalCount: number,
  ): Observable<
    | ActivityTimelineInterface[]
    | ResponseArrayInterface<IActivityTimeLineLineResponse>
    | ResponseArrayInterface<LineCRUDInterface>
    | ResponseArrayInterface<IActivityHistory>
  >[] {
    const partCount: number = 5000;
    const observables: Observable<
      | ActivityTimelineInterface[]
      | ResponseArrayInterface<IActivityTimeLineLineResponse>
      | ResponseArrayInterface<LineCRUDInterface>
      | ResponseArrayInterface<IActivityHistory>
    >[] = [this.getLinesActivityTimelineData(params), this.loadLinesActivityTimeline(params, true)];
    const observablesCountOfActivityHistory: number = Math.ceil(totalCount / partCount);
    Array.from(Array(observablesCountOfActivityHistory)).map((value, index) => {
      const page = index + 1;

      observables.push(this.loadActivityHistoriesActivityTimeline(params, page, partCount));
    });

    if (observablesCountOfActivityHistory > 1) {
      this.helperService.showWaitMessage();
    }

    return observables;
  }

  private static getDurationCalc(startDate: string, endDate: string): number {
    return moment.duration(moment(endDate).diff(moment(startDate))).asSeconds();
  }

  public getModifiedActivityHistories(
    activityHistories: IActivityHistory[],
    lineData: LineCRUDInterface[],
  ): ActivityTimelineInterface[] {
    return activityHistories.reduce((result: ActivityTimelineInterface[], history: IActivityHistory) => {
      if (!_.isNil(history.activity)) {
        const isMissingTask: boolean =
          _.isNil(history.task) && history.activity.activityType !== ActivityTypes.RUN_TIME;

        const isMissing: boolean =
          (_.isNil(history.workOrder) &&
            history.activity.activityType !== ActivityTypes.IDLE_TIME &&
            !Boolean(history.task?.meta?.withoutWorkOrder)) ||
          (!_.isNil(history.task) && history.task.isMissingData === 1) ||
          isMissingTask;

        result.push({
          isMissing,
          id: history.id,
          lineId: history.lineId,
          lineName: lineData.find((x: LineCRUDInterface) => x.id === history.lineId).title,
          barTitle: isMissing ? this.translate.instant('activityTimeline.missing.data') : history.activity.name,
          taskName: isMissingTask ? this.translate.instant('activityTimeline.missing.task') : history.task?.title,
          activityName: history?.activity?.name,
          activityType: history?.activity?.activityType,
          endDate: this.helperService.convertFromISOFormatToGivenTimezone(history.end),
          startDate: this.helperService.convertFromISOFormatToGivenTimezone(history.start),
          productDescription: !_.isNil(history.workOrder)
            ? history.workOrder?.product.description
            : _.isNil(history.workOrder) &&
              history.activity.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(history.task?.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.product')
            : ' - ',
          woNumber: !_.isNil(history.workOrder)
            ? history.workOrder?.woNumber
            : _.isNil(history.workOrder) &&
              history.activity.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(history.task?.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.workOrder')
            : ' - ',
          workOrderId: !_.isNil(history.workOrder)
            ? history.workOrder?.id
            : history.activity.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(history.task?.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.workOrder')
            : ' - ',
          duration: ActivityTimelineService.getDurationCalc(history.start, history.end),
          durationUnit: 's',
          isHistory: true,
          isComment: false,
          workOrderComplete: !_.isNil(history.workOrder)
            ? history.workOrder.completed !== null
              ? Boolean(history.workOrder.completed)
              : false
            : false,
          userId: history.userId,
          userName: history.user.userName,
          activitySequenceId: history.activitySequenceId,
        });
      }

      return result;
    }, []);
  }

  public getModifiedLines(lines: IActivityTimeLineLineResponse[], timezone: string): ActivityTimelineInterface[] {
    const dateNow: string = moment().tz(timezone).format(mysqlTimestampFormat);

    return lines.reduce((result: ActivityTimelineInterface[], line: IActivityTimeLineLineResponse) => {
      if (!_.isNil(line.activityName)) {
        const isMissingTask: boolean = _.isNil(line.taskName) && line.activityType !== ActivityTypes.RUN_TIME;
        const isMissing: boolean =
          isMissingTask ||
          (_.isNil(line.woNumber) &&
            line.activityType !== ActivityTypes.IDLE_TIME &&
            !Boolean(line.meta?.withoutWorkOrder)) ||
          (!_.isNil(line.taskName) && line.isMissingTask === 1);
        result.push({
          isMissing,
          id: line.id,
          lineId: line.id,
          lineName: line.title,
          barTitle: isMissing ? this.translate.instant('activityTimeline.missing.data') : line.activityName,
          taskName: isMissingTask ? this.translate.instant('activityTimeline.missing.task') : line.taskName,
          activityName: line?.activityName,
          activityType: line?.activityType,
          endDate: dateNow,
          startDate: this.helperService.convertFromISOFormatToGivenTimezone(line.timer),
          productDescription: !_.isNil(line.woNumber)
            ? line.productDescription
            : _.isNil(line.woNumber) &&
              line.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(line.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.product')
            : ' - ',
          woNumber: !_.isNil(line.woNumber)
            ? line.woNumber
            : _.isNil(line.woNumber) &&
              line.activityType !== ActivityTypes.IDLE_TIME &&
              !Boolean(line.meta?.withoutWorkOrder)
            ? this.translate.instant('activityTimeline.missing.workOrder')
            : ' - ',
          workOrderId: line.workOrderId,
          duration: ActivityTimelineService.getDurationCalc(line.timer, dateNow),
          durationUnit: 's',
          isHistory: false,
          isComment: false,
          workOrderComplete: !_.isNil(line.woNumber)
            ? line.completed !== null
              ? Boolean(line.completed)
              : false
            : false,
        });
      }

      return result;
    }, []);
  }

  public getLinesActivityTimelineData(
    params: IActivityTimelineRequestInterface,
  ): Observable<ActivityTimelineInterface[]> {
    const httpParams: HttpParams = new HttpParams()
      .append('siteId', String(params.siteId))
      .append('lineIds', String(params.lineId))
      .append('startDate', params.startDate)
      .append('endDate', params.endDate)
      .append('isBusinessDate', params.isBusinessDate);

    return this.http.get<ActivityTimelineInterface[]>(`${this.routes.lines}/activity-timeline`, {
      params: httpParams,
    });
  }
}
