import { Action } from '@ngrx/store';
import { EquipmentTaskResponseInterface, IStartEquipmentTaskParameter } from './equipment-task.model';
import { IRootAddress } from '../settings/tree-nodes/tree-nodes.model';
import { ITaskTreeNode } from '../../shared/component/activity-buttons/task-selection/task-selection.model';

export enum EquipmentTaskActionTypes {
  StartEquipmentTaskDataLoading = '[EQUIPMENT_TASK] START EQUIPMENT TASK DATA LOADING',
  StartEquipmentTaskDataLoaded = '[EQUIPMENT_TASK] START EQUIPMENT TASK DATA LOADED',
  NodeDataLoading = '[EQUIPMENT_TASK] TASK SELECTION DATA LOADING',
  NodeDataLoaded = '[EQUIPMENT_TASK] TASK SELECTION DATA LOADED',
  FetchError = '[EQUIPMENT_TASK] Fetch Error',
}

export class StartEquipmentTaskDataLoading implements Action {
  readonly type = EquipmentTaskActionTypes.StartEquipmentTaskDataLoading;

  constructor(public parameters: IStartEquipmentTaskParameter) {}
}

export class StartEquipmentTaskDataLoaded implements Action {
  readonly type = EquipmentTaskActionTypes.StartEquipmentTaskDataLoaded;

  constructor(public payload: EquipmentTaskResponseInterface) {}
}

export class NodeDataLoad implements Action {
  readonly type = EquipmentTaskActionTypes.NodeDataLoading;

  constructor(
    public rootAddress: IRootAddress,
    public search?: string,
    public parentId?: number,
    public isSkipped: boolean = false,
    public ignoreHidden: boolean = false,
  ) {}
}

export class NodeDataLoaded implements Action {
  readonly type = EquipmentTaskActionTypes.NodeDataLoaded;

  constructor(public payload: ITaskTreeNode[], public isSkipped: boolean = false) {}
}

export class FetchError implements Action {
  readonly type = EquipmentTaskActionTypes.FetchError;

  constructor(public payload: any) {}
}

export type EquipmentTaskActions =
  | StartEquipmentTaskDataLoading
  | StartEquipmentTaskDataLoaded
  | NodeDataLoad
  | NodeDataLoaded
  | FetchError;
