import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../shared/model/interface/generic-api-request.model';
import {
  SensorBulkUpdateRequestInterface,
  SensorCRUDCreateRequestInterface,
  SensorCRUDInterface,
  SensorCRUDUpdateRequestInterface,
  SensorFormDropdownData,
} from './sensor-management.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum SensorActionTypes {
  LoadSensors = '[SensorManagement] Load Sensors',
  SensorsLoaded = '[SensorManagement] Sensors Loaded',
  LoadFormDropdownData = '[SensorManagement] Load Form Dropdown Data',
  FormDropdownDataLoaded = '[SensorManagement] Form Dropdown Loaded',
  CreateSensor = '[SensorManagement] Create Sensor',
  CreateSensorCompleted = '[SensorManagement] Create Sensor Completed',
  UpdateSensor = '[SensorManagement] Update Sensor',
  UpdateSensorCompleted = '[SensorManagement] Update Sensor Completed',
  BulkUpdateSensor = '[SensorManagement] Bulk Update Sensor',
  BulkUpdateSensorCompleted = '[SensorManagement] Bulk Update Sensor Completed',
  DeleteSensor = '[SensorManagement] Delete Sensor',
  DeleteSensorCompleted = '[SensorManagement] Delete Sensor Completed',
  BulkDeleteSensor = '[SensorManagement] Bulk Delete Sensor',
  BulkDeleteSensorCompleted = '[SensorManagement] Bulk Delete Sensor Completed',
  LoadCounterSensors = '[SensorManagement] Load Counter Sensors',
  CounterSensorsLoaded = '[SensorManagement] Counter Sensors Loaded',
  FetchError = '[SensorManagement] Fetch Error',
}

export class LoadSensors implements Action {
  readonly type = SensorActionTypes.LoadSensors;
  constructor(public parameters: GenericCrudRequestConstructionParameters) {}
}

export class SensorsLoaded implements Action {
  readonly type = SensorActionTypes.SensorsLoaded;
  constructor(public payload: GetManyResponseInterface<SensorCRUDInterface>) {}
}

export class LoadFormDropdownData implements Action {
  readonly type = SensorActionTypes.LoadFormDropdownData;
}

export class FormDropdownDataLoaded implements Action {
  readonly type = SensorActionTypes.FormDropdownDataLoaded;
  constructor(public payload: SensorFormDropdownData) {}
}

export class CreateSensor implements Action {
  readonly type = SensorActionTypes.CreateSensor;
  constructor(public requestParameters: SensorCRUDCreateRequestInterface) {}
}

export class CreateSensorCompleted implements Action {
  readonly type = SensorActionTypes.CreateSensorCompleted;
  constructor(public payload: BaseOneResponseInterface<SensorCRUDInterface>) {}
}

export class UpdateSensor implements Action {
  readonly type = SensorActionTypes.UpdateSensor;
  constructor(public id: number, public requestParameters: SensorCRUDUpdateRequestInterface) {}
}

export class UpdateSensorCompleted implements Action {
  readonly type = SensorActionTypes.UpdateSensorCompleted;
  constructor(public payload: BaseOneResponseInterface<SensorCRUDInterface>) {}
}

export class BulkUpdateSensor implements Action {
  readonly type = SensorActionTypes.BulkUpdateSensor;
  constructor(public requestParameters: SensorBulkUpdateRequestInterface[]) {}
}

export class BulkUpdateSensorCompleted implements Action {
  readonly type = SensorActionTypes.BulkUpdateSensorCompleted;
  constructor(public payload: BulkResponseDataInterface) {}
}

export class DeleteSensor implements Action {
  readonly type = SensorActionTypes.DeleteSensor;
  constructor(public id: number) {}
}

export class DeleteSensorCompleted implements Action {
  readonly type = SensorActionTypes.DeleteSensorCompleted;
}

export class BulkDeleteSensor implements Action {
  readonly type = SensorActionTypes.BulkDeleteSensor;
  constructor(public ids: number[]) {}
}

export class BulkDeleteSensorCompleted implements Action {
  readonly type = SensorActionTypes.BulkDeleteSensorCompleted;
  constructor(public payload: BulkResponseDataInterface) {}
}

export class LoadCounterSensors implements Action {
  readonly type = SensorActionTypes.LoadCounterSensors;
  constructor(public searchText?: string, public lineId?: number) {}
}

export class CounterSensorsLoaded implements Action {
  readonly type = SensorActionTypes.CounterSensorsLoaded;
  constructor(public payload: GetManyResponseInterface<SensorCRUDInterface>) {}
}

export class FetchError implements Action {
  readonly type = SensorActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type SensorActions =
  | LoadSensors
  | SensorsLoaded
  | LoadFormDropdownData
  | FormDropdownDataLoaded
  | CreateSensor
  | CreateSensorCompleted
  | UpdateSensor
  | UpdateSensorCompleted
  | BulkUpdateSensor
  | BulkUpdateSensorCompleted
  | DeleteSensor
  | DeleteSensorCompleted
  | BulkDeleteSensor
  | BulkDeleteSensorCompleted
  | LoadCounterSensors
  | CounterSensorsLoaded;
