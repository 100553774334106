import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { INotificationFeedItem } from 'src/app/shared/service/notification-feed/notification-feed.model';
import { Router } from '@angular/router';
import { TimestampPipe } from '../pipes/timestamp.pipe';

@Component({
  selector: 'app-notification-feed-item',
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, TimestampPipe, TranslateModule],
  providers: [AsyncPipe],
  templateUrl: './notification-feed-item.component.html',
  styleUrls: ['./notification-feed-item.component.scss'],
})
export class NotificationFeedItemComponent {
  @Input() item: INotificationFeedItem | undefined;
  @Input() timeOnly: boolean = false;
  @Output() setRead: EventEmitter<boolean> = new EventEmitter();

  public onNotificationClick(): void {
    if (!this.item) {
      return;
    }

    if (!this.item.readAt) {
      this.setRead.emit(true);
    }

    if (this.item.notification.url) {
      window.location.assign(this.item.notification.url);
    }
  }

  public onReadButtonClick(event: MouseEvent): void {
    if (!this.item) {
      return;
    }

    this.setRead.emit(this.item.readAt === null);
    event.stopPropagation();
  }
}
