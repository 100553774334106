import { TrackByFunction } from '@angular/core';
import { ITableHeader } from '../../../constants.model';

export class ComponentUtilities {
  public static findOneOptionForSelect<T>(collection: T[], value: unknown, key: string | number = 'id'): T[] {
    const item: T | undefined = collection.find((item: T) => key in item && item[key as keyof T] === value);

    return item !== undefined ? [item] : ([] as T[]);
  }

  public static readonly genericTrackByIndexFunction: TrackByFunction<any> = (index: number, _item: any) => {
    return index;
  };

  public static readonly genericTrackByItemFunction: TrackByFunction<any> = (_index: number, item: any) => {
    return item;
  };

  public static readonly datatableHeaderTrackBy: TrackByFunction<ITableHeader> = (
    index: number,
    item: ITableHeader,
  ) => {
    return item.value ? `${item.value}_|_${item.name}` : item;
  };

  public static readonly genericTrackByIdFunction: TrackByFunction<any> = (index: number, item: any) => {
    return item.id ?? item;
  };
}
