import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildDecorator } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OeeAppState } from '../../../store/oee.reducer';
import { Store } from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, take } from 'rxjs';
import {
  EConfigurationTypes,
  IColumnSortOrderItem,
  IDropdownOptions,
  IGeneralConfigurationColumnSortOrderSection,
  IGeneralConfigurationTableHeaderSection,
  IGeneralConfigurationTargetExceededHighlightColumnsSection,
  IGeneralConfigurationWidgetSection,
  ITableHeaderGroup,
  ITableHeaderGroupItem,
  IWidgetConfiguration,
  TGeneralConfigurationSection,
} from './general-configuration.model';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import * as LineViewEnhancedActions from '../../../store/dashboards/line-view-enhanced/line-view-enhanced.actions';
import * as PageHeaderActions from '../../../store/page-header/page-header.actions';
import { HelperService } from '../../service/helper.service';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import {
  IComponentConfiguration,
  UserConfigurationStateInterface,
} from '../../../store/user-configuration/user-configuration.model';
import { ToastHelperService } from '../../service/toast/toast.helper.service';
import { User } from '../../../store/user/model';
import { PageConfigurationTypes } from '../../../../constants.model';
import { EWidgetType } from '../page-configuration/page-configuration.model';
import { EKpiCardContentType, IScwMatKpiCard } from '../scw-mat-ui/scw-mat-kpi-card/scw-mat-kpi-card.model';

@OnDestroyDecorator
@Component({
  selector: 'app-general-configuration',
  templateUrl: './general-configuration.component.html',
  styleUrls: ['./general-configuration.component.scss'],
})
export class GeneralConfigurationComponent implements OnInit, OnDestroy {
  @ViewChild('close_button') closeButtonRef: ViewChild;
  @ViewChild('unsaved_modal') unsavedChangesModalTemplateRef: ViewChildDecorator;
  @ViewChild('config_modal') configModalTemplateRef: ViewChildDecorator;

  protected areChangesMade: boolean = false;
  protected configModalRef: NgbModalRef;
  protected saveChangesModalRef: NgbModalRef;
  protected modalArray: NgbModalRef[] = [];

  protected readonly EConfigurationTypes = EConfigurationTypes;

  private readonly storeSubscriptions: Subscription[] = [];
  private readonly scwModalLg: string = 'scw-modal-lg';
  private readonly scwModalSm: string = 'scw-modal-sm';

  private readonly defaultLineViewEnhancedConfiguration: TGeneralConfigurationSection[] = [
    {
      type: EConfigurationTypes.WIDGET,
      name: 'widgets',
      title: this.translate.instant('generalConfiguration.modal.widget.title'),
      description: this.translate.instant('generalConfiguration.modal.widget.description'),
      orientation: 'horizontal',
      items: [
        {
          name: 'runningLines',
          type: PageConfigurationTypes.WIDGET,
          selected: true,
          disabled: true,
          content: {
            type: EWidgetType.KPI_CARD,
            pageConfigurationTitle: this.translate.instant('lineView.runningLines'),
            header: [
              {
                type: EKpiCardContentType.TEXT,
                value: this.translate.instant('lineView.runningLines'),
              },
              {
                type: EKpiCardContentType.ICON,
                value: 'fas fa-play-circle',
                style: {
                  color: '#6FC764',
                },
              },
            ],
            body: [
              {
                key: 'runTime$',
                type: EKpiCardContentType.TEXT,
                value: null,
                style: {
                  color: '#6FC764',
                },
              },
            ],
          },
        },
        {
          name: 'downLines',
          type: PageConfigurationTypes.WIDGET,
          selected: true,
          disabled: true,
          content: {
            type: EWidgetType.KPI_CARD,
            pageConfigurationTitle: this.translate.instant('lineView.downLines'),
            header: [
              {
                type: EKpiCardContentType.TEXT,
                value: this.translate.instant('lineView.downLines'),
              },
              {
                type: EKpiCardContentType.ICON,
                value: 'fas fa-stop-circle',
                style: {
                  color: '#DD332B',
                },
              },
            ],
            body: [
              {
                key: 'downTime$',
                type: EKpiCardContentType.TEXT,
                value: null,
                style: {
                  color: '#DD332B',
                },
              },
            ],
          },
        },
        {
          name: 'idleLines',
          type: PageConfigurationTypes.WIDGET,
          selected: true,
          disabled: true,
          content: {
            type: EWidgetType.KPI_CARD,
            pageConfigurationTitle: this.translate.instant('lineView.idleLines'),
            header: [
              {
                type: EKpiCardContentType.TEXT,
                value: this.translate.instant('lineView.idleLines'),
              },
              {
                type: EKpiCardContentType.ICON,
                value: 'fas fa-pause-circle',
              },
            ],
            body: [
              {
                key: 'idleTime$',
                type: EKpiCardContentType.TEXT,
                value: null,
              },
            ],
          },
        },
        {
          name: 'todayOEE',
          type: PageConfigurationTypes.WIDGET,
          selected: true,
          disabled: false,
          content: {
            type: EWidgetType.KPI_CARD,
            pageConfigurationTitle: this.translate.instant('lineView.todaysOEE'),
            header: [
              {
                type: EKpiCardContentType.TEXT,
                value: this.translate.instant('lineView.todaysOEE'),
              },
              {
                type: EKpiCardContentType.ICON,
                value: 'fas fa-stopwatch',
              },
            ],
            body: [
              {
                key: 'runTimeAndDownTimeOee$',
                type: EKpiCardContentType.TEXT,
                value: null,
                displayPercentage: true,
              },
            ],
          },
        },
        {
          name: 'totalNumberOfUsers',
          type: PageConfigurationTypes.WIDGET,
          selected: false,
          disabled: false,
          content: {
            type: EWidgetType.KPI_CARD,
            pageConfigurationTitle: this.translate.instant('lineView.totalNumberOfUsers'),
            header: [
              {
                type: EKpiCardContentType.TEXT,
                value: this.translate.instant('lineView.totalNumberOfUsers'),
              },
              {
                type: EKpiCardContentType.ICON,
                value: 'fas fa-users',
              },
            ],
            body: [
              {
                key: 'totalNumberOfUsers$',
                type: EKpiCardContentType.TEXT,
                value: null,
              },
            ],
          },
        },
        {
          name: 'totalNumberOfAssets',
          type: PageConfigurationTypes.WIDGET,
          selected: false,
          disabled: false,
          content: {
            type: EWidgetType.KPI_CARD,
            pageConfigurationTitle: this.translate.instant('lineView.totalNumberOfAssets'),
            header: [
              {
                type: EKpiCardContentType.TEXT,
                value: this.translate.instant('lineView.totalNumberOfAssets'),
              },
              {
                type: EKpiCardContentType.ICON,
                value: 'fas fa-cubes',
              },
            ],
            body: [
              {
                key: 'totalNumberOfAssets$',
                type: EKpiCardContentType.TEXT,
                value: null,
              },
            ],
          },
        },
      ],
    },
    {
      type: EConfigurationTypes.TABLE,
      name: 'headerGroups',
      title: this.translate.instant('generalConfiguration.modal.columns.title'),
      availableTitle: this.translate.instant('generalConfiguration.modal.columns.availableTitle'),
      selectedTitle: this.translate.instant('generalConfiguration.modal.columns.selectedTitle'),
      isSearchEnabled: true,
      searchPlaceholder: this.translate.instant('generalConfiguration.modal.columns.searchPlaceholder'),
      searchInputModel: '',
      items: [
        {
          name: 'siteDepartmentLineGroup',
          title: this.translate.instant('generalConfiguration.modal.columns.groups.siteDepartmentLineGroup'),
          items: [
            {
              name: 'siteName',
              title: this.translate.instant('lineView.tableHeaders.siteName'),
              groupName: 'siteDepartmentLineGroup',
              selected: true,
            },
            {
              name: 'departmentName',
              title: this.translate.instant('lineView.tableHeaders.departmentName'),
              groupName: 'siteDepartmentLineGroup',
              selected: true,
            },
            {
              name: 'lineName',
              title: this.translate.instant('lineView.tableHeaders.lineName'),
              groupName: 'siteDepartmentLineGroup',
              selected: true,
              isRequired: true,
            },
            {
              name: 'lineState',
              title: this.translate.instant('lineView.tableHeaders.lineState'),
              groupName: 'siteDepartmentLineGroup',
              selected: true,
            },
          ],
        },
        {
          name: 'jobProductWorkOrderGroup',
          title: this.translate.instant('generalConfiguration.modal.columns.groups.jobProductWorkOrderGroup'),
          items: [
            {
              name: 'job',
              title: this.translate.instant('lineView.tableHeaders.job'),
              groupName: 'jobProductWorkOrderGroup',
              selected: false,
            },
            {
              name: 'productDescription',
              title: this.translate.instant('lineView.tableHeaders.productDescription'),
              groupName: 'jobProductWorkOrderGroup',
              selected: false,
            },
            {
              name: 'productId',
              title: this.translate.instant('lineView.tableHeaders.productId'),
              groupName: 'jobProductWorkOrderGroup',
              selected: false,
            },
            {
              name: 'workOrderName',
              title: this.translate.instant('lineView.tableHeaders.workOrderName'),
              groupName: 'jobProductWorkOrderGroup',
              selected: true,
            },
            {
              name: 'workOrderPhase',
              title: this.translate.instant('lineView.tableHeaders.workOrderPhase'),
              groupName: 'jobProductWorkOrderGroup',
              selected: true,
            },
            {
              name: 'workOrderPhaseDuration',
              title: this.translate.instant('lineView.tableHeaders.workOrderPhaseDuration'),
              groupName: 'jobProductWorkOrderGroup',
              selected: true,
            },
            {
              name: 'workOrderGoodCount',
              title: this.translate.instant('lineView.tableHeaders.workOrderGoodCount'),
              groupName: 'jobProductWorkOrderGroup',
              selected: true,
            },
            {
              name: 'averageSpeed',
              title: this.translate.instant('lineView.tableHeaders.averageSpeed'),
              groupName: 'jobProductWorkOrderGroup',
              selected: true,
            },
            {
              name: 'workOrderDuration',
              title: this.translate.instant('lineView.tableHeaders.workOrderDuration'),
              groupName: 'jobProductWorkOrderGroup',
              selected: true,
            },
            {
              name: 'scrapQuantity',
              title: this.translate.instant('lineView.tableHeaders.scrapQuantity'),
              groupName: 'jobProductWorkOrderGroup',
              selected: true,
            },
            {
              name: 'workOrderPhaseStart',
              title: this.translate.instant('lineView.tableHeaders.workOrderPhaseStart'),
              groupName: 'jobProductWorkOrderGroup',
              selected: false,
            },
          ],
        },
        {
          name: 'activityGroup',
          title: this.translate.instant('generalConfiguration.modal.columns.groups.activityGroup'),
          items: [
            {
              name: 'activityName',
              title: this.translate.instant('lineView.tableHeaders.activityName'),
              groupName: 'activityGroup',
              selected: true,
            },
            {
              name: 'activityStartTime',
              title: this.translate.instant('lineView.tableHeaders.activityStartTime'),
              groupName: 'activityGroup',
              selected: true,
            },
            {
              name: 'activityDuration',
              title: this.translate.instant('lineView.tableHeaders.activityDuration'),
              groupName: 'activityGroup',
              selected: true,
            },
            {
              name: 'runTimeAverageSpeed',
              title: this.translate.instant('lineView.tableHeaders.runTimeAverageSpeed'),
              groupName: 'activityGroup',
              selected: true,
            },
            {
              name: 'taskIssueName',
              title: this.translate.instant('lineView.tableHeaders.task'),
              groupName: 'activityGroup',
              selected: false,
            },
          ],
        },
        {
          name: 'equipmentAndAsset',
          title: this.translate.instant('generalConfiguration.modal.columns.groups.equipmentAndAsset'),
          items: [
            {
              name: 'nameOfCheckInAssets',
              title: this.translate.instant('lineView.tableHeaders.nameOfCheckInAssets'),
              groupName: 'equipmentAndAsset',
              selected: false,
            },
            {
              name: 'numberOfCheckInAssets',
              title: this.translate.instant('lineView.tableHeaders.numberOfCheckInAssets'),
              groupName: 'equipmentAndAsset',
              selected: false,
            },
            {
              name: 'equipment',
              title: this.translate.instant('lineView.tableHeaders.equipment'),
              groupName: 'equipmentAndAsset',
              selected: false,
            },
          ],
        },
        {
          name: 'usersGroup',
          title: this.translate.instant('generalConfiguration.modal.columns.groups.usersGroup'),
          items: [
            {
              name: 'nameOfCheckInUsers',
              title: this.translate.instant('lineView.tableHeaders.nameOfCheckInUsers'),
              groupName: 'usersGroup',
              selected: false,
            },
            {
              name: 'numberOfCheckInUsers',
              title: this.translate.instant('lineView.tableHeaders.numberOfCheckInUsers'),
              groupName: 'usersGroup',
              selected: false,
            },
            {
              name: 'laborCapacity',
              title: this.translate.instant('lineView.tableHeaders.laborCapacity'),
              groupName: 'usersGroup',
              selected: false,
            },
            {
              name: 'operatorName',
              title: this.translate.instant('lineView.tableHeaders.lastActionBy'),
              groupName: 'usersGroup',
              selected: false,
            },
            {
              name: 'operators',
              title: this.translate.instant('lineView.tableHeaders.operators'),
              groupName: 'usersGroup',
              selected: false,
            },
            {
              name: 'supervisor',
              title: this.translate.instant('lineView.tableHeaders.supervisor'),
              groupName: 'usersGroup',
              selected: false,
            },
            {
              name: 'lineLeader',
              title: this.translate.instant('lineView.tableHeaders.lineLeader'),
              groupName: 'usersGroup',
              selected: false,
            },
            {
              name: 'admin',
              title: this.translate.instant('lineView.tableHeaders.admin'),
              groupName: 'usersGroup',
              selected: false,
            },
          ],
        },
        {
          name: 'durationGroup',
          title: this.translate.instant('generalConfiguration.modal.columns.groups.duration'),
          items: [
            {
              name: 'PreRunDuration',
              title: this.translate.instant('lineView.tableHeaders.PreRunDuration'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'RunDuration',
              title: this.translate.instant('lineView.tableHeaders.RunDuration'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'PostRunDuration',
              title: this.translate.instant('lineView.tableHeaders.PostRunDuration'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'preRunVersusTargetPercentage',
              title: this.translate.instant('lineView.tableHeaders.preRunVersusTargetPercentage'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'preRunVersusTargetNumerical',
              title: this.translate.instant('lineView.tableHeaders.preRunVersusTargetNumerical'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'runVersusTargetPercentage',
              title: this.translate.instant('lineView.tableHeaders.runVersusTargetPercentage'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'runVersusTargetNumerical',
              title: this.translate.instant('lineView.tableHeaders.runVersusTargetNumerical'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'postRunVersusTargetPercentage',
              title: this.translate.instant('lineView.tableHeaders.postRunVersusTargetPercentage'),
              groupName: 'durationGroup',
              selected: false,
            },
            {
              name: 'postRunVersusTargetNumerical',
              title: this.translate.instant('lineView.tableHeaders.postRunVersusTargetNumerical'),
              groupName: 'durationGroup',
              selected: false,
            },
          ],
        },
        {
          name: 'oeeGroup',
          title: 'OEE',
          items: [
            {
              name: 'availability',
              title: this.translate.instant('lineView.tableHeaders.availability'),
              groupName: 'oeeGroup',
              selected: false,
            },
            {
              name: 'performance',
              title: this.translate.instant('lineView.tableHeaders.performance'),
              groupName: 'oeeGroup',
              selected: false,
            },
            {
              name: 'quality',
              title: this.translate.instant('lineView.tableHeaders.quality'),
              groupName: 'oeeGroup',
              selected: false,
            },
            {
              name: 'todayOEE',
              title: this.translate.instant('lineView.tableHeaders.todayOEE'),
              groupName: 'oeeGroup',
              selected: false,
            },
            {
              name: 'oeeTrend',
              title: this.translate.instant('lineView.tableHeaders.oeeTrend'),
              groupName: 'oeeGroup',
              selected: false,
            },
          ],
        },
        {
          name: 'othersGroup',
          title: this.translate.instant('generalConfiguration.modal.columns.groups.othersGroup'),
          items: [
            {
              name: 'liveCamera',
              title: this.translate.instant('lineView.tableHeaders.liveCamera'),
              groupName: 'othersGroup',
              selected: false,
            },
            {
              name: 'processOrder',
              title: this.translate.instant('lineView.tableHeaders.processOrder'),
              groupName: 'othersGroup',
              selected: false,
            },
            {
              name: 'nameOfCheckInMaintenanceUsers',
              title: this.translate.instant('lineView.tableHeaders.nameOfCheckInMaintenanceUsers'),
              groupName: 'othersGroup',
              selected: false,
            },
            {
              name: 'meta.missingDataCount',
              title: this.translate.instant('lineView.tableHeaders.missingDataCount'),
              groupName: 'othersGroup',
              selected: false,
            },
          ],
        },
      ],
      selectedColumnsDescription: this.translate.instant('generalConfiguration.modal.columns.description'),
      selectedItems: [],
      headerGroupDomIds: [],
    },
    {
      type: EConfigurationTypes.COLUMN_SORT_ORDER,
      name: 'lineStateSortOrder',
      title: this.translate.instant('generalConfiguration.modal.sortOrders.lineStateSortOrder.title'),
      description: this.translate.instant('generalConfiguration.modal.sortOrders.lineStateSortOrder.description'),
      items: [
        {
          name: 'runTime',
          title: this.translate.instant('general.activityType.runTime'),
        },
        {
          name: 'downTime',
          title: this.translate.instant('general.activityType.downTime'),
        },
        {
          name: 'downTimePlanned',
          title: this.translate.instant('general.activityType.downTimePlanned'),
        },
        {
          name: 'idleTime',
          title: this.translate.instant('general.activityType.idleTime'),
        },
      ],
    },
    {
      type: EConfigurationTypes.TARGET_EXCEEDED_HIGHLIGHT_COLUMNS,
      name: 'targetExceededHighlightColumns',
      title: this.translate.instant('generalConfiguration.modal.highlightColumns.title'),
      description: this.translate.instant('generalConfiguration.modal.highlightColumns.description'),
      items: [
        {
          id: 'workOrderDuration',
          name: this.translate.instant('lineView.tableHeaders.workOrderDuration'),
        },
        {
          id: 'PreRunDuration',
          name: this.translate.instant('lineView.tableHeaders.PreRunDuration'),
        },
        {
          id: 'RunDuration',
          name: this.translate.instant('lineView.tableHeaders.RunDuration'),
        },
        {
          id: 'PostRunDuration',
          name: this.translate.instant('lineView.tableHeaders.PostRunDuration'),
        },
      ],
      selectedItems: [],
    },
  ];

  protected lineViewEnhancedConfiguration: TGeneralConfigurationSection[];
  private initialLineViewEnhancedConfiguration: TGeneralConfigurationSection[];

  protected spotlightOverlayDisplay: 'block' | 'none' = 'none';
  protected userLanguage: string = 'en';
  public updateContent(contentHeaders: IScwMatKpiCard, targetHeader: IWidgetConfiguration): IScwMatKpiCard {
    return {
      ...targetHeader.content,
      disabled: !targetHeader.selected,
      displayRenderProperties: {
        ...targetHeader.content.displayRenderProperties,
        pageConfigurationMode: true,
      },
    };
  }

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
    private readonly toastHelperService: ToastHelperService,
  ) {
    this.lineViewEnhancedConfiguration = this.assignSelectedHeaderItemsToSelectedItemsColumn();
    this.initialLineViewEnhancedConfiguration = HelperService.cloneDeep(this.lineViewEnhancedConfiguration);
  }

  public ngOnInit(): void {
    this.storeSubscriptions.push(
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((user: User) => {
          this.userLanguage = user.language.toLowerCase();
        }),
      this.store.select('userConfigurationStore').subscribe((state: UserConfigurationStateInterface) => {
        if (state.userConfigurationData.LineViewEnhancedComponent) {
          const selectedColumns: string[] = _.find(state.userConfigurationData.LineViewEnhancedComponent, {
            name: 'tableColumns',
          })?.value;

          if (!_.isEmpty(selectedColumns)) {
            this.lineViewEnhancedConfiguration = this.assignSelectedHeaderItemsToSelectedItemsColumn(selectedColumns);
          }

          const selectedWidgets: string[] = _.find(state.userConfigurationData.LineViewEnhancedComponent, {
            name: 'widgetSettings',
          })?.value;

          if (!_.isEmpty(selectedWidgets)) {
            const widgetConfiguration: IGeneralConfigurationWidgetSection = _.find(this.lineViewEnhancedConfiguration, {
              name: 'widgets',
            });
            const selectedWidgetConfiguration: IWidgetConfiguration[] = [];

            selectedWidgets.forEach((selectedWidget: string) => {
              selectedWidgetConfiguration.push({
                ..._.find(widgetConfiguration.items, { name: selectedWidget }),
                selected: true,
              });
            });

            selectedWidgetConfiguration.push(
              ..._.differenceBy(widgetConfiguration.items, selectedWidgetConfiguration, 'name').map(
                (unselectedWidget) => {
                  return {
                    ...unselectedWidget,
                    selected: false,
                  };
                },
              ),
            );

            _.set(widgetConfiguration, 'items', selectedWidgetConfiguration);
          }

          const selectedSortOrders: IComponentConfiguration[] = _.find(
            state.userConfigurationData.LineViewEnhancedComponent,
            {
              name: 'sortOrders',
            },
          )?.children;

          if (!_.isEmpty(selectedSortOrders)) {
            selectedSortOrders.forEach((sortOrder: IComponentConfiguration) => {
              const sortOrderConfig: IGeneralConfigurationColumnSortOrderSection = _.find(
                this.lineViewEnhancedConfiguration,
                { name: sortOrder.name },
              );
              const customOrder: IColumnSortOrderItem[] = [];

              (sortOrder.value as string[]).forEach((sortOrderValue: string) => {
                customOrder.push(_.find(sortOrderConfig.items, { name: sortOrderValue }));
              });

              _.set(sortOrderConfig, 'items', customOrder);
            });
          }

          const selectedHighlightColumns: string[] = _.find<IComponentConfiguration>(
            state.userConfigurationData.LineViewEnhancedComponent,
            {
              name: 'highlightColumns',
            },
          )?.value;

          if (!_.isEmpty(selectedHighlightColumns)) {
            const selectedHighlightColumnsConfig: IDropdownOptions[] =
              _.find<IGeneralConfigurationTargetExceededHighlightColumnsSection>(this.lineViewEnhancedConfiguration, {
                name: 'targetExceededHighlightColumns',
              }).selectedItems;

            selectedHighlightColumns.forEach((selectedHighlightColumn: string) => {
              selectedHighlightColumnsConfig.push({
                id: selectedHighlightColumn,
                name: this.translate.instant(`lineView.tableHeaders.${selectedHighlightColumn}`),
              });
            });
          }

          this.initialLineViewEnhancedConfiguration = HelperService.cloneDeep(this.lineViewEnhancedConfiguration);
        }
      }),
    );
  }

  private assignSelectedHeaderItemsToSelectedItemsColumn(
    userSelectedColumnSettings?: string[],
  ): TGeneralConfigurationSection[] {
    const lineViewEnhancedConfiguration: TGeneralConfigurationSection[] = HelperService.cloneDeep(
      this.defaultLineViewEnhancedConfiguration,
    );

    _.filter<IGeneralConfigurationTableHeaderSection>(lineViewEnhancedConfiguration, {
      type: EConfigurationTypes.TABLE,
    }).forEach((tableConfig: IGeneralConfigurationTableHeaderSection) => {
      tableConfig.items.forEach((headerGroup: ITableHeaderGroup) => {
        tableConfig.headerGroupDomIds.push(headerGroup.name);

        tableConfig.selectedItems.push(
          ...headerGroup.items.filter((item: ITableHeaderGroupItem) => {
            if (userSelectedColumnSettings) {
              item.selected = userSelectedColumnSettings.indexOf(item.name) !== -1;
            }

            return item.selected;
          }),
        );

        if (userSelectedColumnSettings) {
          tableConfig.selectedItems?.sort(
            (lhs: ITableHeaderGroupItem, rhs: ITableHeaderGroupItem) =>
              userSelectedColumnSettings.indexOf(lhs.name) - userSelectedColumnSettings.indexOf(rhs.name),
          );
        }

        _.set(headerGroup, 'items', _.differenceBy(headerGroup.items, tableConfig.selectedItems, 'name'));
      });
    });

    return lineViewEnhancedConfiguration;
  }

  protected showModal(templateRef: TemplateRef<any>): void {
    this.configModalRef = this.modalService.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalLg,
    });
    this.modalArray.push(this.configModalRef);

    this.store.dispatch(new PageHeaderActions.CountdownPause());
    this.store.dispatch(new PageHeaderActions.CountdownDisable());
  }

  protected onClickClose(): void {
    if (this.areChangesMade) {
      this.saveChangesModalRef = this.modalService.open(this.unsavedChangesModalTemplateRef, {
        keyboard: false,
        backdrop: 'static',
        windowClass: this.scwModalSm,
      });
      this.modalArray.push(this.saveChangesModalRef);
    } else {
      this.configModalRef.close();
      this.resetSearchInputModel();
      this.store.dispatch(new PageHeaderActions.CountdownReset());
    }
  }

  protected apply(setAsDefault: boolean): void {
    this.areChangesMade = false;
    this.configModalRef?.close();

    if (this.saveChangesModalRef) {
      this.saveChangesModalRef.close();
    }

    this.resetSearchInputModel();
    this.initialLineViewEnhancedConfiguration = HelperService.cloneDeep(this.lineViewEnhancedConfiguration);

    this.store.dispatch(
      new LineViewEnhancedActions.SetGeneralConfiguration(
        HelperService.cloneDeep(this.lineViewEnhancedConfiguration),
        setAsDefault,
      ),
    );
  }

  protected dontApply(): void {
    this.areChangesMade = false;

    this.saveChangesModalRef.close();
    this.onClickClose();
    this.resetSearchInputModel();

    this.lineViewEnhancedConfiguration = HelperService.cloneDeep(this.initialLineViewEnhancedConfiguration);
  }

  protected drop<Type>(items: Type[], event: CdkDragDrop<Type[]>): void {
    this.areChangesMade = true;
    moveItemInArray<Type>(event.container.data, event.previousIndex, event.currentIndex);
  }

  protected dropHeaderItem(items: ITableHeaderGroupItem[], event: CdkDragDrop<ITableHeaderGroupItem[]>): void {
    this.areChangesMade = true;

    if (event.container === event.previousContainer) {
      const isItemRequired: boolean = event.item.data.isRequired;
      const clientX: number = event.event.type.includes('mouse')
        ? (event.event as MouseEvent)?.clientX
        : (event.event as TouchEvent)?.changedTouches[0]?.clientX;

      if (_.isNil(clientX)) {
        return;
      }

      if (_.isArray(event.container.connectedTo)) {
        if (clientX < window.innerWidth / 2) {
          if (isItemRequired) {
            this.displayRequiredColumnToast(event.item.data.title);
            return;
          }

          const targetArray: ITableHeaderGroupItem[] = _.find<ITableHeaderGroup>(
            _.find<IGeneralConfigurationTableHeaderSection>(this.lineViewEnhancedConfiguration, {
              name: 'headerGroups',
            })?.items,
            {
              name: event.item.data.groupName,
            },
          )?.items;

          if (!_.isNil(targetArray)) {
            transferArrayItem<ITableHeaderGroupItem>(
              event.previousContainer.data,
              targetArray,
              event.previousIndex,
              targetArray.length,
            );
          }
        } else {
          moveItemInArray<ITableHeaderGroupItem>(event.container.data, event.previousIndex, event.currentIndex);
        }
      } else {
        if (clientX > window.innerWidth / 2) {
          if (isItemRequired) {
            this.displayRequiredColumnToast(event.item.data.title);
            return;
          }

          const targetArray: ITableHeaderGroupItem[] = _.find<IGeneralConfigurationTableHeaderSection>(
            this.lineViewEnhancedConfiguration,
            {
              name: 'headerGroups',
            },
          )?.selectedItems;

          if (!_.isNil(targetArray)) {
            transferArrayItem<ITableHeaderGroupItem>(
              event.container.data,
              targetArray,
              event.previousIndex,
              targetArray.length,
            );
          }
        } else {
          moveItemInArray<ITableHeaderGroupItem>(event.container.data, event.previousIndex, event.currentIndex);
        }
      }
    } else {
      const prevIndex: number = _.findIndex(event.previousContainer.data, (item) => item.name === event.item.data.name);

      transferArrayItem<ITableHeaderGroupItem>(
        event.previousContainer.data,
        event.container.data,
        prevIndex,
        event.currentIndex,
      );
    }
  }

  private displayRequiredColumnToast(columnName: string): void {
    this.toastHelperService.showToastMessage(
      false,
      this.translate.instant('generalConfiguration.validation.requiredColumn.title', {
        columnName: columnName,
      }),
      this.translate.instant('generalConfiguration.validation.requiredColumn.message', {
        columnName: columnName,
      }),
    );
  }

  protected dragStarted(event): void {
    this.spotlightOverlayDisplay = 'block';

    if (event.source.dropContainer.id.includes('-drop-list')) {
      document.getElementById(event.source.data.groupName).classList.add('overlay-active');
      document.getElementById(event.source.dropContainer.id).classList.add('overlay-active');
    } else {
      document.getElementById(event.source.dropContainer.connectedTo).classList.add('overlay-active');
    }
  }

  protected dragReleased(event): void {
    this.spotlightOverlayDisplay = 'none';

    if (event.source.dropContainer.id.includes('-drop-list')) {
      document.getElementById(event.source.dropContainer.id).classList.remove('overlay-active');
      document.getElementById(event.source.data.groupName).classList.remove('overlay-active');
    } else {
      document.getElementById(event.source.dropContainer.connectedTo).classList.remove('overlay-active');
    }
  }

  protected selectedOrRequiredPredicate(item: CdkDrag<ITableHeaderGroupItem>, list: CdkDropList): boolean {
    return item.data.selected && item.data.groupName === list.id && !item.data.isRequired;
  }

  protected widgetSelection($event, widget): void {
    this.areChangesMade = true;

    widget.selected = !widget.selected;
    widget.disabled = !widget.selected;

    $event.widget.selected = widget.selected;
    $event.widget.disabled = widget.disabled;
    $event.widget.body[0].value = $event.widget.selected ? 'fa-eye fas' : 'fa-eye-slash fas';
  }

  protected onKeyUpSearchBox($event, section: IGeneralConfigurationTableHeaderSection): void {
    section.items.forEach((headerItem: ITableHeaderGroup) =>
      headerItem.items.forEach((item: ITableHeaderGroupItem): void => {
        item.isSearched = $event.length > 1 && item.title.toLowerCase().includes($event.toLowerCase());
      }),
    );

    section.selectedItems.forEach((item: ITableHeaderGroupItem): void => {
      item.isSearched = $event.length > 1 && item.title.toLowerCase().includes($event.toLowerCase());
    });
  }

  public ngOnDestroy(): void {
    this.modalArray.forEach((modal: NgbModalRef): void => {
      modal.close();
    });

    this.storeSubscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  public uppercase = (value: string) => {
    return value.toLocaleUpperCase(this.userLanguage);
  };

  private resetSearchInputModel(): void {
    _.filter(this.lineViewEnhancedConfiguration, {
      type: EConfigurationTypes.TABLE,
    }).forEach((tableConfigurationSection: IGeneralConfigurationTableHeaderSection) => {
      tableConfigurationSection.searchInputModel = '';
      this.onKeyUpSearchBox('', tableConfigurationSection);
    });
  }
}
