import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { activityColors } from '../../../shared/helper/app-helper';
import { AutoPilotStatusesType, SnoozeOptionsInterface } from './alert-pause.model';
import { dateStringToDateObject, getCurrentDateTime } from '../../../shared/helper/date';
import { OeeAppState } from '../../../store/oee.reducer';
import { User } from '../../../store/user/model';
import {
  AlertPauseStateInterface,
  AlertPauseUpdateRequestInterface,
} from '../../../store/alert-pause/alert-pause.model';
import * as AlertPauseActions from '../../../store/alert-pause/alert-pause.actions';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'alert-pause',
  templateUrl: './alert-pause.component.html',
  styleUrls: ['./alert-pause.component.scss'],
})
export class AlertPauseComponent implements OnInit, OnDestroy {
  public clock: Date;
  public autoPilotIsActive: boolean = true;
  public showDurationButtonGroup: boolean = false;
  public pausedDurationMessage: string | null = null;
  public snoozeOptions: SnoozeOptionsInterface[] = [];
  public isLoading$: boolean = false;
  private snoozeUntil$: string;
  private timeZone$: string;
  private lineId$: number;
  private alertSubscription: Subscription;
  private userStoreSubscription: Subscription;
  private interval: NodeJS.Timer;
  private aMinute: number = 60000;
  private indefiniteDateTime: string = '9999-12-31 00:00:00';
  private action: string;
  public pauseModalRef: NgbModalRef;
  public scwModalSm: string = 'scw-modal-sm';

  constructor(
    private translate: TranslateService,
    private mainActions: ActionsSubject,
    private store: Store<OeeAppState>,
    private readonly ngbModal: NgbModal,
  ) {
    this.snoozeOptions = [
      { duration: 15, text: `15${this.translate.instant('general.shortMinute')}` },
      { duration: 60, text: `1${this.translate.instant('general.shortHour')}` },
      { duration: 720, text: `12${this.translate.instant('general.shortHour')}` },
      { duration: 1440, text: `1${this.translate.instant('general.shortDay')}` },
    ];
  }

  ngOnInit() {
    this.userStoreSubscription = this.store.select('user').subscribe((state: User) => {
      this.lineId$ = state.lineId;
      this.timeZone$ = state.timezone;

      if (this.alertSubscription) {
        this.alertSubscription.unsubscribe();
      }

      if (this.pauseModalRef) {
        this.pauseModalRef.dismiss();
      }

      this.alertSubscription = this.store.select('alertPause').subscribe((state: AlertPauseStateInterface) => {
        this.isLoading$ = state.isLoading;

        if (state.isLoaded && !state.isLoading) {
          this.snoozeUntil$ = state.snoozeUntil;
          this.calculateSnooze();
        }
      });
    });

    this.interval = setInterval(() => {
      this.calculateSnooze();
    }, this.aMinute);
  }

  updateSnoozeUntil(action: AutoPilotStatusesType, duration?: number): void {
    this.action = action;
    const requestData: AlertPauseUpdateRequestInterface = {
      action,
    };

    if (action === 'snooze') {
      requestData.duration = duration;
    }

    this.store.dispatch(new AlertPauseActions.StartUpdateAlertPauseDataLoading(requestData, this.lineId$));

    if (this.pauseModalRef) {
      this.pauseModalRef.dismiss();
    }
  }

  showDurationButtons(): void {
    setTimeout(() => {
      this.showDurationButtonGroup = true;
    }, 0);
  }

  activateAutoPilotTemplate(active: boolean): void {
    this.autoPilotIsActive = active;
    this.showDurationButtonGroup = false;
  }

  calculateSnooze(): void {
    this.pausedDurationMessage = null;
    const snoozeUntilDate: Date | string = dateStringToDateObject(this.snoozeUntil$);
    const remainingSnoozeDuration: number =
      snoozeUntilDate instanceof Date ? snoozeUntilDate.getTime() - getCurrentDateTime(this.timeZone$).getTime() : 0;
    const autoPilotInactive: boolean = remainingSnoozeDuration > 0;

    if (autoPilotInactive && this.snoozeUntil$ !== this.indefiniteDateTime) {
      const hour: number = Math.floor(remainingSnoozeDuration / 3600000);
      const minute: number = Math.floor((remainingSnoozeDuration - hour * 3600000) / 60000);

      if (hour > 0) {
        this.pausedDurationMessage = this.translate.instant('alertControl.snooze.pausedDuration', {
          hourDuration: hour,
          minuteDuration: minute,
        });
      } else if (minute > 0) {
        this.pausedDurationMessage = this.translate.instant('alertControl.snooze.pausedDurationOnlyMinute', {
          minuteDuration: minute,
        });
      } else if (remainingSnoozeDuration > 0) {
        this.pausedDurationMessage = this.translate.instant('alertControl.snooze.lessThanAMinute');
      }
    }

    this.activateAutoPilotTemplate(!autoPilotInactive);
  }

  public pauseModal(content: TemplateRef<any>): void {
    this.pauseModalRef = this.ngbModal.open(content, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.scwModalSm,
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);

    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }

    if (this.userStoreSubscription) {
      this.userStoreSubscription.unsubscribe();
    }

    if (this.pauseModalRef) {
      this.pauseModalRef.dismiss();
    }
  }
}
