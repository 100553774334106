import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IWorkOrder } from './job-performance.model';
import { ActivityLogsResponseInterface } from '../root-cause-analysis/root-cause-analysis.model';
import { IActivityLogsRequest } from '../../dashboards/production-view/production-view.model';
import { IOeeCalculationResultFormatted } from '../../../shared/model/interface/generic-api-response.model';
import { ESqlMode, IWorkOrderLaborAssetInformation } from '../work-order-performance/work-order-performance.model';
import { map, mergeMap } from 'rxjs/operators';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { IOeeResponse } from '../deep-dive-analysis/deep-dive-analysis.model';
import { IOeeFilter } from '../../../view/reports/job-performance/job-performance.model';
import { IExcelColumnDefinition } from '../../../shared/service/excel/excel.helper';
import { IWoOeeWithinIntervalDatatableState } from '../../../standalone/wo-oee-within-interval-datatable/wo-oee-within-interval-datatable.model';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { TranslateService } from '@ngx-translate/core';
import { ExcelHelperService } from '../../../shared/service/excel/excel.helper.service';
import * as AppActions from '../../../store/app/actions';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import { ITableHeader } from '../../../../constants.model';
import { IJobOeeResult } from '../../layout-plan-view/layout-plan-view.model';

@Injectable({
  providedIn: 'root',
})
export class JobPerformanceService {
  private readonly routes = {
    workOrders: `${this.baseUrl}/work-orders`,
    oee: `${this.baseUrl}/oee-calculation/calculate-oee`,
    jobOee: `${this.baseUrl}/oee-calculation/job`,
    activityLogs: `${this.baseUrl}/activity-histories/activity-logs`,
    line: `${this.baseUrl}/lines`,
    laborAndAssetDurations: `${this.baseUrl}/work-orders/labor-and-asset-durations`,
    oeeCalculation: `${this.baseUrl}/oee-calculation/calculate-oee`,
  };
  public rowDataFormat = (response): Pick<IWoOeeWithinIntervalDatatableState, 'data' | 'total'> => ({
    total: response.total,
    data: response.data.map(
      (data: IWorkOrder): IWorkOrder => ({
        ...data,
        availabilityPerInterval: this.decimalHelper.toFixedValue(
          this.decimalHelper.multiply(data.lastOeeCalculation?.AvailabilityPerInterval, '100'),
          2,
        ),
        performancePerInterval: this.decimalHelper.toFixedValue(
          this.decimalHelper.multiply(data.lastOeeCalculation?.PerformancePerInterval, '100'),
          2,
        ),
        qualityPerInterval: this.decimalHelper.toFixedValue(
          this.decimalHelper.multiply(data.lastOeeCalculation?.QualityPerInterval, '100'),
          2,
        ),
        overallOEE: this.decimalHelper.toFixedValue(
          this.decimalHelper.multiply(data.lastOeeCalculation?.OverallOEE, '100'),
          2,
        ),
        siteName: data.site.name,
        status: data.completed
          ? this.translate.instant('workOrder.completeStatus.completed')
          : this.translate.instant('workOrder.completeStatus.inProgress'),
      }),
    ),
  });

  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public http: HttpClient,
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly translate: TranslateService,
    private readonly excelService: ExcelHelperService,
  ) {}

  public getWorkOrders(params?: HttpParams): Observable<GetManyResponseInterface<IWorkOrder>> {
    return this.http.get<GetManyResponseInterface<IWorkOrder>>(this.routes.workOrders, { params });
  }

  public getOeeSelectedWorkOrders(
    workOrderId: number[],
    siteId: number,
  ): Observable<GetManyResponseInterface<IOeeCalculationResultFormatted>> {
    const body: IOeeFilter = {
      workOrderId,
      startDate: '2010-01-01',
      endDate: '2037-12-01',
      sqlMode: ESqlMode.INTERVAL,
      oeeType: '-1',
      sites: [siteId],
    };

    return this.http.post<GetManyResponseInterface<IOeeCalculationResultFormatted>>(this.routes.oee, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getOeeOfJob(jobId: number): Observable<IJobOeeResult> {
    return this.http.get<IJobOeeResult>(`${this.routes.jobOee}/${jobId}`);
  }

  public getActivityLogData(
    workOrderIds?: number[],
  ): Observable<BaseOneResponseInterface<ActivityLogsResponseInterface>> {
    const body: IActivityLogsRequest = {
      isBusinessDate: true,
      sites: -1,
      lines: -1,
      lineTypes: -1,
      activities: -1,
      products: -1,
      rootCauseGroups: -1,
      shifts: -1,
      advancedFilterPage: 'job-performance',
      advancedFilterParams: JSON.stringify({ $and: [] }),
      workOrders: workOrderIds,
    };

    return this.http.post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(this.routes.activityLogs, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getWorkOrdersWithLastLine(params?: HttpParams): Observable<GetManyResponseInterface<IWorkOrder>> {
    return this.http.get<GetManyResponseInterface<IWorkOrder>>(this.routes.workOrders, { params }).pipe(
      mergeMap((workOrders: GetManyResponseInterface<IWorkOrder>) => {
        const httpParams: HttpParams = new HttpParams().set(
          's',
          JSON.stringify({ siteId: { $eq: workOrders.data[0]?.siteId } }),
        );

        return this.http
          .get<GetManyResponseInterface<LineCRUDInterface>>(this.routes.line, {
            params: httpParams,
          })
          .pipe(
            map((lines: GetManyResponseInterface<LineCRUDInterface>) => {
              workOrders.data = workOrders.data.map((workOrder: IWorkOrder) => ({
                ...workOrder,
                lastLineTitle: workOrder.lastLine
                  ? lines.data.find((line: LineCRUDInterface) => line.id === workOrder.lastLine)?.title
                  : null,
              }));

              return workOrders;
            }),
          );
      }),
    );
  }

  public getLaborInformation(
    workOrderIds: number[],
  ): Observable<BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>> {
    return this.http.post<BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>>(
      this.routes.laborAndAssetDurations,
      {
        workOrderIds,
        useReplica: true,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public fetchOeeResultByTimeSelection(body: IOeeFilter): Observable<GetManyResponseInterface<IOeeResponse>> {
    return this.http.post<GetManyResponseInterface<IOeeResponse>>(this.routes.oeeCalculation, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public downloadExcel(datatableHeaders: ITableHeader[], workOrderIds: number[]): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const excelColumns: IExcelColumnDefinition[] = [
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'woNumber'),
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'siteName'),
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'lastLineTitle'),
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'status'),
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'availabilityPerInterval', 15),
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'performancePerInterval', 15),
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'qualityPerInterval', 15),
      ExcelHelperService.getGenericReadonlyExcelColumn(datatableHeaders, 'overallOEE', 15),
    ];

    let httpParams: HttpParams = new HttpParams()
      .append('join', 'site||name')
      .append('page', String(1))
      .append('limit', String(1000))
      .append('sort', 'id,DESC');

    if (workOrderIds) {
      httpParams = httpParams.set('s', JSON.stringify({ id: { $in: workOrderIds } }));
    }

    const sourcesObject = {
      rows: this.getWorkOrdersWithLastLine(httpParams).pipe(
        map<GetManyResponseInterface<IWorkOrder>, Pick<any, 'data' | 'total'>>(this.rowDataFormat),
      ),
    };

    forkJoin(sourcesObject).subscribe((payload) => {
      this.excelService.downloadSimpleExcel(
        {
          data: payload.rows.data,
          columns: excelColumns,
        },
        'pageTitles.job-performance',
        { name: 'jobPerformance' },
      );
    });
  }
}
