import { Moment } from 'moment';
import { ELoadStatus } from '../../../../constants.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export interface INotificationLogsState {
  getNotificationLogsStatus: ELoadStatus;
  previewLoadStatus: ELoadStatus;
  notificationLogsData: Pick<GetManyResponseInterface<INotificationLogsDatatableRow>, 'data' | 'total'>;
  previewData: IHtmlPreview;
}

export interface INotificationLogsFilterParams {
  dateRange?: IDateRange;
  recipients?: string[] | string;
  types?: string[] | string;
  status?: number;
  search?: string;
}

export interface INotificationLogsQueryParams {
  start: string;
  end: string;
  recipients?: string[] | string;
  types?: string[] | string;
  search?: string;
}

export interface IDateRange {
  startDate: Moment;
  endDate: Moment;
}

export enum ENotificationType {
  email = 'email',
  sms = 'sms',
  pushNotification = 'push_notification',
}

export interface INotificationLogsDatatableRow {
  id: string;
  title: string;
  type: ENotificationType;
  recipients: { email: string; trackingId: string; statuses: INotificationStatus[] }[];
  loggedAt: string;
}

export enum EEmailAttachmentType {
  INLINE = 'inline',
  REGULAR = 'regular',
}

export interface IEmailAttachment {
  base64: string;
  fileName: string;
  type: EEmailAttachmentType;
}

export interface IEmailNotificationData {
  contentType: 'html';
  subject: string;
  body: string;
  attachments?: IEmailAttachment[];
}

export interface ISmsData {
  text: string;
}

export interface IPushNotificationData {
  title: string;
  body: string;
}

export interface IHtmlPreview {
  title: string | null;
  html: string | null;
}

export interface INotificationStatus {
  _id: string;
  channel: string;
  env: string;
  loggedAt: string;
  notificationType: string;
  serviceName: string;
  shardKeyRef: string;
  status: string;
  trackingId: string;
}

export type TNotificationDataOption = IEmailNotificationData | ISmsData | IPushNotificationData;
