import { ActionReducer, ActionType, createReducer, on } from '@ngrx/store';
import { INotificationFeedState } from './notification-feed.model';
import * as NotificationFeedActions from './notification-feed.actions';

export const notificationFeedInitialState: INotificationFeedState = {
  onlyShowUnread: false,
};

export const notificationFeedReducer: ActionReducer<INotificationFeedState> = createReducer(
  notificationFeedInitialState,
  on(
    NotificationFeedActions.setOnlyShowUnread,
    (
      state: INotificationFeedState,
      action: ActionType<typeof NotificationFeedActions.setOnlyShowUnread>,
    ): INotificationFeedState => ({ ...state, ...action }),
  ),
);
