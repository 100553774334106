import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as ObjectActions from './equipment-task.actions';
import { forkJoin, of } from 'rxjs';
import * as AppActions from '../../store/app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { IEquipmentTaskResponse, IGetNodeQuery, ITaskEquipmentDetail } from './equipment-task.model';
import { EquipmentTaskService } from './equipment-task.service';
import { ETreeRootType, ITreeNode } from '../settings/tree-nodes/tree-nodes.model';
import { TreeNodesService } from '../settings/tree-nodes/tree-nodes.service';
import { ResponseArrayInterface } from '../../shared/model/interface/generic-api-response.model';
import { ITaskTreeNode } from '../../shared/component/activity-buttons/task-selection/task-selection.model';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';

@Injectable()
export class EquipmentTaskEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private readonly service: EquipmentTaskService,
    private readonly treeNodesService: TreeNodesService,
    private store: Store<oeeAppReducer.OeeAppState>,
    private translatorService: EntityTranslatorService,
  ) {}

  nodeDataLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EquipmentTaskActionTypes.NodeDataLoading),
      switchMap((objectData: ObjectActions.NodeDataLoad) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        const searchObject: IGetNodeQuery = { isActive: { $eq: 1 }};
        const searchText: string =
          objectData.search && objectData.search !== '' && objectData.search.trim().length > 0
            ? objectData.search.trim()
            : null;

        if (searchText === null) {
          searchObject.parentId = objectData.parentId ? { $eq: objectData.parentId } : { $isnull: true };
        } else {
          searchObject.name = { $cont: searchText };
        }

        const treeNodeHttpParams = new HttpParams()
          .append('rootType', ETreeRootType.TASK_TREE)
          .append(
            'rootAddress',
            JSON.stringify({
              lineIds: [objectData.rootAddress.lineId],
              activityIds: [objectData.rootAddress.activityId],
            }),
          )
          .append('sort', 'name,ASC')
          .append('s', JSON.stringify(searchObject));

        return forkJoin([
          this.service.getEquipmentsAndTasks(
            objectData.rootAddress.lineId,
            objectData.rootAddress.activityId,
            1,
            searchText,
            objectData.parentId,
            objectData.ignoreHidden,
          ),
          this.treeNodesService.getTreeNodes(treeNodeHttpParams),
        ]).pipe(
          switchMap((response: (IEquipmentTaskResponse | ResponseArrayInterface<ITreeNode>)[]) => {
            response.forEach((response: IEquipmentTaskResponse | ResponseArrayInterface<ITreeNode>) => {
              response.data.forEach((data: ITaskEquipmentDetail | ITreeNode) => {
                this.translatorService.translate(data);
              });
            });

            const nodes: ITaskTreeNode[] = this.service.prepareNodeData(response, objectData.rootAddress.lineId);

            return of(
              new AppActions.HideTopLoader(),
              new ObjectActions.NodeDataLoaded(nodes, objectData.isSkipped ?? false),
            );
          }),
        );
      }),
    ),
  );
}
