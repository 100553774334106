import {
  ETranslatableEntity,
  IEntityTranslation,
  IEntityTranslationsOptions,
  IObjectWithEntityTranslations,
  IUpdatedEntityTranslation,
} from './entity-translator.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { Injectable } from '@angular/core';
import { User } from '../../../store/user/model';
import { EOtherLanguages } from '../../../../constants';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class EntityTranslatorService {
  public static DEFAULT_ENTITY_TRANSLATION_FORM_OPTIONS: Record<EOtherLanguages, string> = {
    [EOtherLanguages.TR]: null,
    [EOtherLanguages.DE]: null,
    [EOtherLanguages.ES]: null,
    [EOtherLanguages.FR]: null,
    [EOtherLanguages.IT]: null,
    [EOtherLanguages.PL]: null,
    [EOtherLanguages.JA]: null,
  };

  private userLanguage: string;

  constructor(private readonly store: Store<AppState>) {
    store.select('user').subscribe((state: User) => {
      this.userLanguage = state.language;
    });
  }

  public translate(entity: IObjectWithEntityTranslations): void {
    if (!(entity?.entityTranslations?.length > 0)) {
      return;
    }

    const translations: IEntityTranslation[] = entity.entityTranslations.filter((translation: IEntityTranslation) => {
      return translation.language.toUpperCase() === this.userLanguage.toUpperCase();
    });

    translations.forEach((translation: IEntityTranslation) => {
      const { propertyName } = translation;
      entity.originalEntityProperty = entity.originalEntityProperty || {};
      entity.originalEntityProperty[propertyName] = _.get(entity, propertyName);
      _.set(entity, propertyName, translation.value);
    });
  }

  public static reduceEntityTranslations(
    entityTranslations: IEntityTranslation[],
    propertyName: string,
  ): Record<EOtherLanguages, string> {
    return entityTranslations.reduce(
      (object: Record<EOtherLanguages, string>, entityTranslation: IEntityTranslation) => {
        if (entityTranslation.propertyName === propertyName) {
          object[(entityTranslation.language as unknown as string).toLowerCase()] = entityTranslation.value;
        }
        return object;
      },
      _.cloneDeep(EntityTranslatorService.DEFAULT_ENTITY_TRANSLATION_FORM_OPTIONS),
    );
  }

  public static convertFormTranslationsToEntityTranslations<T>(
    formTranslations: IEntityTranslationsOptions<T>[],
  ): IUpdatedEntityTranslation<T>[] {
    const updatedEntityTranslations: IUpdatedEntityTranslation<T>[] = [];
    formTranslations.forEach((formTranslation: IEntityTranslationsOptions<T>) => {
      return Object.keys(formTranslation.languageOptions).map((key: string) => {
        updatedEntityTranslations.push({
          propertyName: formTranslation.propertyName,
          language: key as EOtherLanguages,
          value: formTranslation.languageOptions[key],
        });
      });
    });
    return updatedEntityTranslations;
  }

  public findTranslatedValue(
    entityTranslations: IEntityTranslation[],
    entityName: ETranslatableEntity,
    propertyName: string,
    id: number,
    defaultValue: string,
    language: string = this.userLanguage,
  ): string {
    if (!language || !entityTranslations?.length || language === 'en') {
      return defaultValue;
    }

    const translation = entityTranslations.find(
      (entityTranslation: IEntityTranslation) =>
        entityTranslation.entityName === entityName &&
        entityTranslation.entityId === id &&
        entityTranslation.propertyName === propertyName &&
        (!language || entityTranslation.language === language),
    );
    return translation?.value ?? defaultValue;
  }
}
