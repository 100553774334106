export const mysqlTimestampFormat = 'YYYY-MM-DD HH:mm:ss';
export const mysqlDateFormat = 'YYYY-MM-DD';
export const iosTimestampFormat = 'YYYY/MM/DD HH:mm:ss';
export const mysqlTimestampFormatZeroSecond = 'YYYY-MM-DD HH:mm:00';
export const generalDateTimeFormat: string = 'MMM d, yyyy h:mm aa';
export const monthAndHourAmPmFormat: string = 'MMM D, h:mm A';
export const monthAnd24HourFormat: string = 'MMM D, H:mm';
export const defaultDateFormat: string = 'dd/MM/yyyy';
export const monthNameDateFormat: string = 'DD MMM YYYY';
export const momentWeekFormat: string = 'GGGG/WW';
export const momentMonthFormat: string = 'YYYY/MM';
export const mysqlTimestampFormatZeroMinAndSec = 'YYYY-MM-DD HH:00:00';
export const customDotDateFormat = 'DD.MM.YYYY';
export const defaultExcelDateTimeFormat: string = 'L LT';
export const zeroMinAndSecTime: string = 'HH:00:00';
import * as momentTz from 'moment-timezone';
import * as moment from 'moment';
import { DECIMAL_NUMERIC_PRECISION } from '../../../constants';

export function formatDateTime(date) {
  let result = '';
  if (date instanceof Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    result = `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
  }
  return result;
}

export function formatDate(date: Date) {
  let result = '';
  if (date instanceof Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    result = `${year}-${month}-${day}`;
  }
  return result;
}

export function formatTime(date: Date) {
  let result = '';
  if (date instanceof Date) {
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    result = `${hour}:${minute}`;
  }
  return result;
}

export function changeTimezone(datetime, ianatz) {
  if (datetime !== null && datetime !== '') {
    let date: any;
    if (datetime instanceof Date) {
      date = datetime;
    } else {
      date = new Date(datetime.replace(/-/g, '/'));
    }
    const invdate = new Date(date.toLocaleString('en-US', {
      timeZone: ianatz,
    }));
    const diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() + diff);
  }
  return '';
}

export function dateStringToDateObject(datetime) {
  let result:any;
  if (datetime !== null && datetime !== '' && datetime !== undefined) {
    if (datetime instanceof Date) {
      result = datetime;
    } else {
      result = new Date(datetime.replace(/T/g, ' ').replace(/-/g, '/'));
    }
  } else {
    result = '';
  }
  return result;
}

export function secondsToHms(number, translate) {
  let result = null;
  if (number !== undefined && number !== null && number !== '' && typeof number === 'number') {
    const h = Math.floor(number / 3600);
    const m = Math.floor(number % 3600 / 60);
    const s = Math.floor(number % 3600 % 60);

    let hDisplay = '';
    let mDisplay = '';
    let sDisplay = '';
    result = [];

    if (h > 0) {
      hDisplay = h + translate.hour;
      result.push(hDisplay);
    }

    if (m > 0) {
      mDisplay = m + translate.minute;
      result.push(mDisplay);
    }

    if (s > 0) {
      sDisplay = s + translate.second;
      result.push(sDisplay);
    }
    result = result.join(':');
  }
  return result;
}

export function minutesToHm(minutes: number): string {
  const h: number = Math.floor(minutes / 60);
  const m: number = Math.floor(minutes % 60);
  const result: string[] = [];

  result.push(h > 0 ? h.toString().padStart(2, '0') : '00');
  result.push(m > 0 ? m.toString().padStart(2, '0') : '00');

  return result.join(':');
}

export function replaceDateStringForValidator(dateString: string) {
  if (typeof dateString !== 'string') {
    return '';
  }

  return dateString.replace(/T/g, ' ')
    .replace(/-/g, '/')
    .substring(0, 19);
}

export function getCurrentDateTime(timeZone: string): Date {
  const formattedMoment = momentTz().tz(timeZone).format(mysqlTimestampFormat);
  if (typeof formattedMoment === 'string') {
    return dateStringToDateObject(formattedMoment);
  }
  return new Date(formattedMoment);
}

export function getCurrentDateTimeAsMoment(timeZone: string): moment.Moment {
  return momentTz().tz(timeZone);
}

export function transformDurationType(
  duration: number,
  transformFromType: moment.unitOfTime.Base,
  transformToType: moment.unitOfTime.Base,
  precision: number = DECIMAL_NUMERIC_PRECISION,
): number {
  return Number(moment.duration(duration, transformFromType).as(transformToType).toFixed(precision));
}
