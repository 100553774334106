import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { Inject, Injectable } from '@angular/core';
import {
  ICommentLogsDeleteResponse,
  IGetCommentLogRowResponse,
  ICommentLogsTableData,
  ICommentLogsRawData,
  ECommentLogsObjectName,
  ECommentLogsScopeName,
  ICommentLogsQueryParams,
  ICommentLogsQueryParamsFormatted,
  ICommentLogsTableDataResponse,
  ICommentCountWithLastCommentMessage,
  ICommentCountWithLastCommentMessageParams,
  ICommentMessage,
} from './comment-logs.model';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrderDeleteResponseInterface } from '../../../shared/service/work-order-schedule/work-order-schedule.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { FilterHelperService } from 'src/app/shared/service/filter/filter.helper.service';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import { TagInterface } from '../../settings/tags/tags.model';
import { HelperService } from '../../../shared/service/helper.service';
import { TagsService } from '../../settings/tags/tags.service';

@Injectable({
  providedIn: 'root',
})
export class CommentLogsService {
  constructor(
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly tagService: TagsService,
    private readonly helperService: HelperService,
  ) {}

  public static excludedCommentTypes: string[] = [
    'shift_quality',
    'shift_goal_hit',
    'shift_data_quality',
    'shift_safety',
  ];
  private readonly notApplicable: string = 'N/A';
  private readonly routes = {
    comments: '/comments',
    commentLogs: '/comments/comment-logs',
    singleDelete: '/comments',
    bulkDelete: '/comments/bulk/delete',
    singleEdit: '/comments',
    bulkEdit: '/comments/bulk/edit',
    commentCountWithLastCommentMessage: '/comments/comment-count-with-last-comment-message',
  };

  public getCommentLogsData<T extends Partial<ICommentLogsRawData> = Partial<ICommentLogsRawData>>(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<T>> {
    return this.http.get<GetManyResponseInterface<T>>(`${this.api}${this.routes.comments}`, { params });
  }

  public getCommentLogsTableData(
    body: ICommentLogsQueryParamsFormatted,
  ): Observable<GetManyResponseInterface<ICommentLogsTableDataResponse>> {
    return this.http.post<GetManyResponseInterface<ICommentLogsTableDataResponse>>(
      `${this.api}${this.routes.commentLogs}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getCommentLogData(commentId: number): Observable<IGetCommentLogRowResponse> {
    return this.http.get<IGetCommentLogRowResponse>(`${this.api}${this.routes.comments}/${commentId}`);
  }

  public editCommentLogData<T extends Partial<ICommentLogsRawData> = Partial<ICommentLogsRawData>>(
    comment: Partial<ICommentLogsRawData>,
    commentId: number,
    params?: HttpParams,
  ): Observable<IGetCommentLogRowResponse<T>> {
    return this.http.patch<IGetCommentLogRowResponse<T>>(
      `${this.api}${this.routes.singleEdit}/${commentId}`,
      { ...comment },
      { params },
    );
  }

  public setEditableFieldsGetMany(response: GetManyResponseInterface<ICommentLogsTableDataResponse>): void {
    response.data.forEach((objectData: ICommentLogsTableDataResponse) => {
      objectData.editableFields = 'commentMessage';
    });
  }

  public setEditableFieldsGetOne(response: IGetCommentLogRowResponse): void {
    response.data.editableFields = 'commentMessage';
  }

  public deleteCommentLogsRows(commentLogs: number[] | number): Observable<ICommentLogsDeleteResponse> {
    if (Array.isArray(commentLogs) && commentLogs.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          comments: commentLogs,
        },
      };
      return this.http.delete<WorkOrderDeleteResponseInterface>(`${this.api}${this.routes.bulkDelete}`, httpOptions);
    }

    return this.http.delete<WorkOrderDeleteResponseInterface>(
      `${this.api}${this.routes.singleDelete}/${Array.isArray(commentLogs) ? commentLogs[0] : commentLogs}`,
    );
  }

  public getScopeNameFromCommentLogsRowData(row: ICommentLogsTableDataResponse): string {
    let scopeName: string = null;
    let rawDataObjectTypeKey: string = null;
    let rawDataObjectTypeValue: string = null;
    if (!_.isNil(row.objectType)) {
      rawDataObjectTypeKey = ECommentLogsObjectName[row.objectType];
      rawDataObjectTypeValue = ECommentLogsScopeName[row.objectType];
    }
    if (!_.isNil(rawDataObjectTypeKey) && !_.isNil(rawDataObjectTypeValue) && !_.isNil(row[rawDataObjectTypeKey])) {
      scopeName = row[rawDataObjectTypeKey][rawDataObjectTypeValue];
    }
    return scopeName;
  }

  public getObjectTranslations(translateKey: string): string {
    const translateValue: string = this.translate.instant(translateKey);

    return translateKey === translateValue ? null : translateValue;
  }

  public getCommentMessage(row: ICommentLogsTableDataResponse): string {
    if (!CommentLogsService.excludedCommentTypes.includes(row.commentTypeCategory)) {
      return row.commentMessage ?? null;
    }

    let commentMessage: string = null;

    switch (row.commentTypeCategory) {
      case 'shift_goal_hit':
        commentMessage =
          row.commentMessage === '1'
            ? this.translate.instant('general.yes')
            : row.commentMessage === '-1'
            ? this.notApplicable
            : this.translate.instant('general.no');
        break;
      case 'shift_data_quality':
        switch (row.commentMessage) {
          case '0':
            commentMessage = this.translate.instant('shiftSummary.dropdown.options.toBeConfirmed');
            break;
          case '1':
            commentMessage = this.translate.instant('shiftSummary.dropdown.options.confirmedNoIssues');
            break;
          case '2':
            commentMessage = this.translate.instant('shiftSummary.dropdown.options.confirmedNeedsImprovement');
            break;
        }
        break;
      case 'shift_quality':
      case 'shift_safety':
        commentMessage =
          row.commentMessage === '1'
            ? this.translate.instant('shiftSummary.dropdown.options.incidentOccurred')
            : this.translate.instant('shiftSummary.dropdown.options.noEvents');
        break;
    }

    return commentMessage;
  }

  public formatCommentLogsData(
    data: ICommentLogsTableDataResponse[],
    dateFormat: string,
    dateTimeFormat: string,
    locale: string,
    commentMessageMaxLength: number,
  ): ICommentLogsTableData[] {
    return data.map((datum: ICommentLogsTableDataResponse): ICommentLogsTableData => {
      const commentDate: string = this.getCommentDate(datum, locale, dateFormat, dateTimeFormat);

      return {
        commentDate,
        id: datum.id,
        siteId: Number(datum.siteId),
        siteName: datum.siteName,
        workOrderName: datum.workOrderNumber,
        commentType: this.getObjectTranslations(
          `commentLogs.body.commentType.${_.camelCase(datum.commentTypeCategory)}`,
        ),
        commentTypeRaw: datum.commentTypeCategory,
        objectType: datum.objectType
          ? this.translate.instant(`commentLogs.body.objectType.${_.camelCase(datum.objectType)}`)
          : null,
        objectName: datum.objectName,
        objectProperty: datum.objectProperty
          ? this.getObjectTranslations(`commentLogs.body.objectProperty.${_.camelCase(datum.objectProperty)}`) ??
            datum.objectProperty
          : null,
        commentMessage: this.getCommentMessage(datum) ?? null,
        commentMessageRaw: datum.commentMessage ?? null,
        commentMessageFixedLength: this.truncateCommentMessageWhenLimitExceeded(
          datum.commentMessage,
          commentMessageMaxLength,
        ),
        shiftName: datum.shiftName,
        createdBy: datum.createUserFullName,
        lastChangedBy: datum.changeUserFullName,
        lastChangedAt: datum.lastChangedAt
          ? moment(this.helperService.convertFromISOFormatToGivenTimezone(datum.lastChangedAt))
              .locale(locale)
              .format(dateTimeFormat)
          : null,
        editableFields: datum.editableFields ?? '',
        tags: datum.tags ?? [],
        tagIds: datum.tagIds,
        tagIdFromAi: datum.tagIdFromAi,
        folderId: datum.folderId,
        filePaths: datum.filePaths,
        workOrderId: datum.workOrderId,
      };
    });
  }

  public formatCommentLogsParams(params: ICommentLogsQueryParams): ICommentLogsQueryParamsFormatted {
    return {
      page: params.page ?? 1,
      offset: params.offset ?? 10,
      startDate: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(params.dateRange?.startDate),
      endDate: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(params.dateRange?.endDate),
      siteIds: FilterHelperService.formatDropdownFilterOptionOutput(params?.siteIds),
      workOrderIds: FilterHelperService.formatDropdownFilterOptionOutput(params?.workOrderIds),
      commentTypes: FilterHelperService.formatDropdownFilterOptionOutput(params?.commentTypes) as string[],
      objectTypes: FilterHelperService.formatDropdownFilterOptionOutput(params?.objectTypes) as string[],
      tagIds: params.tagIds ?? -1,
      ...(params.sort?.length ? { sort: `${params.sort[0].column},${params.sort[0].type}` } : undefined),
    };
  }

  private getCommentDate(
    datum: ICommentLogsTableDataResponse,
    locale: string,
    dateFormat: string,
    dateTimeFormat: string,
  ): string {
    if (datum.commentDate || datum.shiftDay || datum.createdAt) {
      return moment(
        this.helperService.convertFromISOFormatToGivenTimezone(datum.commentDate) ||
          datum.shiftDay ||
          this.helperService.convertFromISOFormatToGivenTimezone(datum.createdAt as string),
      )
        .locale(locale)
        .format(!datum.commentDate && datum.shiftDay ? dateFormat : dateTimeFormat);
    }

    return '';
  }

  public getCommentCountWithLastCommentMessage(params: ICommentCountWithLastCommentMessageParams): Observable<GetManyResponseInterface<ICommentCountWithLastCommentMessage>> {
    return this.http.post<GetManyResponseInterface<ICommentCountWithLastCommentMessage>>(
      `${this.api}${this.routes.commentCountWithLastCommentMessage}`,
      params,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      }
    );
  }

  private truncateCommentMessageWhenLimitExceeded(message: string, maxLength: number): string | null {
    const isNumeric: boolean = !isNaN(Number(message));

    if (isNumeric) {
      return null;
    }

    return _.truncate(message, {
      length: maxLength,
      omission: '...',
    });
  }

  public mapTagsToCommentCountWithLastCommentMessageData(commentsData: ICommentCountWithLastCommentMessage[], tagsData: TagInterface[]): void {
    commentsData.map((commentData: ICommentCountWithLastCommentMessage) => {
      commentData.allCommentTags = [];

      commentData.allCommentMessages?.map((commentMessage: ICommentMessage) => {
        const commentTags: number[] = HelperService.cloneDeep(commentMessage.tagIds ?? []);

        if (commentMessage.tagIdFromAi) {
          commentTags.push(commentMessage.tagIdFromAi);
        }

        commentMessage.tags = tagsData
          .filter((tag: TagInterface) => commentTags.includes(tag.id))
          .map(this.tagService.assignTagName);

        if (commentMessage.tags) {
          commentData.allCommentTags.push(...commentMessage.tags);
        }
      });

      commentData.allCommentTags = _.uniqBy(commentData.allCommentTags, 'id');
    });
  }
}
