import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as SensorReportActions from './sensor-reports.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SensorReportsService } from './sensor-reports.service';
import { HttpParams } from '@angular/common/http';
import * as AppActions from '../../app/actions';
import { LineService } from '../../../shared/service/filter/line.service';
import { LineCRUDResponseInterface } from '../../../shared/service/filter/service.class';
import { emptyAction, SensorDataType } from '../../../../constants';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SensorListInterface, SensorReportsDataInterface } from './sensor-reports.model';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class SensorReportsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly sensorReportsService: SensorReportsService,
    private readonly lineService: LineService,
  ) {}

  getSensorList = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorReportActions.START_SENSOR_LIST_LOADING),
      switchMap((objectData: SensorReportActions.StartSensorListLoading) => {
        let params: HttpParams = new HttpParams();
        const searchDataQueryOptions = {
          $and: [
            {
              $or: [
                ...(Array.isArray(objectData.payload?.lineIds)
                  ? [{ lineId: { $in: objectData.payload?.lineIds } }]
                  : []),
                ...(Array.isArray(objectData.payload?.lineStationIds)
                  ? [{ lineStationId: { $in: objectData.payload?.lineStationIds } }]
                  : []),
              ],
            },
            { siteId: { $in: objectData.payload?.siteIds } },
            { id: { $in: objectData.payload?.sensors } },
          ],
        };

        if (objectData.payload) {
          params = params.set('s', JSON.stringify(searchDataQueryOptions));
        }

        params = params
          .append('join', 'equipmentAssignment')
          .append('join', 'line')
          .append('join', 'lineStation')
          .set('sort', 'equipmentAssignment.orderNumber,ASC')
          .append('limit', '1000');

        return from(this.sensorReportsService.getSensorList(params)).pipe(
          switchMap((response: GetManyResponseInterface<SensorListInterface>) => {
            return of(new SensorReportActions.StartSensorListLoaded(response.data));
          }),
          catchError((err) => {
            return of(new SensorReportActions.FetchError(err));
          }),
        );
      }),
      catchError((error) => {
        return of(new SensorReportActions.FetchError(error));
      }),
    ),
  );

  getReportsData = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorReportActions.START_SENSOR_REPORTS_DATA_LOADING),
      switchMap((objectData: SensorReportActions.StartSensorReportsDataLoading) => {
        let params: HttpParams = new HttpParams();

        if (objectData.payload?.startDate) {
          params = params.set('startDate', objectData.payload.startDate);
        }

        if (objectData.payload?.endDate) {
          params = params.set('endDate', objectData.payload.endDate);
        }

        if (objectData.payload?.dataType) {
          params = params.set('dataType', objectData.payload.dataType);
        }

        if (objectData.payload?.sensors) {
          params = params.set('sensors', objectData.payload.sensors.toString());
        }

        this.store.dispatch(new AppActions.ShowLoader());
        return from(this.sensorReportsService.getSensorReportsData(params)).pipe(
          switchMap((response: GetManyResponseInterface<SensorReportsDataInterface>) => {
            return of(new SensorReportActions.StartSensorReportsDataLoaded(response.data));
          }),
          catchError((error) => {
            return of(new SensorReportActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SensorReportActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorReportActions.GET_LINES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().set('fields', 'title').set('limit', '500');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: LineCRUDResponseInterface) => {
            return of(new SensorReportActions.GetLinesCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new SensorReportActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SensorReportActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  sensorReportDownloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorReportActions.DOWNLOAD_EXCEL),
      switchMap((objectData: SensorReportActions.SensorReportDownloadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.sensorReportsService.downloadExcel(objectData.siteId, HelperService.cloneDeep(objectData.excelHeaders), objectData.excelData);

        return emptyAction;
      }),
      catchError((err) => {
        return of(new SensorReportActions.FetchError(err), new AppActions.HideLoader());
      }),
    ),
  );
}
