import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetailCardContent } from './detail-card.model';

@Component({
  selector: 'app-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent implements OnInit {
  @Input() hasBorder: boolean = true;
  @Input() content: DetailCardContent[];
  @Input() nullReplacer: string = '-';
  @Input() isLoading: boolean = false;
  @Input() cardColumnsCls: string = null;
  @Input() progressBarHeight: number = 1;
  @Input() hasPenIcon: boolean = false;

  @Output() onPenIconClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public ngOnInit(): void {}

  public penIconClicked() {
    this.onPenIconClicked.emit();
  }
}
