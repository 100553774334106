import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommentLogsService } from './comment-logs.service';
import * as ObjectActions from '../comment-logs/comment-logs.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { forkJoin, of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import {
  ICommentLogsDeleteResponse,
  IGetCommentLogRowResponse,
  ICommentLogsQueryParamsFormatted,
  ICommentLogsTableDataResponse,
  ICommentCountWithLastCommentMessage,
} from './comment-logs.model';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import * as _ from 'lodash';
import { TagsService } from '../../settings/tags/tags.service';
import { TagInterface, TagsObjectTypes } from '../../settings/tags/tags.model';

@Injectable()
export class CommentLogsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: CommentLogsService,
    private readonly tagsService: TagsService,
    private readonly store: Store<OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  getCommentLogsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.COMMENT_LOGS_DATA_LOADING),
      switchMap((objectData: ObjectActions.CommentLogsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: ICommentLogsQueryParamsFormatted = this.service.formatCommentLogsParams(objectData.params);

        return this.service.getCommentLogsTableData(params).pipe(
          switchMap((response: GetManyResponseInterface<ICommentLogsTableDataResponse>) => {
            this.service.setEditableFieldsGetMany(response);

            return of(new ObjectActions.CommentLogsDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
      }),
    ),
  );

  getCommentLogRowForCommentForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.COMMENT_LOGS_GET_ROW),
      switchMap((objectData: ObjectActions.CommentLogsGetRow) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.getCommentLogData(objectData.commentId).pipe(
          switchMap((response: IGetCommentLogRowResponse) => {
            this.service.setEditableFieldsGetOne(response);
            return of(new ObjectActions.CommentLogsGetRowCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  editCommentLogRowForCommentForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.COMMENT_LOGS_EDIT_ROW),
      switchMap((objectData: ObjectActions.CommentLogsEditRow) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.editCommentLogData(objectData.payload, objectData.commentId).pipe(
          switchMap((response: IGetCommentLogRowResponse) => {
            return of(new ObjectActions.CommentLogsEditRowCompleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteCommentLogsRows = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.COMMENT_LOGS_DELETE_ROWS),
      switchMap((objectData: ObjectActions.CommentLogsDeleteRows) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteCommentLogsRows(objectData.payload).pipe(
          switchMap((response: ICommentLogsDeleteResponse) => {
            if (response.data === undefined || Object.keys(response.data).length === 0) {
              _.set(response, 'data', [
                {
                  success: response.success,
                  message: response.message,
                },
              ]);
            }

            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(new ObjectActions.CommentLogsDeleteRowsCompleted(objectData.payload, response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getCommentCountWithLastCommentMessageData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADING),
      switchMap((objectData: ObjectActions.CommentLogsCountWithLastMessageDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        if (objectData.params.containAllCommentMessages) {
          return forkJoin([
            this.service.getCommentCountWithLastCommentMessage(objectData.params),
            this.tagsService.getTags({
              filters: [{ field: 'objectType', ids: [TagsObjectTypes.Comment, TagsObjectTypes.ScrapCountComment] }],
              page: 1,
              limit: 1000,
            }),
          ]).pipe(
            switchMap(
              (
                response: (
                  | GetManyResponseInterface<ICommentCountWithLastCommentMessage>
                  | GetManyResponseInterface<TagInterface>
                )[],
              ) => {
                let commentsData: ICommentCountWithLastCommentMessage[] = [];
                let tagsData: TagInterface[] = [];

                response.map(
                  (
                    value:
                      | GetManyResponseInterface<ICommentCountWithLastCommentMessage>
                      | GetManyResponseInterface<TagInterface>,
                    index: number,
                  ): void => {
                    switch (index) {
                      case 0:
                        commentsData = value.data as ICommentCountWithLastCommentMessage[];
                        break;
                      case 1:
                        tagsData = value.data as TagInterface[];
                        break;
                    }
                  },
                );

                this.service.mapTagsToCommentCountWithLastCommentMessageData(commentsData, tagsData);

                return of(
                  new ObjectActions.CommentLogsCountWithLastMessageDataLoaded(commentsData),
                  new AppActions.HideLoader(),
                );
              },
            ),
            catchError((err) => {
              return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
            }),
          );
        } else {
          return this.service.getCommentCountWithLastCommentMessage(objectData.params).pipe(
            switchMap((response: GetManyResponseInterface<ICommentCountWithLastCommentMessage>) => {
              return of(
                new ObjectActions.CommentLogsCountWithLastMessageDataLoaded(response.data),
                new AppActions.HideLoader(),
              );
            }),
            catchError((err) => {
              return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
            }),
          );
        }
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
      }),
    ),
  );
}
