import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ELanguages } from '../../../../../constants';
import {
  EquipmentTaskOutputInterface,
  EquipmentTaskStateInterface,
  ITaskEquipmentDetail,
} from '../../../../store/equipment-task/equipment-task.model';
import { EquipmentTaskService } from '../../../../store/equipment-task/equipment-task.service';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as TreeNodesActions from '../../../../store/settings/tree-nodes/tree-nodes.actions';
import * as EquipmentTaskActions from '../../../../store/equipment-task/equipment-task.actions';
import {
  IRootAddress,
  ISkippedTreeNodeResponse,
  ITreeNode,
  TSkippedTreeNodeMetadata,
} from '../../../../store/settings/tree-nodes/tree-nodes.model';
import { User } from '../../../../store/user/model';
import { HelperService } from '../../../service/helper.service';
import { ScwMatButtonGroupButtons } from '../../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { ENodeType, ETaskListMode, INodeGroup, ITaskTreeNode } from './task-selection.model';
import { TaskCRUDInterface } from '../../../../store/settings/tasks/tasks.model';
import * as _ from 'lodash';

@Component({
  selector: 'task-selection',
  templateUrl: './task-selection.component.html',
  styleUrls: ['./task-selection.component.scss'],
})
export class TaskSelectionComponent implements OnInit, OnDestroy {
  @Input() rootAddress: IRootAddress;
  @Input() ignoreHidden: boolean | undefined;
  @Output() selectedTask = new EventEmitter<EquipmentTaskOutputInterface>();

  public nodeGroups: INodeGroup[] = [];
  public selectedNodes: ITaskTreeNode[] = [];
  public searchText: string = '';
  public listMode: ETaskListMode = ETaskListMode.EQUIPMENT_BASED;

  public isLoaded$: boolean = false;
  public showTaskListModeButtons: boolean = false;
  public language$: ELanguages;
  public listModeButtons: ScwMatButtonGroupButtons[] = [
    {
      value: ETaskListMode.EQUIPMENT_BASED,
      text: this.translate.instant('main.equipmentTaskSelection.equipmentBased'),
    },
    {
      value: ETaskListMode.TASK_BASED,
      text: this.translate.instant('main.equipmentTaskSelection.taskBased'),
    },
  ];

  private isFirstStep: boolean;
  private nodes$: ITaskTreeNode[] = [];
  private searchTimeout: ReturnType<typeof setTimeout>;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly translate: TranslateService,
    public readonly helperService: HelperService,
    private readonly store: Store<OeeAppState>,
    private readonly toast: ToastrService,
    private readonly storeActions: ActionsSubject,
  ) {}

  public ngOnInit(): void {
    this.isFirstStep = true;

    this.getNodes();

    this.subscriptions.push(
      this.store.select('equipmentTask').subscribe((state: EquipmentTaskStateInterface) => {
        this.isLoaded$ = !state.nodesDataLoading && state.nodesDataLoaded;

        if (!this.isLoaded$) {
          return;
        }

        this.nodes$ = state.nodes;
        this.renderNodeList(this.nodes$);
      }),
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.language$ = state.language as ELanguages;
        }),
      this.storeActions
        .pipe(ofType(TreeNodesActions.SKIPPED_TREE_NODES_DATA_LOADED))
        .subscribe((response: TreeNodesActions.SkippedTreeNodesDataLoaded) => {
          this.isLoaded$ = true;

          const activityTasks: EquipmentTaskOutputInterface[] = response.payload.data.activityTasks.map(
            (task: TaskCRUDInterface) => this.mapTaskToEquipmentTaskOutputInterface(task),
          );

          const currentTreeNodeTasks: ITaskEquipmentDetail[] = response.payload.data.currentTreeNodeData.tasks;
          const subFolderLength: number = response.payload.data.currentTreeNodeData.children.length;

          if (currentTreeNodeTasks.length === 1 && subFolderLength === 0) {
            this.applyAutomaticSelection(
              {
                taskId: currentTreeNodeTasks[0].id,
                taskName: currentTreeNodeTasks[0].title,
                equipmentId: currentTreeNodeTasks[0]?.equipmentId,
                equipmentName: currentTreeNodeTasks[0]?.equipment?.equipmentName,
                isCommentRequired: currentTreeNodeTasks[0].isCommentRequired,
                phaseId: currentTreeNodeTasks[0].phaseId,
                equipmentOrder: currentTreeNodeTasks[0].order,
                taskNameTranslations: currentTreeNodeTasks[0].titleTranslations,
                meta: currentTreeNodeTasks[0].meta,
              },
              false,
            );
            return;
          }

          if (activityTasks.length === 1) {
            this.applyAutomaticSelection(activityTasks[0]);
            return;
          }

          this.nodes$ = this.prepareNodeData(response.payload.data);

          const currentTreeNode: ITreeNode = response.payload.data.currentTreeNodeData.current;
          const skippedTreeNodeMetadata: TSkippedTreeNodeMetadata[] = currentTreeNode
            ? [
                ...response.payload.data.skippedTreeNodeMetadata,
                { id: currentTreeNode.id, name: currentTreeNode.name, meta: currentTreeNode.meta },
              ]
            : response.payload.data.skippedTreeNodeMetadata;
          const childrenTreeNodes: ITreeNode[] = response.payload.data.currentTreeNodeData.children;
          const currentTasks: ITaskEquipmentDetail[] = response.payload.data.currentTreeNodeData.tasks;

          if (skippedTreeNodeMetadata.length > 0) {
            this.pushFolderTreeNodesToSelectedNodes(skippedTreeNodeMetadata);
          }

          const equipments: Record<number, ITaskTreeNode[]> = _.groupBy(
            this.nodes$.filter((taskTreeNode: ITaskTreeNode) => taskTreeNode.type === ENodeType.Equipment),
            'id',
          );

          const equipmentIdList: string[] = Object.keys(equipments);
          const uniqueEquipmentCount: number = _.uniq(equipmentIdList).length;

          if (
            Object.keys(equipments).length === 1 &&
            childrenTreeNodes.length === 0 &&
            uniqueEquipmentCount === 1 &&
            currentTasks.every((task: ITaskEquipmentDetail) => task.equipmentId === Number(equipmentIdList[0]))
          ) {
            const id: number = Number(Object.keys(equipments)[0]);

            this.onNodeItemClicked(equipments[id][0]);
            return;
          }

          this.renderNodeList(this.nodes$);
        }),
    );
  }

  public onNodeItemClicked(selectedNode: ITaskTreeNode, isSkippingModeEnabled: boolean = true): void {
    this.selectedNodes.push(selectedNode);

    switch (selectedNode.type) {
      case ENodeType.Folder:
        this.searchText = null;
        this.getNodes(selectedNode.id, false, isSkippingModeEnabled);
        break;
      case ENodeType.Task:
        if (!selectedNode.isVirtualGroup) {
          this.selectedTask.emit(selectedNode.taskInfo);
          return;
        }

        const equipments: ITaskTreeNode[] = this.nodes$.filter(
          (node: ITaskTreeNode) => node.title === selectedNode.title && node.type === ENodeType.Task,
        );

        this.renderNodeList(equipments, selectedNode);
        break;
      case ENodeType.Equipment:
        const tasks: ITaskTreeNode[] = this.nodes$.filter(
          (node: ITaskTreeNode) => node.taskInfo?.equipmentName === selectedNode.title && node.type === ENodeType.Task,
        );

        if (tasks.length === 1) {
          this.applyAutomaticSelection(tasks[0].taskInfo, false);
          return;
        }

        this.renderNodeList(tasks, selectedNode);
        break;
      default:
    }
  }

  public onBreadcrumbItemClicked(selectedNode: ITaskTreeNode, index: number): void {
    const latestSelectedNode: ITaskTreeNode | undefined = _.last(this.selectedNodes);
    if (latestSelectedNode && latestSelectedNode.id === selectedNode.id) {
      return;
    }

    this.selectedNodes.splice(index, this.selectedNodes.length);
    this.onNodeItemClicked(selectedNode, false);
  }

  public onSearched(): void {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.listMode = this.searchText ? ETaskListMode.TASK_BASED : ETaskListMode.EQUIPMENT_BASED;
      this.getNodes(undefined, true);
    }, 600);
  }

  public onListModeChanged(mode: ETaskListMode): void {
    this.listMode = mode;
    this.renderNodeList(this.nodes$);
  }

  public onRootIconClicked(): void {
    this.searchText = null;
    this.getNodes(undefined, true, false);
  }

  public getNodes(parentId?: number, clearBreadcrumb: boolean = false, isSkippingModeEnabled: boolean = true): void {
    this.isLoaded$ = true;

    if (clearBreadcrumb) {
      this.selectedNodes = [];
    }

    if ((this.searchText !== '' && this.searchText !== null) || !isSkippingModeEnabled) {
      this.store.dispatch(
        new EquipmentTaskActions.NodeDataLoad(this.rootAddress, this.searchText, parentId, false, !!this.ignoreHidden),
      );

      return;
    }

    this.store.dispatch(new TreeNodesActions.SkippedTreeNodesDataLoad(this.rootAddress, parentId, !!this.ignoreHidden));
  }

  public renderNodeList(nodes: ITaskTreeNode[], selectedNode?: ITaskTreeNode): void {
    const equipments: ITaskTreeNode[] = nodes.filter((node: ITaskTreeNode) => node.type === ENodeType.Equipment);
    this.isFirstStep = false;
    this.nodeGroups = [];
    this.nodeGroups = EquipmentTaskService.getNodeGroups(nodes, this.listMode, selectedNode);
    this.showTaskListModeButtons = equipments.length > 1;
  }

  private applyAutomaticSelection(task: EquipmentTaskOutputInterface, isActivityLevelSelection: boolean = true): void {
    this.selectedTask.emit(task);

    const message: string = isActivityLevelSelection
      ? 'main.activityButtons.automaticTaskSelection'
      : 'main.taskButtons.automaticallySelected';

    this.helperService.showToastMessage(
      true,
      this.translate.instant(message),
      this.translate.instant('general.success'),
    );
  }

  private prepareNodeData(response: ISkippedTreeNodeResponse): ITaskTreeNode[] {
    const tasks: ITaskEquipmentDetail[] = response.currentTreeNodeData.tasks ?? [];
    const folders: ITreeNode[] = response.currentTreeNodeData.children ?? [];
    const nodes: ITaskTreeNode[] = [];
    let equipments: ITaskTreeNode[] = [];

    for (const folder of folders) {
      if (!folder.isActive) {
        continue;
      }

      nodes.push({
        id: folder.id,
        type: ENodeType.Folder,
        title: folder.name,
        icon: 'fas fa-folder',
        order: 0,
        isVirtualGroup: false,
        meta: folder.meta ?? null,
      });
    }

    for (const task of tasks) {
      nodes.push({
        id: task.id,
        type: ENodeType.Task,
        title: task.title,
        subTitle: task?.equipment?.equipmentName,
        subIcon: 'fas fa-cube',
        order: task.order,
        icon: null,
        isVirtualGroup: false,
        taskInfo: {
          equipmentName: task?.equipment?.equipmentName,
          equipmentId: task?.equipment?.id,
          equipmentOrder: task?.equipment?.equipmentOrder,
          taskId: task.id,
          taskName: task.title,
          taskNameTranslations: task.titleTranslations,
          isCommentRequired: task.isCommentRequired,
          phaseId: task.phaseId,
          meta: task.meta,
        },
      });

      if (task.equipment) {
        equipments.push({
          id: task.equipment.id,
          type: ENodeType.Equipment,
          title: task.equipment.equipmentName,
          order: task.equipment.equipmentOrder,
          icon: 'fas fa-cube',
          isVirtualGroup: true,
        });
      }
    }

    equipments = _.uniqBy(equipments, 'id');

    return _.orderBy(nodes.concat(equipments), ['order'], ['asc']);
  }

  private mapTaskToEquipmentTaskOutputInterface(task: TaskCRUDInterface): EquipmentTaskOutputInterface {
    return {
      equipmentOrder: 0,
      isCommentRequired: task.isCommentRequired,
      taskNameTranslations: task.titleTranslations,
      taskId: task.id,
      taskName: task.title,
      equipmentId: task.equipmentId,
      equipmentName: task?.equipment?.equipmentName,
      phaseId: task?.phaseId,
      meta: task.meta,
    };
  }

  private pushFolderTreeNodesToSelectedNodes(skippedNodes: TSkippedTreeNodeMetadata[]): void {
    skippedNodes.forEach((treeNode: TSkippedTreeNodeMetadata) => {
      const isUnique: boolean = !this.selectedNodes.some((node: ITaskTreeNode) => node.id === treeNode.id);
      if (!isUnique) {
        return;
      }

      this.selectedNodes.push({
        id: treeNode.id,
        title: treeNode.name,
        isVirtualGroup: false,
        order: undefined,
        type: ENodeType.Folder,
        icon: 'fa-folder fas',
        meta: treeNode.meta ?? null,
      });
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
