import { Moment } from 'moment';
import { GroupedByTypes } from '../../../store/reports/check-in-log/check-in-log.model';
import { SearchDropdownInterface } from '../../home/cico/cico.model';
import {
  IDefaultSelectItem,
  ScwMatSelectRule
} from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInputRule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ScwMatDatepickerReturnInterface } from '../../../shared/component/scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';

export interface DateRangeFilterInterface {
  startDate: Moment;
  endDate: Moment;
}

export interface CheckInLogsFilterOutputInterface {
  dateRange: DateRangeFilterInterface;
  site: number[];
  line: number[];
  shift: number[];
  groupByOption: GroupedByTypes;
  typeId: number;
  sourceObjectIds: number[];
  status: number[] | number;
}

export interface CheckInLogFormRequestPayloadInterface {
  id?: number;
  sourceTypeId: number;
  sourceObjectId: number;
  destinationTypeId: number;
  destinationObjectId: number;
  checkInTime: string;
  checkOutTime: string;
}

export enum CheckInLogFormTypes {
  Create = 'create',
  Edit = 'edit',
}

export enum CheckInLogReportTypes {
  LaborLogs = 1,
  AssetLogs = 2,
}

interface CheckInLogCardInterface {
  objectCountLabel: string;
  objectCountIcon: string;
  objectHourLabel: string;
  showRegularOvertimeCard: boolean;
}

interface CheckInLogModalInterface {
  createTitle: string;
  updateTitle: string;
  addButton: string;
  searchDropdown: SearchDropdownInterface;
  successMessage: string;
}

interface CheckInLogTableInterface {
  objectColumnName: string;
  objectCountColumnName: string;
  baseTypeOptionName: string;
}

export interface CheckInLogReportInterface {
  typeId: CheckInLogReportTypes;
  card: CheckInLogCardInterface;
  modal: CheckInLogModalInterface;
  table: CheckInLogTableInterface;
}

export interface ShiftDetailsInterface {
  id: string;
  name: string;
  siteIds: string;
  lineIds: string;
  relatedExceptionId: number;
  calendarDate: string;
}

export interface CheckInTableQueryParams {
  page?: number;
  offset?: number;
  pageSize: number;
  orderBy?: string;
  orderDesc?: boolean;
  sourceObjectIds?: string;
  sourceTypeId?: CheckInLogReportTypes;
  destinationTypeId?: string;
  siteIds?: string;
  objectIds?: string;
  shiftIds?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
}

export interface DropdownOptionsInterface {
  id: number;
  name: string;
}

export interface CheckInResponse {
  calculatedEndDate: string;
  calculatedStartDate: string;
  destinationObjectId: number;
  destinationTypeId: number;
  duration: string;
  endDate: string;
  id: number;
  isAvailable: number;
  line: string;
  lineId: number;
  siteId: number;
  siteName: string;
  sourceObjectId: number;
  sourceObjectName: string;
  sourceObjectShortName: string;
  sourceObjectViewName: string;
  role: string;
  customRole: boolean;
  startDate: string;
  sourceTypeId: number;
  station: string;
  details: string;
}

export interface CheckInFormInterface {
  id?: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatSelectRule[];
  };
  sourceTypeId: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatSelectRule[];
  };
  sourceObjectId: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatInputRule[];
  };
  destinationTypeId: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatInputRule[];
  };
  destinationObjectId: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatInputRule[];
  };
  checkInTime: {
    isEnabled: boolean;
    value: ScwMatDatepickerReturnInterface;
    rules?: ScwMatInputRule[];
  };
  checkOutTime: {
    isEnabled: boolean;
    value: ScwMatDatepickerReturnInterface;
    rules?: ScwMatSelectRule[];
  };
  lineId: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatInputRule[];
  };
  lineStationId: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
  siteId: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
}

export enum EKpiCardName {
  USERS = 'users',
  LABOR_HOURS = 'laborHours',
  OVERTIME = 'overtime',
}
