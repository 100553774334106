<div class="modal-body">
  <h3 class="modal-title scw-header m-b-15 d-flex align-items-center">
    {{ oldWorkOrder }} - {{ productNameOfTheWorkOrder }}
    <app-work-order-activity-history
      class="m-l-10"
      [showMissingDataButton]="true"
      [isWorkOrderMissing]="isWorkOrderMissing"
      [workOrderActivityHistoryModalData]="workOrderActivityHistoryData"
      (workOrderActivityHistoryModalClose)="onClickWorkOrderActivityHistoryCloseButton()"
    ></app-work-order-activity-history>
  </h3>
  <form name="fBatchCloseForm">
    <div class="alert alert-success icons-thumbs-up m-b-10" *ngIf="oeeStyles.overall === 'oee-success'">
      <p>
        {{ 'main.batchClose.goodJobText' | translate }}
      </p>
    </div>
    <div *ngIf="!distributeOrderedQuantityFlag" class="alert alert-info icons-alert">
      <p>
        {{
          'main.workOrder.workOrderManuelCountForm.info'
            | translate
              : {
                  goodCount: workOrderCloseSensorData.goodCount | formatDecimalToSiteScale,
                  scrapCount: workOrderCloseSensorData.scrapCount | formatDecimalToSiteScale
                }
        }}
      </p>
    </div>
    <div *ngIf="distributeOrderedQuantityFlag" class="alert alert-info icons-alert">
      <div class="row">
        <div class="col d-flex align-items-center">
          <p>{{ this.distributeMethodTranslation }}</p>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center">
          <scw-mat-button size="xs" type="standard" (onClick)="distributeOrderedQuantity()">
            {{ 'main.workOrder.distributeOrderedQuantity.action' | translate }}
          </scw-mat-button>
        </div>
      </div>
    </div>
    <div class="row fixed-modals-long">
      <div class="col-sm-8">
        <datatable
          noDataText="{{ 'datatable.noData' | translate }}"
          [shouldPaginate]="false"
          [headers]="tableHeaders"
          [items]="workOrderCloseTableData"
          [justDataTable]="true"
          class="custom-table"
        >
          <ng-template let-item let-i="index">
            <td
              *ngFor="let header of tableHeaders"
              [ngClass]="header.value === 'actual' && i === 1 && oeeProgress.quality > 100 ? 'excess-quality' : ''"
            >
              <ng-container *ngIf="header.value === 'variancePercentage'; else else1">
                {{ item[header.value] | toFixed : 2 }}
              </ng-container>
              <ng-template #else1>
                <ng-container *ngIf="header.value !== 'header' && item['formatScale'] === 'site'; else else2">
                  {{ item[header.value] | formatDecimalToSiteScale }}
                </ng-container>
              </ng-template>
              <ng-template #else2>
                <ng-container *ngIf="header.value !== 'header' && item['formatScale'] === 'user'; else else3">
                  {{ item[header.value] | toFixed : userScaleLimit$ }}
                </ng-container>
              </ng-template>
              <ng-template #else3>
                {{ item[header.value] }}
              </ng-template>
              <scw-mat-button
                *ngIf="[0, 1].indexOf(i) !== -1 && header.value === 'actual'"
                size="xs"
                type="standard"
                [className]="['float-right', 'mt-2']"
                [iconButton]="true"
                (onClick)="openEditFinalQuantityModal(final_quantity_edit_modal)"
                data-automation-id="work-order-finalize-actual-quantity-btn"
              >
                <i class="fas fa-pen"></i>
              </scw-mat-button>
            </td>
          </ng-template>
        </datatable>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-12 work-order-metric-progress-card">
            <h3 class="no-space scw-header">{{ 'main.batchClose.oee' | translate }}</h3>
            <div class="progress-box">
              <div class="progress progress-lg d-inline-block">
                <div
                  class="progress-bar progress-lg mw-100"
                  role="progressbar"
                  [style.width.%]="oeeProgress.overall"
                  [ngClass]="oeeStyles.overall"
                ></div>
              </div>
              <p class="d-inline-block mr-1 f-w-400">&nbsp;{{ oeeProgress.overall | toFixed : 2 }}%</p>
            </div>
          </div>
          <div class="col-sm-12 work-order-metric-progress-card" [hidden]="oeeProgress.availability === -1">
            <h5 class="no-space scw-header">{{ 'main.batchClose.availability' | translate }}</h5>
            <div class="progress-box">
              <div class="progress progress-lg d-inline-block">
                <div
                  class="progress-bar mw-100"
                  role="progressbar"
                  [style.width.%]="oeeProgress.availability"
                  [ngClass]="oeeStyles.a"
                ></div>
              </div>
              <p class="d-inline-block mr-1 f-w-400">&nbsp;{{ oeeProgress.availability | toFixed : 2 }}%</p>
            </div>
          </div>
          <div class="col-sm-12 work-order-metric-progress-card" [hidden]="oeeProgress.performance === -1">
            <div class="d-flex">
              <h5 class="no-space scw-header">{{ 'main.batchClose.performance' | translate }}</h5>
              <span class="seed-info align-self-center ml-2" [class.d-none]="!(oeeProgress.performance > 100)">
                <em class="fas fa-info-circle text-gray d-flex align-items-center mr-1"></em>
                {{ speedInfo }} {{ formattedProductUnit }}</span
              >
            </div>
            <div class="progress-box">
              <div class="progress progress-lg d-inline-block">
                <div
                  class="progress-bar mw-100"
                  role="progressbar"
                  [style.width.%]="oeeProgress.performance"
                  [ngClass]="oeeProgress.performance > 100 ? 'missing-color' : oeeStyles.p"
                ></div>
              </div>
              <p class="d-inline-block mr-1 f-w-400">&nbsp;{{ oeeProgress.performance | toFixed : 2 }}%</p>
            </div>
          </div>
          <div class="col-sm-12 work-order-metric-progress-card" [hidden]="oeeProgress.quality === -1">
            <h5 class="no-space scw-header">{{ 'main.batchClose.quality' | translate }}</h5>
            <div class="progress-box">
              <div class="progress progress-lg d-inline-block">
                <div
                  class="progress-bar mw-100"
                  role="progressbar"
                  [style.width.%]="oeeProgress.quality"
                  [ngClass]="oeeProgress.quality > 100 ? 'missing-color' : oeeStyles.q"
                ></div>
              </div>
              <p class="d-inline-block mr-1 f-w-400">&nbsp;{{ oeeProgress.quality | toFixed : 2 }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 m-b-10 variance">
        <div class="row">
          <div class="col-sm-2 align-self-center">
            {{ 'main.batchClose.explainVariances' | translate }}
          </div>
          <div class="col-sm-10">
            <scw-mat-textarea
              [rows]="1"
              [block]="true"
              [rules]="[{ maxLength: InputLimit.WORK_ORDER_CLOSE_EXPLAIN_VARIANCES_MAX_LENGTH }]"
              [(inputModel)]="workOrderCloseFeedbackForm.variancesExplanation"
            ></scw-mat-textarea>
          </div>
        </div>
      </div>
      <div class="col-sm-12 m-t-10">
        <div class="row">
          <div class="col-sm-2 d-flex">
            <div class="row">
              <div class="col-sm-12 align-self-center">
                {{ 'main.batchClose.workedQuestion' | translate }}
              </div>
              <div class="col-sm-12 align-self-center">
                {{ 'main.batchClose.improveQuestion' | translate }}
              </div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="row">
              <div class="col-sm-12">
                <scw-mat-textarea
                  [(inputModel)]="workOrderCloseFeedbackForm.goodThings"
                  [rules]="[{ maxLength: InputLimit.WORK_ORDER_CLOSE_EXPLAIN_VARIANCES_MAX_LENGTH }]"
                  [rows]="1"
                  [block]="true"
                ></scw-mat-textarea>
              </div>
              <div class="col-sm-12">
                <scw-mat-textarea
                  [(inputModel)]="workOrderCloseFeedbackForm.needToBeImprove"
                  [rules]="[{ maxLength: InputLimit.WORK_ORDER_CLOSE_EXPLAIN_VARIANCES_MAX_LENGTH }]"
                  [rows]="1"
                  [block]="true"
                ></scw-mat-textarea>
              </div>
            </div>
          </div>
          <div class="col-sm-3 align-self-center">
            <h5>
              {{ 'main.batchClose.crewQuestion' | translate }}
            </h5>
            <div class="emote-faces">
              <scw-mat-rating [(inputModel)]="workOrderCloseFeedbackForm.crewPerformPoint"></scw-mat-rating>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="col-sm-12">
    <scw-mat-button type="standard" [className]="['float-left']" (onClick)="backButtonIsClicked()">
      {{ 'general.back' | translate }}
    </scw-mat-button>
    <scw-mat-button
      [className]="['float-right']"
      (onClick)="submitWorkOrderCloseFeedbackForm()"
      data-automation-id="work-order-finalize-btn"
    >
      {{ 'main.batchClose.modalTitle' | translate }}
    </scw-mat-button>
  </div>
</div>

<ng-template #final_quantity_edit_modal let-modal>
  <scw-mat-form (scwMatFormSubmit)="onSubmit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'main.workOrder.manuelCountHistoryTable.setFinalCount' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body work-order-manual-count-table">
      <div>
        <work-order-manual-count
          [countMode]="ECountMode.hourly"
          (finalCountDirty)="finalCountDirty = $event"
          [modalType]="countModalType.finalDistribution"
          [configuration]="manualCountConfiguration"
          (setDistributionRequestParams)="setApprovedFinalCounts($event)"
        >
        </work-order-manual-count>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-left">
        <scw-mat-button (onClick)="modal.dismiss()" type="standard">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
      </div>
      <div class="buttons-right">
        <scw-mat-button [isSubmitButton]="true">
          {{ 'main.workOrder.manuelCountHistoryTable.confirmFinalCount' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
