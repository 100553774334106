import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ETooltipContentTypes } from './show-tooltip-button.model';
import { ColorTaggedListComponent } from '../tooltip-contents/color-tagged-list/color-tagged-list.component';
import { NgbButtonsModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ScwMatSmallButtonModule } from '../../../shared/component/scw-mat-ui/scw-mat-small-button/scw-mat-small-button.module';
import { SimpleListComponent } from '../tooltip-contents/simple-list/simple-list.component';

@Component({
  selector: 'show-tooltip-button',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ColorTaggedListComponent,
    NgbPopoverModule,
    NgbButtonsModule,
    ScwMatSmallButtonModule,
    SimpleListComponent,
  ],
  templateUrl: './show-tooltip-button.component.html',
  styleUrls: ['./show-tooltip-button.component.scss'],
})
export class ShowTooltipButtonComponent implements OnInit {
  @Input() buttonLabel: string;
  @Input() block: boolean = false;
  @Input() list: { name: string; color: string }[] = [];
  @Input() tooltipTopLabel: string = '';
  @Input() tooltipContentType: ETooltipContentTypes = ETooltipContentTypes.ColorTaggedList;
  @Input() showLength: boolean = true;
  @Input() container: string | null = null;
  @ViewChild('colorTaggedList', { static: true }) colorTaggedListTemplateRef: TemplateRef<unknown>;
  @ViewChild('simpleList', { static: true }) simpleListTemplateRef: TemplateRef<unknown>;

  public selectedTooltipTemplate: TemplateRef<unknown>;

  ngOnInit(): void {
    this.selectedTooltipTemplate = this.getSelectedTooltipTemplate();
  }

  private getSelectedTooltipTemplate(): TemplateRef<unknown> {
    switch (this.tooltipContentType) {
      case ETooltipContentTypes.ColorTaggedList:
        return this.colorTaggedListTemplateRef;
      case ETooltipContentTypes.SimpleList:
        return this.simpleListTemplateRef;
    }
  }
}
