import { Injectable } from '@angular/core';
import { Actions, createEffect, CreateEffectMetadata, ofType } from '@ngrx/effects';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { LineService } from '../../../shared/service/filter/line.service';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import * as ObjectActions from './line-view-enhanced.actions';
import * as UserConfigurationActions from '../../user-configuration/user-configuration.actions';
import * as AppActions from '../../app/actions';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LineCRUDResponseInterface } from '../../../shared/service/filter/service.class';
import { OeeAppState } from '../../oee.reducer';
import { LineViewEnhancedService } from './line-view-enhanced.service';
import {
  ELineViewEnhancedResponseMode,
  IFilter,
  ILinesReport,
  ILineViewEnhanced,
  IFilterData,
} from './line-view-enhanced.model';

@Injectable()
export class LineViewEnhancedEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly toast: ToastrService,
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly service: LineViewEnhancedService,
    private readonly lineService: LineService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  private selectedSiteFilters: number[];
  private selectedLineFilters: number[];
  private selectedActivityFilters: number[];
  private isLineListVisible: boolean;
  private tableColumns: any[];
  private selectedWidgets: any[];
  private LINE_VIEW = {
    GET: {
      FILTER_URL: '/api/live-dashboard-filters-and-settings',
      DATA_URL: '/api/live-dashboard',
    },
  };

  fetchLineViewEnhancedFilterData: Observable<
    | ObjectActions.LineViewEnhancedFilterDataLoaded
    | ObjectActions.LineViewEnhancedDataLoading
    | ObjectActions.FetchDataError
    | AppActions.HideLoader
  > &
    CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_VIEW_ENHANCED_FILTER_DATA_LOADING),
      switchMap(() => {
        this.store
          .select('lineViewEnhancedStore')
          .pipe(take(1))
          .subscribe((state) => {
            this.isLineListVisible = state.isLineListVisible;
          });

        return this.service.getFiltersAndSettings().pipe(
          switchMap((resData: { data: IFilterData }) => {
            return of(
              new ObjectActions.LineViewEnhancedFilterDataLoaded(resData),
              new ObjectActions.LineViewEnhancedDataLoading(
                this.isLineListVisible ? ELineViewEnhancedResponseMode.ALL : ELineViewEnhancedResponseMode.CARD,
              ),
            );
          }),
          catchError((errorResponse) => {
            return of(new ObjectActions.FetchDataError(errorResponse), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new ObjectActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  setFilterDataLoaded: Observable<
    | ObjectActions.FetchDataError
    | AppActions.HideLoader
    | ObjectActions.SetSelectedSiteFilters
    | ObjectActions.SetSelectedLineFilters
    | ObjectActions.SetSelectedActivityFilters
  > &
    CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_VIEW_ENHANCED_FILTER_DATA_GENERATED),
      switchMap((objectData: ObjectActions.SetFilterDataGenerated) => {
        return of(
          new ObjectActions.SetSelectedSiteFilters(objectData.payload.siteData),
          new ObjectActions.SetSelectedLineFilters(objectData.payload.lineData),
          new ObjectActions.SetSelectedActivityFilters(objectData.payload.activityData),
        );
      }),
      catchError((errorResponse) => {
        return of(new ObjectActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  getLineViewEnhancedDataStarted: Observable<
    ObjectActions.FetchDataError | AppActions.HideLoader | ObjectActions.LineViewEnhancedDataLoaded
  > &
    CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_VIEW_ENHANCED_DATA_LOADING),
      switchMap((objectData: ObjectActions.LineViewEnhancedDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const filters: IFilter = this.getSelectedFilterTypeIds();

        if (filters.selectedLines?.length === 0 || filters.selectedSites?.length === 0) {
          const errorMessage = this.translate.instant('lineView.emptyFilterErrorMessage');

          return of(new ObjectActions.FetchDataError({ errorMessage }), new AppActions.HideLoader());
        }

        const search: {[key: string]: object} = {};

        if (filters.selectedLines?.length > 0) {
          search['id'] = { $in: filters.selectedLines };
        }

        if (filters.selectedSites?.length > 0) {
          search['siteId'] = { $in: filters.selectedSites };
        }

        if (filters.selectedActivities?.length > 0) {
          search['selectedActivity'] = { $in: filters.selectedActivities };
        }

        const queryParams: HttpParams = new HttpParams()
          .set(
            'reportType',
            encodeURI(
              objectData.mode === ELineViewEnhancedResponseMode.ALL
                ? ELineViewEnhancedResponseMode.ALL
                : ELineViewEnhancedResponseMode.SUMMARY,
            ),
          )
          .set('s', JSON.stringify(search))
          .set('columns', encodeURI(filters.selectedColumns.map((x: any) => x.name).join(',')))
          .set('widgetConfiguration', encodeURI(filters.selectedWidgets.map((x: any) => x.name).join(',')));

        return this.service.getLineViewEnhancedData(queryParams).pipe(
          switchMap((resData: ILinesReport) => {
            if (resData.hasOwnProperty('data') && resData['data'].hasOwnProperty('lines')) {
              resData['data']['lines'].forEach((line) => this.entityTranslatorService.translate(line));
            }

            return of(
              new ObjectActions.LineViewEnhancedDataLoaded(resData['data'], objectData.mode),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchDataError(errorRes), new AppActions.HideLoader());
          }),
          finalize(() => {
            this.store.dispatch(new AppActions.HideLoader());
            this.store.dispatch(new ObjectActions.CancelLoading());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new ObjectActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  startFiltersAndSettingsSaving: Observable<
    ObjectActions.FetchDataError | AppActions.HideLoader | UserConfigurationActions.UpdateUserConfigurationLoading
  > &
    CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_VIEW_ENHANCED_START_FILTERS_AND_SETTINGS_SAVING),
      switchMap(() => {
        const filters = this.getSelectedFilterTypeIds();
        return of(
          new UserConfigurationActions.UpdateUserConfigurationLoading({
            LineViewEnhancedComponent: [
              {
                name: 'filters',
                children: [
                  {
                    name: 'siteIds',
                    value: filters.selectedSites,
                  },
                  {
                    name: 'lineIds',
                    value: filters.selectedLines,
                  },
                ],
              },
              {
                name: 'tableColumns',
                value: filters.selectedColumns.map((column) => column.name),
              },
              {
                name: 'widgetSettings',
                value: filters.selectedWidgets.map((column) => column.name),
              },
            ],
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new ObjectActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  getLineCountOfSites: Observable<
    ObjectActions.FetchDataError | AppActions.HideLoader | ObjectActions.LineCountOfSitesLoaded
  > &
    CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_COUNT_OF_SITES_LOADING),
      switchMap((objectData: ObjectActions.LineCountOfSitesLoading) => {
        const httpParams: HttpParams = new HttpParams()
          .set('limit', '1')
          .set('s', JSON.stringify({ siteId: { $in: objectData.selectedSites } }));

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: LineCRUDResponseInterface) => {
            return of(new ObjectActions.LineCountOfSitesLoaded(response.total));
          }),
          catchError((errorResponse) => {
            return of(new ObjectActions.FetchDataError(errorResponse), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new ObjectActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  private getSelectedFilterTypeIds(): IFilter {
    const returnObj: IFilter = {
      selectedLines: [],
      selectedSites: [],
      selectedActivities: [],
      selectedColumns: [],
      selectedWidgets: [],
    };

    this.store
      .select('lineViewEnhancedStore')
      .pipe(take(1))
      .subscribe((state: ILineViewEnhanced) => {
        this.selectedSiteFilters = state.sitesFilter;
        this.selectedLineFilters = state.linesFilter;
        this.selectedActivityFilters = state.activitiesFilter;
        this.tableColumns = state.tableColumns;
        this.selectedWidgets = state.widgets;
      });

    returnObj.selectedSites = this.selectedSiteFilters;
    returnObj.selectedLines = this.selectedLineFilters;
    returnObj.selectedActivities = this.selectedActivityFilters;

    this.tableColumns.forEach((column) => {
      if (column.selected || column.name === 'lineName') {
        returnObj.selectedColumns.push(column);
      }
    });

    if (this.selectedWidgets !== undefined) {
      this.selectedWidgets.forEach((widget) => {
        if (widget.selected) {
          returnObj.selectedWidgets.push(widget);
        }
      });
    }

    return returnObj;
  }
}
