import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IChangeoverPayload, IChangeoverKpiDataResponse } from './changeover.model';
import { Observable } from 'rxjs';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import * as moment from 'moment';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class ChangeoverService {
  constructor(@Inject('API_BASE_URL') private readonly baseUrl: string, private readonly http: HttpClient) {}

  private readonly routes = {
    changeoverKpiInformation: `${this.baseUrl}/work-orders/changeover-kpi-information`,
    changeoverChartData: `${this.baseUrl}/work-orders/changeover-chart-data`,
  };

  public getChangeoverKpiInformation(
    payload: IChangeoverPayload,
  ): Observable<BaseOneResponseInterface<IChangeoverKpiDataResponse[]>> {
    const params: HttpParams = this.getChangeoverKpiInformationParams(payload);
    return this.http.get<BaseOneResponseInterface<IChangeoverKpiDataResponse[]>>(this.routes.changeoverKpiInformation, {
      params,
    });
  }

  public getChangeoverChartData(
    payload: IChangeoverPayload,
  ): Observable<BaseOneResponseInterface<IChangeoverKpiDataResponse[]>> {
    const params: HttpParams = this.getChangeoverKpiInformationParams(payload);
    return this.http.get<BaseOneResponseInterface<IChangeoverKpiDataResponse[]>>(this.routes.changeoverChartData, {
      params,
    });
  }

  private getChangeoverKpiInformationParams(params: IChangeoverPayload): HttpParams {
    const formattedParams: HttpParams = new HttpParams()
      .set('startDate', params.startDate)
      .set('endDate', params.endDate)
      .set('siteId', params.siteId[0])
      .set('groupBy', params.groupBy)
      .set('lineIds', params.lines !== -1 && Array.isArray(params.lines) ? params.lines.join(',') : '-1')
      .set(
        'rootCauseGroupIds',
        params.rootCauseGroupIds !== -1 && Array.isArray(params.rootCauseGroupIds)
          ? params.rootCauseGroupIds.join(',')
          : -1,
      )
      .set(
        'activityIds',
        params.activityIds !== -1 && Array.isArray(params.activityIds) ? params.activityIds.join(',') : '-1',
      )
      .set(
        'toProductIds',
        params.toProductIds !== -1 && Array.isArray(params.toProductIds) ? params.toProductIds.join(',') : '-1',
      )
      .set(
        'fromProductIds',
        params.fromProductIds !== -1 && Array.isArray(params.fromProductIds) ? params.fromProductIds.join(',') : '-1',
      );

    return formattedParams;
  }
}
