<div class="datatable-buttons">
  <div
    class="row"
    [style.pointer-events]="activityEventsMissingDataLoading$ || activityEventsDataLoading$ ? 'none' : 'all'"
  >
    <div class="col-lg-3">
      <div class="left-grp">
        <scw-mat-small-button
          (onClick)="
            selectOrUnselectAll(
              hasMissingData
                ? missingDataTableData.length && missingDataTableData.length !== selectedHistoryItems.length
                : lineViewTableData.length && lineViewTableData.length !== selectedHistoryItems.length
            )
          "
          [disabled]="hasMissingData ? !missingDataTableData.length : !lineViewTableData.length"
        >
          {{
            (
              hasMissingData
                ? !missingDataTableData.length || selectedHistoryItems.length === missingDataTableData.length
                : !lineViewTableData.length || selectedHistoryItems.length === lineViewTableData.length
            )
              ? ('general.unselectAll' | translate)
              : ('general.selectAll' | translate)
          }}
        </scw-mat-small-button>
        <scw-mat-small-button
          [iconButton]="true"
          [disabled]="
            selectedHistoryItems.length === 0 ||
            configuration.isWorkOrderCanceled ||
            configuration.isWorkOrderFinalized ||
            (selectedCompletedWorkOrders.length > 1 &&
              selectedCompletedWorkOrders.length === selectedHistoryItems.length)
          "
          (onClick)="activityEditModal(selectedHistoryItems, activity_edit_modal, ETableModalTypes.edit)"
          data-automation-id="edit-activity-history-btn"
        >
          <em class="fas fa-pen"></em>
        </scw-mat-small-button>
        <scw-mat-small-button
          [iconButton]="true"
          [disabled]="
            selectedHistoryItems.length === 0 || configuration.isWorkOrderCanceled || configuration.isWorkOrderFinalized
          "
          (onClick)="deleteActivityHistoryModal(delete_modal, selectedHistoryItems.length > 1)"
        >
          <em class="fas fa-trash"></em>
        </scw-mat-small-button>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="right-grp">
        <scw-mat-small-button
          [disabled]="configuration.isWorkOrderCanceled || configuration.isWorkOrderFinalized"
          (onClick)="activityEditModal([], activity_edit_modal, ETableModalTypes.add)"
        >
          <em class="fas fa-plus"></em>
          {{ 'general.addActivityHistory' | translate }}
        </scw-mat-small-button>
      </div>
    </div>
  </div>
</div>
<datatable
  noDataText="{{ 'datatable.noData' | translate }}"
  [serverSide]="true"
  [headers]="hasMissingData ? missingDataTableHeaders : lineViewTableHeaders"
  [items]="hasMissingData ? missingDataTableData : lineViewTableData"
  [itemsCount]="hasMissingData ? missingDataTableDataCount : lineViewTableDataCount"
  [currentPage]="hasMissingData ? missingDataTableCurrentPage : lineViewTableCurrentPage"
  [rowsPerPage]="hasMissingData ? missingDataTableParams.pageSize : lineViewTableParams.pageSize"
  [tableStyle]="calledFrom !== activityHistoryTableCalledFrom.WORK_ORDERS ? tableStyle : {}"
  [stickyLeadColumn]="calledFrom === activityHistoryTableCalledFrom.WORK_ORDERS"
  [stickyLastColumn]="calledFrom === activityHistoryTableCalledFrom.WORK_ORDERS"
  [isLoading]="hasMissingData ? activityEventsMissingDataLoading$ : activityEventsDataLoading$"
  (onDataRequest)="onDataRequestHandler($event, hasMissingData ? 'missingData' : 'lineView')"
  (onClickRow)="onClickRow($event)"
  heightMode="fixed"
>
  <ng-template let-item>
    <td class="nowrap">
      <scw-mat-checkbox
        [id]="'line-view-checkbox-' + item.id"
        [value]="item.id"
        (onChange)="onActivityHistoryCheckboxChange($event)"
      >
      </scw-mat-checkbox>
    </td>
    <td
      class="nowrap"
      [ngClass]="item.activity.activityType !== 'idleTime' && item.workOrderId === null ? 'padding-unset' : ''"
    >
      <scw-mat-missing-data
        [text]="item.batchNumber"
        [isIdleActivity]="item.activity.activityType === 'idleTime'"
        [isWithoutWorkOrder]="item.withoutWorkOrder"
        [containsHyperLink]="
          !((!item?.withoutWorkOrder && item?.batchNumber === null) || item?.batchNumber === '') ||
          item?.activity?.activityType === 'idleTime'
        "
        [hyperLink]="['/reports/work-order-performance/' + item?.workOrderId]"
      ></scw-mat-missing-data>
    </td>
    <td class="nowrap" *ngIf="calledFrom === activityHistoryTableCalledFromType.WORK_ORDERS">
      {{ item.line?.title || '' }}
    </td>
    <td
      class="nowrap"
      [class.activityHistoryCellWithBorderColor]="
        !(item.activityId === null || item.activityId === '') &&
        calledFrom === activityHistoryTableCalledFrom.WORK_ORDERS
      "
      [ngClass]="item.activityId === null || item.activityId === '' ? 'padding-unset' : ''"
    >
      <ng-container
        *ngIf="
          !(item.activityId === null || item.activityId === '') &&
            calledFrom === activityHistoryTableCalledFrom.WORK_ORDERS;
          else withoutActivity
        "
      >
        <scw-mat-border-coloring
          [customColor]="
            item.activity.activityType === 'downTimePlanned'
              ? item?.workOrder?.site?.configuration?.colors?.activity?.plannedDownTime?.card
              : undefined
          "
          [type]="item.activityColor"
          [text]="item.activity?.name"
        ></scw-mat-border-coloring>
      </ng-container>
      <ng-template #withoutActivity>
        <scw-mat-missing-data text="{{ item.activity?.name }}"></scw-mat-missing-data>
      </ng-template>
    </td>
    <td
      class="nowrap"
      [ngClass]="
        ((item.taskId === null || item.taskId === '' || item.task.isMissingData === 1) &&
          item.activity.activityType !== 'runTime') ||
        (!item.workOrder?.product?.description && item.activity.activityType === 'runTime')
          ? 'padding-unset'
          : ''
      "
    >
      <scw-mat-missing-data
        text="{{ item.taskName }}"
        [isMissingData]="item.task?.isMissingData"
      ></scw-mat-missing-data>
    </td>
    <td class="nowrap" [ngClass]="item.start === null || item.start === '' ? 'padding-unset' : ''">
      <scw-mat-missing-data text="{{ item.start | momentDate : datetimeFormatWithSecond$ }}"></scw-mat-missing-data>
    </td>
    <td class="nowrap">
      {{ item.duration }}
    </td>
    <td class="nowrap" *ngIf="calledFrom === activityHistoryTableCalledFromType.WORK_ORDERS">
      <app-last-comment
        [activityLog]="item"
        [lastComment]="item?.commentCountWithLastCommentMessage?.lastCommentMessage ?? ''"
        [totalComments]="item?.commentCountWithLastCommentMessage?.totalCommentCount ?? 0"
        (onCommentModalClosedEvent)="onLastCommentModalClosed()"
      ></app-last-comment>
    </td>
    <td class="nowrap" *ngIf="calledFrom === activityHistoryTableCalledFromType.WORK_ORDERS">
      <scw-mat-button
        size="xs"
        type="standard"
        [disabled]="!!selectedHistoryItems?.length"
        (onClick)="
          selectedHistoryItems.push(item.id);
          activityEditModal(selectedHistoryItems, activity_edit_modal, ETableModalTypes.edit)
        "
      >
        {{ 'general.edit' | translate }}
      </scw-mat-button>
    </td>
  </ng-template>
</datatable>
<ng-template #activity_edit_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'activityHistory.listView.timeEntry' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="cancelClicked(true)">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <app-activity-form
    [siteData]="sites"
    [lineData]="lines"
    [defaultData]="selectedActivityData"
    [shownFields]="activityEditShownFields"
    [calledFrom]="calledFrom"
    [enabledFields]="activityEditEnabledFields"
    [closeAllModals]="calledFrom !== activityHistoryTableCalledFromType.WORK_ORDERS"
    (cancel)="cancelClicked($event)"
  ></app-activity-form>
</ng-template>
<ng-template #delete_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      <ng-template [ngIf]="selectedHistoryItems.length > 1" [ngIfElse]="singleDeleteHeader">
        {{ 'modal.header.deleteActivityHistories' | translate }}
      </ng-template>
      <ng-template #singleDeleteHeader>
        {{ 'modal.header.deleteActivityHistory' | translate }}
      </ng-template>
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          <ng-template [ngIf]="selectedHistoryItems.length > 1" [ngIfElse]="singleDelete">
            {{ 'modal.activityHistory.deleteBulkInfo.label' | translate : { count: selectedHistoryItems.length } }}
          </ng-template>
          <ng-template #singleDelete>
            {{ 'modal.activityHistory.deleteInfo.label' | translate }}
          </ng-template>
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="deleteSelectedActivityHistoryData()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #bulk_error_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      <ng-template [ngIf]="errorModalType === ETableModalTypes.bulkDelete">
        {{ 'modal.header.deleteActivityHistories' | translate }}
      </ng-template>
      <ng-template [ngIf]="errorModalType === ETableModalTypes.bulkEdit">
        {{ 'modal.header.editActivityHistory' | translate }}
      </ng-template>
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <div>
        <h6 class="scw-header">
          {{ 'modal.bulk.activityHistoryErrorSentence1' | translate : { count: bulkErrorModalSuccessfulCount } }}
        </h6>
        <h6 class="scw-header mt-22 color-red">
          {{ 'modal.bulk.activityHistoryErrorSentence2' | translate }}
        </h6>
        <div class="mt-22">
          <div
            class="bulk-error-modal-product-id-text"
            *ngFor="let activities of unsuccessfulActivityBulkOperationData"
          >
            {{ activities.activity.name }}-{{ activities.start }}-{{ activities.end }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()">
        {{ 'general.ok' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
