import { Action } from '@ngrx/store';
import {
  LineCRUDInterface,
  SiteCRUDInterface,
  UsersCRUDInterface,
} from '../../../shared/component/filter/filter.class';
import {
  UserInterface,
  UserBulkSaveManyInterface,
  UsersDownloadExcelFiltersInterface,
  IBulkEditUser,
  IUsersFilter,
  IUserFilters,
  LanguageResponseInterface,
  IAnonymousCard,
  IOngoingUserCheckinsAndActivityInLine,
} from './users.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { IAddUser } from '../../../view/settings/users/users.model';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { TCacheOptions } from '../../../shared/service/cache-service';

export const USERS_SITES_DATA_LOADING = '[USERS] USERS ACTION SITES DATA LOADING';
export const USERS_SITES_DATA_LOADED = '[USERS] USERS ACTION SITES DATA LOADED';
export const USERS_LINES_DATA_LOADING = '[USERS] USERS ACTION LINES DATA LOADING';
export const USERS_LINES_DATA_LOADED = '[USERS] USERS ACTION LINES DATA LOADED';
export const USERS_DATA_LOADING = '[USERS] USERS ACTION DATA LOADING';
export const USERS_DATA_LOADED = '[USERS] USERS ACTION DATA LOADED';
export const USERS_DATA_FOR_DATATABLE_LOADING = '[USERS] USERS FOR DATATABLE ACTION DATA LOADING';
export const USERS_DATA_FOR_DATATABLE_LOADED = '[USERS] USERS FOR DATATABLE ACTION DATA LOADED';
export const SET_TABLE_SETTINGS = '[USERS] USERS TABLE SETTINGS SET';
export const DOWNLOAD_USERS_EXCEL = '[USERS] DOWNLOAD USERS EXCEL';
export const DOWNLOAD_USERS_EXCEL_COMPLETED = '[USERS] DOWNLOAD USERS EXCEL COMPLETED';
export const UPLOAD_USER_EXCEL = '[USERS] UPLOAD USERS EXCEL';
export const UPLOAD_USER_EXCEL_COMPLETED = '[USERS] UPLOAD USERS EXCEL COMPLETED';
export const ADD_USER = '[USERS] ADD USER';
export const ADD_USER_COMPLETED = '[USERS] ADD USER COMPLETED';
export const EDIT_USER = '[USERS] EDIT USER';
export const EDIT_USER_COMPLETED = '[USERS] EDIT USER COMPLETED';
export const EDIT_CURRENT_USER = '[USERS] EDIT CURRENT USER';
export const EDIT_CURRENT_USER_COMPLETED = '[USERS] EDIT CURRENT USER COMPLETED';
export const BULK_EDIT_USER = '[USERS] BULK EDIT USER';
export const BULK_EDIT_USER_COMPLETED = '[USERS] BULK EDIT USER COMPLETED';
export const SET_SELECTED_FILTERS = '[USERS] SET SELECTED FILTERS';
export const GET_USER = '[USERS] GET USER';
export const GET_USER_COMPLETED = '[USERS] GET USER COMPLETED';
export const GET_ANONYMOUS_CARDS = '[USERS] GET ANONYMOUS CARDS';
export const GET_ANONYMOUS_CARDS_COMPLETED = '[USERS] GET ANONYMOUS CARDS COMPLETED';
export const DELETE_USERS = '[USERS] DELETE USERS';
export const DELETE_USERS_COMPLETED = '[USERS] DELETE USERS COMPLETED';
export const GET_LANGUAGES = '[USERS] GET LANGUAGES';
export const GET_LANGUAGES_COMPLETED = '[USERS] GET LANGUAGES COMPLETED';
export const USERS_FETCH_ERROR = '[USERS] FETCH USERS ERROR';
export const SEND_VERIFICATION_EMAIL = '[USERS] SEND VERIFICATION EMAIL';
export const SEND_VERIFICATION_EMAIL_COMPLETED = '[USERS] SEND VERIFICATION EMAIL COMPLETED';
export const UNVERIFY_USER = '[USERS] UNVERIFY USER';
export const UNVERIFY_USER_COMPLETED = '[USERS] UNVERIFY USER COMPLETED';
export const GET_AVATAR_LOADING = '[USERS] GET AVATAR LOADING';
export const GET_AVATAR_LOADED = '[USERS] GET AVATARS LOADED';
export const GET_AVATAR_FOR_LOGS_LOADING = '[USER] GET AVATAR FOR LOGS LOADING';
export const GET_AVATAR_FOR_LOGS_LOADED = '[USER] GET AVATAR FOR LOGS LOADED';
export const UPLOAD_AVATAR_LOADING = '[USERS] UPLOAD AVATAR LOADING';
export const UPLOAD_AVATAR_LOADED = '[USERS] UPLOAD AVATAR LOADED';
export const DELETE_AVATAR_LOADING = '[USERS] DELETE AVATAR  LOADING';
export const DELETE_AVATAR_LOADED = '[USERS] DELETE AVATAR  LOADED';
export const GET_ONGOING_USER_CHECKINS_AND_ACTIVITY_IN_LINE_LOADING =
  '[USERS] GET ONGOING USER CHECKINS AND ACTIVITY IN LINE LOADING';
export const GET_ONGOING_USER_CHECKINS_AND_ACTIVITY_IN_LINE_LOADED =
  '[USERS] GET ONGOING USER CHECKINS AND ACTIVITY IN LINE LOADED';

export class UsersDataLoading implements Action {
  readonly type = USERS_DATA_LOADING;

  constructor(public siteId: number, public limit?: number) {}
}

export class UsersDataLoaded implements Action {
  readonly type = USERS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<UserInterface>) {}
}

export class UsersDataForDatatableLoading implements Action {
  readonly type = USERS_DATA_FOR_DATATABLE_LOADING;

  constructor(public tableQuery: IUserFilters) {}
}

export class UsersDataForDatatableLoaded implements Action {
  readonly type = USERS_DATA_FOR_DATATABLE_LOADED;
  constructor(public payload: GetManyResponseInterface<UserInterface>) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class UsersSiteDataLoading implements Action {
  readonly type = USERS_SITES_DATA_LOADING;
}

export class UsersSiteDataLoaded implements Action {
  readonly type = USERS_SITES_DATA_LOADED;

  constructor(public payload: ({ id: number; name: string } & Partial<SiteCRUDInterface>)[]) {}
}

export class UsersLineDataLoading implements Action {
  readonly type = USERS_LINES_DATA_LOADING;

  constructor(public siteId?: number[] | number) {}
}

export class UsersLineDataLoaded implements Action {
  readonly type = USERS_LINES_DATA_LOADED;

  constructor(public payload: Partial<LineCRUDInterface>[]) {}
}

export class DownloadUsersExcel implements Action {
  readonly type = DOWNLOAD_USERS_EXCEL;

  constructor(public withData: boolean = false, public filters: UsersDownloadExcelFiltersInterface) {}
}

export class DownloadUsersExcelCompleted implements Action {
  readonly type = DOWNLOAD_USERS_EXCEL_COMPLETED;
}

export class UploadUsersExcel implements Action {
  readonly type = UPLOAD_USER_EXCEL;

  constructor(public users: UserBulkSaveManyInterface) {}
}

export class UploadUsersExcelCompleted implements Action {
  readonly type = UPLOAD_USER_EXCEL_COMPLETED;

  constructor(public payload: (UserInterface & { errorMessages?: string })[]) {}
}

export class AddUser implements Action {
  readonly type = ADD_USER;
  constructor(public user: IAddUser, public hideLoaderAfterSuccess: boolean = true) {}
}

export class AddUserCompleted implements Action {
  readonly type = ADD_USER_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<UsersCRUDInterface>) {}
}

export class EditUser implements Action {
  readonly type = EDIT_USER;
  constructor(
    public user: IAddUser,
    public userId: number,
    public isSelf: boolean,
    public hideLoaderAfterSuccess: boolean = true,
  ) {}
}

export class EditUserCompleted implements Action {
  readonly type = EDIT_USER_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<UsersCRUDInterface>) {}
}

export class BulkEditUser implements Action {
  readonly type = BULK_EDIT_USER;
  constructor(public users: IBulkEditUser[]) {}
}

export class BulkEditUserCompleted implements Action {
  readonly type = BULK_EDIT_USER_COMPLETED;
  constructor(public payload: BulkResponseDataInterface) {}
}

export class DeleteUsers implements Action {
  readonly type = DELETE_USERS;
  constructor(public payload: number[]) {}
}

export class DeleteUsersCompleted implements Action {
  readonly type = DELETE_USERS_COMPLETED;
  constructor(public isBulk: boolean, public response: BulkResponseDataInterface) {}
}

export class GetUser implements Action {
  readonly type = GET_USER;
  constructor(public userId: number) {}
}

export class GetUserCompleted implements Action {
  readonly type = GET_USER_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<UserInterface>) {}
}

export class GetAnonymousCards implements Action {
  readonly type = GET_ANONYMOUS_CARDS;
  constructor(public sites: number[], public search?: string) {}
}

export class GetAnonymousCardsCompleted implements Action {
  readonly type = GET_ANONYMOUS_CARDS_COMPLETED;
  constructor(public payload: GetManyResponseInterface<IAnonymousCard>) {}
}

export class GetLanguages implements Action {
  readonly type = GET_LANGUAGES;
}

export class GetLanguagesCompleted implements Action {
  readonly type = GET_LANGUAGES_COMPLETED;
  constructor(public payload: LanguageResponseInterface) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;
  constructor(public filters: IUsersFilter) {}
}

export class FetchError implements Action {
  readonly type = USERS_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class SendVerificationEmail implements Action {
  readonly type = SEND_VERIFICATION_EMAIL;

  constructor(public id: number) {}
}

export class SendVerificationEmailCompleted implements Action {
  readonly type = SEND_VERIFICATION_EMAIL_COMPLETED;

  constructor(public payload: BaseCrudResponse) {}
}

export class UnverifyUser implements Action {
  readonly type = UNVERIFY_USER;

  constructor(public id: number) {}
}

export class UnverifyUserCompleted implements Action {
  readonly type = UNVERIFY_USER_COMPLETED;

  constructor(public payload: BaseCrudResponse) {}
}

export class GetAvatarLoading implements Action {
  readonly type = GET_AVATAR_LOADING;

  constructor(public avatarPath: string, public options?: TCacheOptions) {}
}

export class GetAvatarLoaded implements Action {
  readonly type = GET_AVATAR_LOADED;

  constructor(public response: BaseOneResponseInterface<string>, public path: string) {}
}

export class GetAvatarForLogsLoading implements Action {
  readonly type = GET_AVATAR_FOR_LOGS_LOADING;

  constructor(public avatarPath: string, public options?: TCacheOptions) {}
}

export class GetAvatarForLogsLoaded implements Action {
  readonly type = GET_AVATAR_FOR_LOGS_LOADED;

  constructor(public response: BaseOneResponseInterface<string>, public path: string) {}
}

export class UploadAvatarLoading implements Action {
  readonly type = UPLOAD_AVATAR_LOADING;

  constructor(public id: number, public base64ImageContent: string) {}
}

export class UploadAvatarLoaded implements Action {
  readonly type = UPLOAD_AVATAR_LOADED;
}

export class DeleteAvatarLoading implements Action {
  readonly type = DELETE_AVATAR_LOADING;

  constructor(public id: number) {}
}

export class DeleteAvatarLoaded implements Action {
  readonly type = DELETE_AVATAR_LOADED;
}

export class getOngoingUserCheckinsAndActivityInLineLoading implements Action {
  readonly type = GET_ONGOING_USER_CHECKINS_AND_ACTIVITY_IN_LINE_LOADING;
  constructor(public userIds: number[]) {}
}

export class getOngoingUserCheckinsAndActivityInLineLoaded implements Action {
  readonly type = GET_ONGOING_USER_CHECKINS_AND_ACTIVITY_IN_LINE_LOADED;
  constructor(public payload: GetManyResponseInterface<IOngoingUserCheckinsAndActivityInLine>) {}
}

export type UserSettingsActions =
  | UsersDataLoading
  | UsersDataLoaded
  | UsersDataForDatatableLoading
  | UsersDataForDatatableLoaded
  | SetTableSettings
  | UsersSiteDataLoading
  | UsersSiteDataLoaded
  | UsersLineDataLoading
  | UsersLineDataLoaded
  | DownloadUsersExcel
  | DownloadUsersExcelCompleted
  | UploadUsersExcel
  | UploadUsersExcelCompleted
  | AddUser
  | AddUserCompleted
  | EditUser
  | EditUserCompleted
  | BulkEditUser
  | BulkEditUserCompleted
  | GetUser
  | GetUserCompleted
  | DeleteUsers
  | DeleteUsersCompleted
  | GetLanguages
  | GetLanguagesCompleted
  | GetAnonymousCards
  | GetAnonymousCardsCompleted
  | SetSelectedFilters
  | FetchError
  | SendVerificationEmail
  | SendVerificationEmailCompleted
  | UnverifyUser
  | UnverifyUserCompleted
  | GetAvatarLoading
  | GetAvatarLoaded
  | GetAvatarForLogsLoading
  | GetAvatarForLogsLoaded
  | UploadAvatarLoading
  | UploadAvatarLoaded
  | DeleteAvatarLoading
  | DeleteAvatarLoaded
  | getOngoingUserCheckinsAndActivityInLineLoading
  | getOngoingUserCheckinsAndActivityInLineLoaded;
