import { AllSettledResult } from '../../shared/model/interface/generic-api-response.model';
import { ResponseInterface } from '../../shared/service/filter/service.class';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import { LinesInterface, WorkOrderInterface } from '../work-order-schedule/work-order-schedule.model';
import {
  IActivityOfActivityHistory,
  ITaskOfActivityHistory,
  IUserOfActivityHistory,
  IWorkOrderOfActivityHistory,
} from '../../shared/service/activity-history/activity-history.model';
import { ELanguages } from '../../../constants';
import { IAdvancedFilterOutput } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { IObjectWithEntityTranslations } from '../../shared/service/entity-translator/entity-translator.model';
import { IInMemoryComment } from 'src/app/standalone/comment-feed/comment-feed-modal/comment-feed-modal.model';
import { BaseCrudResponse } from '../../shared/model/interface/crud-response-interface.model';
import { ICommentCountWithLastCommentMessage } from '../reports/comment-logs/comment-logs.model';
import { BorderColors } from '../../shared/component/scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { IPredictedWorkOrderForSplit } from '../../shared/component/select-work-order/select-work-order.model';

export interface WorkorderNumbersDataInterface extends WorkOrderOperationRules, IObjectWithEntityTranslations {
  actualRunDate?: string;
  completed?: string;
  durationFormatted: string;
  finalQuantity?: string;
  finalScrap?: string;
  scrapCount?: string;
  oeeScrapCount?: string;
  scrapQuantity?: string;
  id: number;
  productDescription: string;
  actualLine?: number;
  lineName?: string;
  oeeA?: string;
  oeeEndDate?: string;
  oeeGoodCount?: string;
  goodCount?: string;
  initialCount?: string;
  loadingGoodCount?: boolean;
  oeeInitialCount?: string;
  oeeOverallOee?: string;
  oeeP?: string;
  oeeQ?: string;
  oeeStartDate?: string;
  oeeTotalDownDuration?: number;
  oeeTotalRunDuration?: number;
  packageSize?: string | null;
  productId: number;
  productTableId: string;
  quantityOrdered: string;
  scheduledEndDate?: string;
  scheduledPostDuration?: string;
  scheduledPreDuration?: string;
  scheduledRunDate: string;
  scheduledRunDuration?: string;
  siteId: number;
  siteName: string;
  targetDuration?: number;
  woDueDate: string | null;
  woId?: string;
  woNumber: string;
  workOrderDuration: string;
  workOrderStartDate: string;
  jobNumber: number;
  oeeGoodCountEffectiveDuration: string;
  oeeInitialCountEffectiveDuration: string;
  oeePotentialDownQuantitySpeed: string;
  oeePotentialIdleQuantitySpeed: string;
  oeePotentialRunQuantitySpeed: string;
  oeeStartShiftDay: string;
  oeeTotalDownTimeCount: number;
  oeeTotalExpectedGoodCount: number;
  oeeTotalIdleTimeCount: number;
  oeeTotalRunTimeCount: number;
  productionStage: number;
  releaseDate: string;
  sequenceNumber: number;
  product: { id: number; productId: string; description: string };
  unitName: string;
  isEditButtonVisible?: boolean;
  productName?: string;
  description?: string;
  disabled?: boolean;
  isCalculating?: boolean;
  canceled?: number;
}

export interface PaginationInterface {
  currentPage: string;
  orderBy: string;
  orderDesc: boolean;
  pageSize: string;
  totalPages: number;
  totalResults: string;
}

export interface EventDataInterface {
  start: string;
  end: string;
  siteId: number;
  lineId: number;
  activityId: number | '';
  crewSize?: string | number;
  phaseId: number | null;
  workOrderId: number | null;
}

export interface SaveUpdateOrDeleteActivityEventDataInterface extends EventDataInterface {
  taskId: number;
  id?: number;
  comments?: readonly IInMemoryComment[];
  workOrderToSplitInfo?: IPredictedWorkOrderForSplit | null;
}

export interface ActivityEventsDataInterface extends EventDataInterface {
  activityId: number | '';
  activityType: string | null;
  workOrderId: number | null;
  workOrderNumber: string | null;
  createdAt: Date;
  changedAt: Date;
  changedBy: string | null;
  crewSize: number;
  description: string | null;
  id: number;
  lineId: number;
  location: string;
  phaseId: number;
  shiftDay: Date;
  shiftId: number;
  shiftPlanId: number;
  siteId: number;
  taskId: number | '';
  userId: number;
  activity: ActivitiesInterface;
  line: LinesInterface;
  workOrder: WorkOrderInterface;
  task: any;
}

export interface WorkOrderResponseDataInterface extends PaginationInterfaceNestJS {
  data: WorkorderNumbersDataInterface[];
}

export interface ActivityEventsResponseDataInterface extends PaginationInterfaceNestJS {
  data: ActivityEventsDataInterface[];
}

export interface OnDataRequestHandlerParamsInterface {
  page: number;
  pageSize: number;
  action?: string;
  search?: string;
  orderBy?: string;
  orderDesc?: string | number;
}

export interface SelectedActivityEventDataInterface extends IObjectWithEntityTranslations {
  id?: number;
  siteId?: number;
  lineId?: number;
  activityId?: number;
  taskId?: number;
  phaseId?: number;
  userId?: number;
  crewSize?: string;
  start?: string;
  end?: string;
  description?: string;
  batchNumber?: string;
  shiftDay?: string;
  shiftId?: number;
  shiftPlanId?: number;
  location?: string;
  user?: IUserOfActivityHistory | null;
  createdByUser?: IUserOfActivityHistory | null;
  task?: ITaskOfActivityHistory | null;
  workOrder?: IWorkOrderOfActivityHistory | null;
  activity?: IActivityOfActivityHistory | null;
  workOrderId?: null | number;
  comments?: readonly IInMemoryComment[];
  predictedWorkOrderForSplit?: IPredictedWorkOrderForSplit | null;
}

export interface EventListInterface {
  activityGroupType: string;
  activityIndividualType: string;
  activityId: number;
  duration: number;
  end: number;
  start: number;
  initialCount: number | string;
  isRuntimeActivity: boolean;
  lineName: string;
  nameTranslations: string;
  productDescription: string;
  taskDescription: string;
  taskName: string;
  titleTranslations: string;
  goodCount: number | string;
  woNumber: string;
  workOrderId: number;
  language: ELanguages;
}

export interface WorkOrdersUpdateGoodAndScrapInterface {
  id: string | number;
}

export interface GetWorkOrderGoodCountLoadedDataInterface {
  from?: string;
  goodCount: string;
  initialCount: string;
}

export interface GetWorkOrderGoodCountLoadedInterface {
  data: GetWorkOrderGoodCountLoadedDataInterface;
  success: boolean;
  woNumber: string;
}

export interface ActivityFormInterface {
  data: ActivityFormDataInterface;
  success: boolean;
}

export interface ActivityFormActivityInterface {
  active: string;
  activityPlannedType?: string;
  activitySubType?: string;
  activityType: string;
  changedAt?: string;
  changedBy?: string;
  client: string;
  createdAt?: string;
  createdBy?: string;
  id: string;
  name: string;
}

export interface ActivityFormLineInterface {
  activeUser: string;
  activityIds: string;
  changedAt?: string;
  changedBy?: string;
  createdAt: string;
  createdBy?: string;
  description: string;
  id: number;
  laborHourFriday: string;
  laborHourMonday: string;
  laborHourSaturday: string;
  laborHourSunday: string;
  laborHourThursday: string;
  laborHourTuesday: string;
  laborHourWednesday: string;
  lineType: string;
  lineTypeId: string;
  siteId: number;
  siteName: string;
  standardSpeed: string;
  statusDescription: string;
  statusId: string;
  timer: string;
  title: string;
}

export interface ActivityFormSiteInterface {
  address: string;
  contactPerson: string;
  description: string;
  email?: string;
  id: number;
  location: string;
  name: string;
  statusDescription: string;
  statusId: string;
  tel: string;
  url?: string;
  web: string;
}

export interface ActivityFormUserInterface {
  fullName: string;
  id: string;
  username: string;
}

export interface ActivityFormDataInterface {
  activities: ActivityFormActivityInterface[];
  lines: ActivityFormLineInterface[];
  sites: ActivityFormSiteInterface[];
  users: ActivityFormUserInterface[];
}

export interface ActivityAddOrEditResponseInterface extends ResponseInterface {
  data: AllSettledResult[];
}

export interface PaginationInterfaceNestJS {
  page: number;
  orderBy: string;
  orderDesc: boolean;
  count: number;
  pageCount: number;
  total: number;
}

export interface WorkOrderOperationRules {
  editableFields: string;
  hasActivityHistory: boolean;
  hasManualCount: boolean;
  hasOnGoingActivity: boolean;
  isDeletable: boolean;
}

export interface ActivityHistoryRequestOptions {
  page: number;
  pageSize: number;
  orderBy: string;
  orderDesc: boolean;
  search: string;
}

export interface ICurrentSiteLineInformation {
  lineId: number;
  siteId: number;
}

export interface FixedAndCurrentVersionInterface extends SelectedActivityEventDataInterface {
  canBeOverwritten?: boolean;
  isSentRecord?: boolean;
  isOngoing?: boolean;
  predictedWorkOrderForSplit?: IPredictedWorkOrderForSplit | null;
}

export interface OverlappedActivitiesInterface {
  activityHistoriesToCreate: SelectedActivityEventDataInterface[];
  activityHistoriesToDelete: number[];
  activityHistoriesToUpdate: SelectedActivityEventDataInterface[];
  currentVersion: FixedAndCurrentVersionInterface[];
  fixedVersion: FixedAndCurrentVersionInterface[];
  hasAnyChange: boolean;
  isValid: boolean;
  newTimer?: string;
}

export interface OverlappedActivitiesDataInterface extends BaseCrudResponse {
  data: OverlappedActivitiesInterface;
}

export interface OverlappingDataInterface {
  id?: number;
  siteId: number;
  lineId: number;
  start: string;
  end: string;
  activityId: number;
  taskId?: number;
  workOrderId?: number;
  userId?: number;
  phaseId?: number;
  description?: string;
  crewSize?: string;
  comments?: readonly IInMemoryComment[];
  predictedWorkOrderForSplit?: IPredictedWorkOrderForSplit | null;
}

export interface OngoingActivityValidationDataInterface {
  timer: string;
  selectedActivity: number;
}

export interface ILineViewParams extends OnDataRequestHandlerParamsInterface {
  advancedFilter?: IAdvancedFilterOutput;
  advancedFilterPage?: string;
}

export interface ILineViewAdvancedFilterParams {
  lineViewAdvancedFilter?: IAdvancedFilterOutput;
  lineViewAdvancedFilterPage?: string;
  missingDataAdvancedFilterPage?: string;
  missingDataAdvancedFilter?: IAdvancedFilterOutput;
}

export interface IActivityHistoryFilter {
  isMissingData: boolean;
  advancedFilter: IAdvancedFilterOutput;
  advancedFilterPage: string;
  statusIds?: number[];
}

export interface IWorkOrderFilter extends ILineViewParams {
  isCompleted?: number;
  isCanceled?: number;
}

export interface IActivityHistoryTableData extends ActivityEventsDataInterface, IObjectWithEntityTranslations {
  batchNumber: string;
  isMissingData: boolean;
  duration: string;
  taskName: string;
  withoutWorkOrder: boolean;
  activityName?: string;
  isLine?: number;
  activityColor?: BorderColors;
}

export interface IActivityHistoryWithLastComment extends IActivityHistoryTableData {
  commentCountWithLastCommentMessage?: ICommentCountWithLastCommentMessage;
}

export enum EZoomEvents {
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
}
