import { Action } from '@ngrx/store';
import {
  ICreateManualCount,
  IManualCount,
  ISetHourlyCount,
  ICommentsResponse,
  IWorkOrderManualCountComment,
  IDistributionData,
  IDistributionResponse,
  IDistributionRequest,
} from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { BulkResponse } from '../../shared/model/interface/generic-api-response.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { IPeriodicOeeCalculationData } from '../reports/periodic-oee-calculation-review/periodic-oee-calculation-review.model';
import { TCommentResponseDto } from '../../standalone/comment-feed/comment-feed-modal/comment-feed-modal.model';

export const SET_WORK_ORDER_MANUAL_COUNT_FORM = '[MANUAL_COUNT] Set work order manual count form state';
export const SAVE_MANUAL_COUNT_COMMENTS = '[MANUAL_COUNT] Save manual count comments';
export const MANUAL_COUNT_COMMENTS_SAVED = '[MANUAL_COUNT] Manual count comments saved';
export const SAVE_SCRAP_COUNT_COMMENT = '[MANUAL_COUNT] Save scrap count comments';
export const SCRAP_COUNT_COMMENT_SAVED = '[MANUAL_COUNT] Scrap count comments saved';
export const RESET_MANUAL_COUNT_STORE = '[MANUAL_COUNT] Reset work order manual count store';
export const SET_HOURLY_COUNT = '[MANUAL_COUNT] Set hourly count';
export const SET_HOURLY_COUNT_COMPLETED = '[MANUAL_COUNT] Set hourly count completed';
export const SET_HOURLY_COUNT_FAILED = '[MANUAL_COUNT] Set hourly count failed';
export const SET_BULK_HOURLY_COUNT = '[MANUAL_COUNT] Set bulk hourly count';
export const SET_BULK_HOURLY_COUNT_COMPLETED = '[MANUAL_COUNT] Set bulk hourly count completed';
export const SET_BULK_HOURLY_COUNT_FAILED = '[MANUAL_COUNT] Set bulk hourly count failed';
export const CREATE_MANUAL_COUNT = '[MANUAL_COUNT] Create manual count';
export const CREATE_MANUAL_COUNT_COMPLETED = '[MANUAL_COUNT] Create manual count completed';
export const CREATE_MANUAL_COUNT_FAILED = '[MANUAL_COUNT] Create manual count failed';
export const FETCH_DATA_ERROR = '[MANUAL_COUNT] FETCH DATA ERROR';
export const MANUAL_COUNT_GET_DISTRIBUTION_LOADING = '[MANUAL_COUNT] Manual count get distribution loading';
export const MANUAL_COUNT_GET_DISTRIBUTION_LOADED = '[MANUAL_COUNT] Manual count get distribution loaded';
export const MANUAL_COUNT_SET_DISTRIBUTION_LOADING = '[MANUAL_COUNT] Manual count set distribution loading';
export const MANUAL_COUNT_SET_DISTRIBUTION_LOADED = '[MANUAL_COUNT] Manual count set distribution loaded';
export const GET_OEE_FOR_DISTRIBUTION_VALIDATION_LOADING = '[MANUAL_COUNT] Get oee for distribution validation loading';
export const GET_OEE_FOR_DISTRIBUTION_VALIDATION_LOADED = '[MANUAL_COUNT] Get oee for distribution validation loaded';
export const GET_SCRAP_COUNT_COMMENT_OF_PRODUCTION_COUNT = '[MANUAL_COUNT] get scrap count comment of production count';
export const GET_SCRAP_COUNT_COMMENT_OF_PRODUCTION_COUNT_LOADED =
  '[MANUAL_COUNT] get scrap count comment of production count loaded';
export const DELETE_SCRAP_COUNT_COMMENT = '[MANUAL_COUNT] delete scrap count comment ';
export const DELETE_SCRAP_COUNT_COMMENT_DONE = '[MANUAL_COUNT] delete scrap count comment done';

export class SetManualCountFormSubmitted implements Action {
  readonly type = SET_WORK_ORDER_MANUAL_COUNT_FORM;

  constructor(public isManualCountAddFormSubmitted: boolean) {}
}

export class SaveManualCountComments implements Action {
  readonly type = SAVE_MANUAL_COUNT_COMMENTS;

  constructor(public comments: IWorkOrderManualCountComment[]) {}
}

export class ManualCountCommentsSaved implements Action {
  readonly type = MANUAL_COUNT_COMMENTS_SAVED;

  constructor(public response: GetManyResponseInterface<ICommentsResponse>) {}
}

export class SaveScrapCountComment implements Action {
  readonly type = SAVE_SCRAP_COUNT_COMMENT;

  constructor(public comment: Omit<IWorkOrderManualCountComment, 'commentTypeId'>) {}
}

export class ScrapCountCommentSaved implements Action {
  readonly type = SCRAP_COUNT_COMMENT_SAVED;

  constructor(public response: GetManyResponseInterface<ICommentsResponse>) {}
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public response: object) {}
}

export class ResetManualCountStore implements Action {
  readonly type = RESET_MANUAL_COUNT_STORE;

  constructor() {}
}

export class SetHourlyCount implements Action {
  readonly type = SET_HOURLY_COUNT;

  constructor(public manualCount: ISetHourlyCount, public duringOngoingDistribution: boolean = false) {}
}

export class SetBulkHourlyCount implements Action {
  readonly type = SET_BULK_HOURLY_COUNT;

  constructor(public manualCounts: ISetHourlyCount[], public duringOngoingDistribution: boolean = false) {}
}

export class CreateManualCount implements Action {
  readonly type = CREATE_MANUAL_COUNT;

  constructor(public data: ICreateManualCount, public isCalledFromSetHourlyCount: boolean = false) {}
}

export class CreateManualCountCompleted implements Action {
  readonly type = CREATE_MANUAL_COUNT_COMPLETED;

  constructor(public payload: IManualCount) {}
}

export class SetHourlyCountCompleted implements Action {
  readonly type = SET_HOURLY_COUNT_COMPLETED;

  constructor(public payload: IManualCount, public duringOngoingDistribution: boolean = false) {}
}

export class SetBulkHourlyCountCompleted implements Action {
  readonly type = SET_BULK_HOURLY_COUNT_COMPLETED;

  constructor(public payload: BulkResponse[], public duringOngoingDistribution: boolean = false) {}
}

export class CreateManualCountFailed implements Action {
  readonly type = CREATE_MANUAL_COUNT_FAILED;

  constructor(public payload: object) {}
}

export class SetHourlyCountFailed implements Action {
  readonly type = SET_HOURLY_COUNT_FAILED;

  constructor(public payload: object, public duringOngoingDistribution: boolean = false) {}
}

export class SetBulkHourlyCountFailed implements Action {
  readonly type = SET_BULK_HOURLY_COUNT_FAILED;

  constructor(public payload: object, public duringOngoingDistribution: boolean = false) {}
}

export class ManualCountGetDistributionLoading implements Action {
  readonly type = MANUAL_COUNT_GET_DISTRIBUTION_LOADING;

  constructor(public params: IDistributionData) {}
}

export class ManualCountGetDistributionLoaded implements Action {
  readonly type = MANUAL_COUNT_GET_DISTRIBUTION_LOADED;

  constructor(public response: IDistributionResponse[]) {}
}

export class ManualCountSetDistributionLoading implements Action {
  readonly type = MANUAL_COUNT_SET_DISTRIBUTION_LOADING;

  constructor(public params: IDistributionRequest, public hideLoaderAfterSuccess: boolean = false) {}
}

export class ManualCountSetDistributionLoaded implements Action {
  readonly type = MANUAL_COUNT_SET_DISTRIBUTION_LOADED;
}

export class GetOeeForDistributionValidationLoading implements Action {
  readonly type = GET_OEE_FOR_DISTRIBUTION_VALIDATION_LOADING;

  constructor(public workOrderId: number) {}
}

export class GetOeeForDistributionValidationLoaded implements Action {
  readonly type = GET_OEE_FOR_DISTRIBUTION_VALIDATION_LOADED;

  constructor(public oeeData: GetManyResponseInterface<IPeriodicOeeCalculationData>) {}
}

export class GetScrapCountCommentOfProductionCount implements Action {
  readonly type = GET_SCRAP_COUNT_COMMENT_OF_PRODUCTION_COUNT;

  constructor(public productionCountId: number) {}
}

export class GetScrapCountCommentOfProductionCountLoaded implements Action {
  readonly type = GET_SCRAP_COUNT_COMMENT_OF_PRODUCTION_COUNT_LOADED;

  constructor(public response: GetManyResponseInterface<TCommentResponseDto>) {}
}

export class DeleteScrapCountComment implements Action {
  readonly type = DELETE_SCRAP_COUNT_COMMENT;

  constructor(public commentId: number) {}
}

export class DeleteScrapCountCommentDone implements Action {
  readonly type = DELETE_SCRAP_COUNT_COMMENT_DONE;
}

export type ManualCountActions =
  | SetManualCountFormSubmitted
  | SetHourlyCount
  | SetHourlyCountCompleted
  | SetHourlyCountFailed
  | SetBulkHourlyCount
  | SetBulkHourlyCountCompleted
  | SetBulkHourlyCountFailed
  | CreateManualCount
  | CreateManualCountCompleted
  | CreateManualCountFailed
  | SaveManualCountComments
  | ManualCountCommentsSaved
  | SaveScrapCountComment
  | ScrapCountCommentSaved
  | FetchDataError
  | ResetManualCountStore
  | ManualCountGetDistributionLoading
  | ManualCountGetDistributionLoaded
  | ManualCountSetDistributionLoading
  | ManualCountSetDistributionLoaded
  | GetOeeForDistributionValidationLoading
  | GetOeeForDistributionValidationLoaded
  | GetScrapCountCommentOfProductionCount
  | GetScrapCountCommentOfProductionCountLoaded
  | DeleteScrapCountComment
  | DeleteScrapCountCommentDone;
