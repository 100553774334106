<div
  *ngIf="!openedFromProductionReview && modalType === countModalType.finalDistribution && isNegativeScrapCount"
  class="alert alert-missing icons-alert"
>
  <div class="row">
    <div class="col d-flex align-items-center">
      <p>{{ 'main.workOrder.workOrderManuelCountForm.negativeScrapCountWarning' | translate }}</p>
    </div>
  </div>
</div>

<div *ngIf="openedFromProductionReview" class="row">
  <div class="col-lg-8">
    <h3>
      <span data-automation-id="start-end-period"
        >{{ workOrderCard.start | momentDate : timeFormat }} - {{ workOrderCard.end | momentDate : timeFormat }}</span
      >
    </h3>
  </div>
  <div *ngIf="isPastHourOfProductionReview" class="col-lg-4 text-right">
    <scw-mat-button
      *ngIf="!isApprovedCountOfProductionReview"
      size="sm"
      type="standard"
      (onClick)="approveOrRejectHourlyCount(true)"
      >{{ 'main.workOrder.manuelCountHistoryTable.confirmHourlyCount' | translate }}</scw-mat-button
    >
    <scw-mat-button
      *ngIf="isApprovedCountOfProductionReview"
      size="sm"
      type="standard"
      (onClick)="approveOrRejectHourlyCount(false)"
      >{{ 'main.workOrder.manuelCountHistoryTable.backToSystemCalculation' | translate }}</scw-mat-button
    >
  </div>
</div>
<div class="row">
  <div
    class="col-lg-12 text-right m-auto pb-2"
    [hidden]="openedFromProductionReview || modalType !== countModalType.countEntry"
  >
    <scw-mat-button-group
      size="xs"
      [buttons]="countModeButtonGroup"
      [className]="['d-inline-block']"
      [(inputModel)]="countMode"
      (inputModelChange)="this.chooseWhichDataToRetrieve()"
    ></scw-mat-button-group>
  </div>
  <div class="col-6 pb-2">
    <app-detail-card [content]="productCard"></app-detail-card>
  </div>
  <div class="col-6 pb-2">
    <app-detail-card
      [content]="goodScrapCountCard"
      [hasPenIcon]="isOngoingDistributionAvailable"
      (onPenIconClicked)="openOngoingWoDistributionModal(ongoing_distribution_modal)"
    ></app-detail-card>
  </div>
</div>
<div class="col-md-12 info-message" *ngIf="showTimestampMismatchWarning && !openedFromProductionReview">
  <div class="row">
    <div class="col-xs-1">
      <em class="fas fa-info-circle text-gray timestamp-mismatch-icon"></em>
    </div>
    <div class="col-md-11">
      <p class="mb-0">
        {{ 'main.workOrder.workOrderManuelCountForm.timestampMismatchWarning' | translate }}
      </p>
    </div>
  </div>
</div>
<div class="col-md-12 info-message" *ngIf="showInvalidatedByApprovedCountWarning">
  <div class="row">
    <div class="col-xs-1">
      <em class="fas fa-info-circle text-gray timestamp-mismatch-icon"></em>
    </div>
    <div class="col-md-11">
      <p class="mb-0">
        {{ 'main.workOrder.workOrderManuelCountForm.invalidatedByConfirmedCountWarning' | translate }}
      </p>
    </div>
  </div>
</div>
<div class="col-md-12 info-message" *ngIf="showCountIsDeletedWarning && isCountCompareMode">
  <div class="row">
    <div class="col-xs-1">
      <em class="fas fa-info-circle text-gray timestamp-mismatch-icon"></em>
    </div>
    <div class="col-md-9">
      <p class="mb-0">
        {{ 'main.workOrder.workOrderManuelCountForm.countIsDeletedWarning' | translate }}
      </p>
    </div>
  </div>
</div>
<div class="datatable-buttons">
  <div class="row m-b-5">
    <div class="col-lg-3 m-auto">
      <div class="left-grp" [hidden]="isCountCompareMode">
        <scw-mat-small-button
          *ngIf="countMode === ECountMode.hourly"
          [disabled]="tableData.length === 0"
          (onClick)="selectOrUnselectAll(selectableTableDataLength !== selectedItemIds.length)"
        >
          {{
            selectableTableDataLength === selectedItemIds.length && selectableTableDataLength !== 0
              ? ('general.unselectAll' | translate)
              : ('general.selectAll' | translate)
          }}
        </scw-mat-small-button>
        <scw-mat-button
          size="xs"
          type="standard"
          [disabled]="
            selectedItemIds.length !== 1 || configuration.isWorkOrderFinalized || configuration.isWorkOrderCanceled
          "
          [iconButton]="true"
          (onClick)="fetchScrapCommentAndOpenEditModal(quantity_edit_modal)"
          ><em class="fas fa-pen"></em
        ></scw-mat-button>
        <scw-mat-button
          size="xs"
          type="standard"
          [disabled]="
            !selectedItemIds.length ||
            isDeleteButtonDisabled ||
            configuration.isWorkOrderFinalized ||
            configuration.isWorkOrderCanceled
          "
          [iconButton]="true"
          (onClick)="openDeleteModal(delete_modal)"
          ><em class="fas fa-trash"></em
        ></scw-mat-button>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="right-grp">
        <ng-template [ngIf]="countMode !== ECountMode.hourly">
          <scw-mat-checkbox
            [(inputModel)]="isShowResultsWithCommentsOnlyChecked"
            (onChange)="chooseWhichDataToRetrieve()"
          >
            {{ 'main.workOrder.workOrderManuelCountForm.showOnlyCommentedRecords' | translate }}
          </scw-mat-checkbox>
          <scw-mat-button
            size="sm"
            type="standard"
            (onClick)="openQuantityModal(quantity_edit_modal, ETableModalTypes.add)"
            data-automation-id="add-count-btn"
            [disabled]="!!(configuration.isWorkOrderFinalized || configuration.isWorkOrderCanceled)"
          >
            <ng-container *ngIf="this.isPastHourOfProductionReview; else add_count_text">
              {{ 'main.workOrder.manuelCountHistoryTable.setHourlyCount' | translate }}
            </ng-container>

            <ng-template #add_count_text>
              <em class="fas fa-plus"></em>
              {{ 'general.addManualCount' | translate }}
            </ng-template>
          </scw-mat-button>
        </ng-template>
        <scw-mat-button
          size="sm"
          type="standard"
          (onClick)="openQuantityModal(quantity_edit_modal, manualCountModalTypes.setFinal)"
          *ngIf="
            (modalType === countModalType.finalDistribution || modalType === countModalType.ongoingDistribution) &&
            !isCountCompareMode
          "
          data-automation-id="set-final-count-btn"
        >
          {{
            (modalType === countModalType.finalDistribution
              ? 'main.workOrder.manuelCountHistoryTable.setFinalCount'
              : 'main.workOrder.manuelCountHistoryTable.setCount'
            ) | translate
          }}
        </scw-mat-button>

        <scw-mat-button
          size="sm"
          type="standard"
          (onClick)="backOriginalCounts()"
          *ngIf="
            (modalType === countModalType.finalDistribution || modalType === countModalType.ongoingDistribution) &&
            isCountCompareMode
          "
        >
          {{ 'main.workOrder.manuelCountHistoryTable.backToOriginalCounts' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <datatable
      noDataText="{{ 'datatable.noData' | translate }}"
      [serverSide]="true"
      [headers]="tableHeader"
      [items]="tableData"
      [itemsCount]="totalManualCountsAmount$"
      [currentPage]="tableQuery.page"
      [rowsPerPage]="tableQuery.pageSize"
      [shouldPaginate]="modalType === countModalType.countEntry"
      (onDataRequest)="onDataRequestHandler($event)"
    >
      <ng-template let-item let-i="index">
        <td
          *ngFor="let header of tableHeader; index as i"
          [ngClass]="{
            'padding-unset': item.activityExistsOnTimestamp !== 1,
            missingDataCell:
              item.isInvalidatedByApproved === 0 && item.activityExistsOnTimestamp !== 1 && item.isSystem === 0,
            'is-invalidated-by-approved-count': item.isInvalidatedByApproved === 1,
            'is-deletion-done-on-timestamp': item.isDeletionDone && modalType === countModalType.ongoingDistribution,
            'is-changed-by-distribution': item.isChanged,
            'timestamp-min-width': header.value === 'timestamp',
            'negative-scrap-row': item['countEntry2'] < 0 || item['suggestedCountEntry2'] < 0
          }"
        >
          <div *ngIf="i === 0 && !isCountCompareMode">
            <scw-mat-checkbox
              [(inputModel)]="item.isSelected"
              [id]="checkboxIdPrefix + item.elementId"
              [value]="item.id"
              [disabled]="item.isDisabled"
              (onChange)="onQuantityCheckboxChanged($event)"
            >
            </scw-mat-checkbox>
          </div>
          <div
            [ngClass]="[item.activityExistsOnTimestamp !== 1 && item.isSystem === 0 ? 'missingDataCell' : '']"
            *ngIf="header.value !== 'comment'; else elseIf1"
          >
            <ng-container *ngIf="i !== 0 || isCountCompareMode">
              <div class="float-left" [ngClass]="[item.activityExistsOnTimestamp !== 1 ? 'padding-left-12' : '']">
                {{ item[header.value] }}
              </div>
              <i
                *ngIf="header.value === 'timestamp' && item.shiftName"
                [autoClose]="false"
                ngbTooltip="{{ item.shiftName }}"
                container="body"
                class="fas fa-info-circle pl-1"
              ></i>
              <em
                *ngIf="(header.value === 'countEntry1' || header.value === 'countEntry2') && item.isApproved === 1"
                class="pl-1 text-primary fas fa-check-circle"
                [ngClass]="{ 'negative-scrap-row-icon': item['countEntry2'] < 0 }"
              ></em>
            </ng-container>
          </div>
          <ng-template #elseIf1>
            <textarea
              [(ngModel)]="item[header.value]"
              [maxlength]="textMaxLength"
              [name]="header.value"
              (ngModelChange)="onChangeCommentModel(item)"
              [hidden]="item.isSystem"
              [disabled]="configuration.isWorkOrderFinalized || configuration.isWorkOrderCanceled"
              rows="1"
              class="form-control"
              placeholder="{{ 'shiftSummary.table.commentPlaceHolder' | translate }}"
            ></textarea>
          </ng-template>
        </td>
      </ng-template>
    </datatable>
  </div>
</div>

<ng-template #quantity_edit_modal let-modal>
  <scw-mat-form (scwMatFormSubmit)="onSubmit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ addEditWorkOrderCountModalTitle }}
      </h3>
      <button
        type="button"
        class="close"
        onfocus="blur()"
        aria-label="Close"
        (click)="closeQuantityEditModal(countEntry2, countEntry2)"
      >
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div
          *ngIf="
            (countMode === ECountMode.hourly || isPastHourOfProductionReview) && modalType === countModalType.countEntry
          "
          class="col-12"
        >
          <h6 class="scw-header">
            <em class="fas fa-exclamation-circle pr-1 pb-1"></em
            >{{ 'main.workOrder.manuelCountHistoryTable.confirmCountInfo' | translate }}
          </h6>
        </div>
        <div
          class="col-12"
          [hidden]="modalType === countModalType.finalDistribution || modalType === countModalType.ongoingDistribution"
        >
          <scw-mat-datepicker
            label="{{ 'woObservations.modal.label.observedAt' | translate }}"
            [timePicker]="true"
            [singleDatePicker]="true"
            [ranges]="null"
            [showCustomRangeLabel]="false"
            [showClearButton]="false"
            [autoApply]="true"
            [maxDate]="now"
            [disabled]="!quantityInformationForm.timestamp.isEnabled"
            [(inputModel)]="quantityInformationForm.timestamp.value"
            [rules]="quantityFormRules.timestamp"
            [isCustomWeekStart]="true"
            [weekStartDay]="weekStartDay$"
            data-automation-id="quantity-observed-at-field"
          ></scw-mat-datepicker>
        </div>
        <div class="col-12">
          <scw-mat-input
            label="{{ tableHeader[2].name }}"
            [(inputModel)]="quantityInformationForm.countEntry1.value"
            (inputModelChange)="onCountEntryInputChange([countEntry1, countEntry2])"
            [rules]="quantityFormRules.countEntry1"
            #countEntry1
            data-automation-id="countEntry1-field"
          ></scw-mat-input>
        </div>
        <div class="col-12">
          <scw-mat-input
            label="{{ tableHeader[3].name }}"
            [(inputModel)]="quantityInformationForm.countEntry2.value"
            (inputModelChange)="onCountEntryInputChange([countEntry1, countEntry2])"
            [rules]="quantityFormRules.countEntry2"
            #countEntry2
            data-automation-id="countEntry2-field"
          ></scw-mat-input>
        </div>
        <div class="col-12" *ngIf="quantityInformationForm.incrementDistributionMethod.isEnabled">
          <scw-mat-radio-group
            [(inputModel)]="this.quantityInformationForm.incrementDistributionMethod.value"
            [options]="incrementDistributionMethodOptions"
            [alignment]="'vertical'"
            [rules]="quantityInformationForm.incrementDistributionMethod.rules"
            [errorText]="noRunTimeAvailableErrorMessage"
            #incrementDistributionMethod
          ></scw-mat-radio-group>
        </div>
        <div class="col-12" *ngIf="quantityInformationForm.commentMessage.isEnabled">
          <scw-mat-textarea
            label="{{ 'modal.label.scrapCountComment' | translate }}"
            [block]="true"
            [rows]="5"
            [(inputModel)]="quantityInformationForm.commentMessage.value"
            [rules]="quantityInformationForm.commentMessage.rules"
            #scrapCountComment
          ></scw-mat-textarea>
          <scw-mat-select
            label="{{ 'modal.label.scrapCategorization' | translate }}"
            searchBehavior="delayed"
            [searchPreventSameSearchString]="false"
            [enableSearchResultList]="true"
            [data]="tags$"
            [singleSelection]="false"
            [enableSearchFilter]="true"
            [enableCheckAll]="false"
            [showCheckbox]="true"
            [badgeShowLimit]="1"
            [searchMaxLength]="40"
            [(inputModel)]="quantityInformationForm.tags.value"
            (onSearch)="onTagsSearch($event)"
            (onCreate)="onTagCreate($event)"
            [createItemLabel]="createItemLabelMessage"
            [createComponent]="true"
            [clearSearchOnOpen]="true"
          >
            <ng-template let-item>
              <div class="d-flex option-row">
                <div class="d-inline-block color-badge" style="background-color: {{ item.backgroundColor }}"></div>
                <label
                  class="d-flex align-items-center mb-0 text-dark max-width-85"
                  [ngClass]="item.disabled === true ? 'text-color-light-gray' : 'text-dark'"
                  >{{ item.name }}</label
                >
              </div>
            </ng-template>
          </scw-mat-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button (onClick)="closeQuantityEditModal(countEntry2, countEntry2)" type="standard">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true" data-automation-id="save-changes-btn">
          {{ 'general.saveChanges' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
<ng-template #delete_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      <ng-template [ngIf]="selectedItemIds.length > 1" [ngIfElse]="singleDeleteHeader">
        {{ 'modal.header.deleteManualCounts' | translate }}
      </ng-template>
      <ng-template #singleDeleteHeader>
        {{ 'modal.header.deleteManualCount' | translate }}
      </ng-template>
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          <ng-template [ngIf]="selectedItemIds.length > 1" [ngIfElse]="singleDelete">
            {{ 'modal.workOrderManualCount.deleteBulkInfo.label' | translate : { count: selectedItemIds.length } }}
          </ng-template>
          <ng-template #singleDelete>
            {{ 'modal.workOrderManualCount.deleteInfo.label' | translate }}
          </ng-template>
          <ng-template class="scw-header" [ngIf]="countMode === ECountMode.hourly">
            {{ 'modal.workOrderManualCount.deleteHourlyInfo.label' | translate }}
          </ng-template>
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="onDeleteConfirm()">
        {{ 'general.delete' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #unsaved_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.applyChanges' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onCancelConfirmationModel()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <h6 class="scw-header">
        {{ 'pageConfiguration.unsavedComments.label' | translate }}
      </h6>
    </div>
  </div>
  <div class="modal-footer">
    <div class="mr-auto">
      <scw-mat-button (onClick)="doActionAfterConfirmation()" type="danger">
        {{ 'general.dontSave' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="onCancelConfirmationModel()" type="standard">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="saveWorkOrderManualCountComments()">
        {{ 'general.save' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #ongoing_distribution_modal let-modal>
  <scw-mat-form (scwMatFormSubmit)="onOngoingDistributionSubmit()">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'main.workOrder.manuelCountHistoryTable.setCount' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="closeOngoingDistributionModal()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body work-order-manual-count-table">
      <div>
        <work-order-manual-count
          [countMode]="ECountMode.hourly"
          [configuration]="configuration"
          [modalType]="countModalType.ongoingDistribution"
          (setDistributionRequestParams)="setOngoingWoDistribution($event)"
        >
        </work-order-manual-count>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-left">
        <scw-mat-button (onClick)="closeOngoingDistributionModal()" type="standard">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
      </div>
      <div class="buttons-right">
        <scw-mat-button [isSubmitButton]="true">
          {{ 'main.workOrder.manuelCountHistoryTable.confirmCount' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
