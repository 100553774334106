import { Action } from '@ngrx/store';
import { IActivityTimelineRequestInterface } from './activity-timeline.model';
import { ActivityTimelineInterface } from '../../../view/reports/activity-timeline/activity-timeline.model';

export enum ActivityTimelineActionTypes {
  ActivityTimeLineDataLoading = '[ACTIVITY_TIMELINE] Activity Timeline Data Loading',
  ActivityTimeLineDataLoaded = '[ACTIVITY_TIMELINE] Activity Timeline Data Loaded',
  ActivityTimeLineFetchData = '[ACTIVITY_TIMELINE] Activity Timeline Fetch Data',
  GetLinesActivityTimelineLoading = '[ACTIVITY_TIMELINE] Get Lines Activity Timeline Loading',
  GetLinesActivityTimelineLoaded = '[ACTIVITY_TIMELINE] Get Lines Activity Timeline Loaded',
  FetchError = '[ACTIVITY_TIMELINE] Fetch Error',
}

export class ActivityTimeLineDataLoading implements Action {
  readonly type = ActivityTimelineActionTypes.ActivityTimeLineDataLoading;

  constructor(public activityTimeline: IActivityTimelineRequestInterface) {}
}

export class ActivityTimeLineDataLoaded implements Action {
  readonly type = ActivityTimelineActionTypes.ActivityTimeLineDataLoaded;

  constructor(public payload: ActivityTimelineInterface[]) {}
}

export class ActivityTimeLineFetchData implements Action {
  readonly type = ActivityTimelineActionTypes.ActivityTimeLineFetchData;

  constructor(public activityTimeline: IActivityTimelineRequestInterface, public totalCount: number) {}
}

export class GetLinesActivityTimelineLoading implements Action {
  readonly type = ActivityTimelineActionTypes.GetLinesActivityTimelineLoading;

  constructor(public payload: IActivityTimelineRequestInterface) {}
}

export class GetLinesActivityTimelineLoaded implements Action {
  readonly type = ActivityTimelineActionTypes.GetLinesActivityTimelineLoaded;

  constructor(public data: ActivityTimelineInterface[]) {}
}

export class FetchError implements Action {
  readonly type = ActivityTimelineActionTypes.FetchError;

  constructor(public payload: any) {}
}

export type ActivityTimelineActions =
  | ActivityTimeLineDataLoading
  | ActivityTimeLineDataLoaded
  | ActivityTimeLineFetchData
  | GetLinesActivityTimelineLoading
  | GetLinesActivityTimelineLoaded
  | FetchError;
