import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildDecorator } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScwMatButtonModule } from '../scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IFile, EFileType } from '../../../store/file-upload/file-upload.model';
import { ImagePreviewModalComponent } from '../file-upload/image-preview-modal/image-preview-modal.component';
import * as _ from 'lodash';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { filter, Subscription, take } from 'rxjs';
import { CameraModalComponent } from '../file-upload/camera-modal/camera-modal.component';
import { MomentDatePipe } from '../../../standalone/pipes/moment-date.pipe';
import { ScwMatFormModule } from '../scw-mat-ui/scw-mat-form/scw-mat-form.module';
import { ScwMatModalSeparatorModule } from '../scw-mat-ui/scw-mat-modal/scw-mat-modal-separator/scw-mat-modal-separator.module';
import { ScwMatSelectModule } from '../scw-mat-ui/scw-mat-select/scw-mat-select.module';
import { ScwMatTextareaModule } from '../scw-mat-ui/scw-mat-textarea/scw-mat-textarea.module';
import { ScwMatInputModule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.module';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { Store } from '@ngrx/store';
import * as ActionTrackerActions from '../../../store/action-tracker/action-tracker.actions';
import { ScwMatSelectRule } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import {
  EItemType,
  TBoardMemberResponse,
  TBoardResponse,
  TSelectedBoard,
  TSelectedBoardMember,
} from 'src/app/store/action-tracker/action-tracker.types';
import { ELoadStatus } from '../../../../constants.model';
import { ScwMatInputRule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ScwMatTextAreaRule } from '../scw-mat-ui/scw-mat-textarea/scw-mat-textarea.model';
import { ToastHelperService } from '../../service/toast/toast.helper.service';
import { FormHelperService } from '../../service/form/form.helper.service';
import { ScwMatDatepickerModule } from '../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.module';
import {
  ScwMatDatepickerReturnInterface,
  ScwMatDatepickerRule,
} from '../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import * as moment from 'moment';
import { IActionTrackerState } from '../../../store/action-tracker/action-tracker.reducer';
import { User } from '../../../store/user/model';
import { smallModalScrollable } from '../../../../constants';
import { IDropdownOption } from './create-quick-issue-action.type';

type TFormRules = {
  name: ScwMatInputRule[];
  description: ScwMatTextAreaRule[];
  board: ScwMatSelectRule[];
  assignee: ScwMatSelectRule[];
  dueDate: ScwMatDatepickerRule[];
  itemType: ScwMatSelectRule[];
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MomentDatePipe,
    ScwMatButtonModule,
    ScwMatFormModule,
    ScwMatModalSeparatorModule,
    ScwMatSelectModule,
    ScwMatTextareaModule,
    ScwMatInputModule,
    TranslateModule,
    FileUploadModule,
    ScwMatDatepickerModule,
  ],
  selector: 'app-create-quick-issue-action-modal',
  templateUrl: 'create-quick-issue-action-modal.component.html',
  styleUrls: ['create-quick-issue-action-modal.component.scss'],
})
export class CreateQuickIssueActionModalComponent implements OnInit, OnDestroy {
  @ViewChild('quick_issue_action_modal')
  public readonly modelRef: ViewChildDecorator;

  @ViewChild('image_preview_modal', { static: false })
  public readonly imagePreviewModalComponent: ImagePreviewModalComponent;

  public readonly formRules: TFormRules = {
    name: [this.formHelperService.getRequiredFormRule(), this.formHelperService.getMaxLengthFormRule(255)],
    description: [],
    board: [this.formHelperService.getRequiredFormRule()],
    assignee: [],
    itemType: [this.formHelperService.getRequiredFormRule()],
    dueDate: [],
  };

  public selectedBoards: TSelectedBoard[] = [];
  public selectedAssignees: TSelectedBoardMember[] = [];
  public readonly EFileType = EFileType;
  public files: IFile[] = [];
  public deletedFile: IFile;
  public selectedImage: IFile = null;
  public isPreviewModalClicked: boolean = false;
  public isUserExistInActionTracker: boolean | null = null;
  public fileDeleteConfirmationModalRef: NgbModalRef;
  public readonly subscriptions: Subscription[] = [];
  public boards$: TBoardResponse[] = [];
  public boardMembers$: TBoardMemberResponse[] = [];
  public readonly itemTypes: IDropdownOption<EItemType>[] = [
    { id: EItemType.issue, name: this.translate.instant('actionTracker.issue') },
    { id: EItemType.action, name: this.translate.instant('actionTracker.action') },
  ];

  public name: string = '';
  public description: string = '';
  public selectedDueDate: ScwMatDatepickerReturnInterface | null = null;
  public selectedItemTypes: IDropdownOption<EItemType>[] = [this.itemTypes[1]];

  public isBoardsLoading: boolean = false;
  public isBoardMembersLoading: boolean = false;

  private isRecommendedBoardSelected: boolean = false;
  private dateTimeFormat$: string;
  private timezone$: string;
  private locale$: string;

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly toast: ToastHelperService,
    private readonly translate: TranslateService,
    private readonly formHelperService: FormHelperService,
  ) {}

  public ngOnInit(): void {
    this.dispatchInitialActions();
    this.createSubscriptions();
    this.deletedFile = null;
    this.isPreviewModalClicked = false;
  }

  public openPreviewModal(file: IFile, fileNumber: number): void {
    this.isPreviewModalClicked = true;
    this.imagePreviewModalComponent.openImagePreviewModal(
      file.folderId ? file.folderId : undefined,
      this.files,
      fileNumber,
    );
  }

  public onBoardDropdownDeselectAll(): void {
    this.boardMembers$ = [];
    this.selectedAssignees = [];
    this.selectedBoards = [];
  }

  public onSelectedBoardChange(): void {
    if (!this.selectedBoards || this.selectedBoards.length === 0) {
      this.selectedAssignees = [];
      return;
    }

    this.store.dispatch(ActionTrackerActions.getActionTrackerBoardMembers({ id: this.selectedBoards[0].id }));
  }

  public showDeleteItemModal(content: TemplateRef<unknown>, file?: IFile): void {
    this.deletedFile = this.files.find((item: IFile): boolean => item === file);

    this.fileDeleteConfirmationModalRef = this.ngbModal.open(content, smallModalScrollable);
  }

  public deleteImage(): void {
    if (!_.isNil(this.deletedFile) && this.deletedFile.folderId) {
      this.imagePreviewModalComponent.onDelete(this.deletedFile);
    }

    _.remove(this.files, this.deletedFile);
    this.fileDeleteConfirmationModalRef?.close();
    this.selectedImage = null;
  }

  public openModal(): void {
    this.ngbModal.open(this.modelRef);
  }

  public onFormSubmit(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    let dueDate: string | undefined;

    if (this.selectedDueDate) {
      dueDate = moment(this.selectedDueDate.startDate, this.dateTimeFormat$, this.locale$)
        .tz(this.timezone$, true)
        .utc()
        .toISOString();
    }

    this.store.dispatch(
      ActionTrackerActions.createActionTrackerItem({
        payload: {
          name: this.name.trim(),
          description: this.description?.trim(),
          boardId: this.selectedBoards[0].id,
          assigneeId: this.selectedAssignees?.[0]?.id ?? undefined,
          isQuick: true,
          itemType: this.selectedItemTypes?.[0]?.id ?? EItemType.issue,
          ...(dueDate ? { dueDate } : {}),
        },
      }),
    );
  }

  public closeModal(): void {
    this.store.dispatch(ActionTrackerActions.resetState());
    this.activeModal.close();
  }

  public deleteButtonClickedFromPreview(deletedFile: IFile): void {
    this.deletedFile = deletedFile;
    this.isPreviewModalClicked = false;

    _.remove(this.files, (file: IFile): boolean =>
      file.id
        ? file.id === deletedFile.id
        : file.fileOriginalName
        ? file.fileOriginalName === deletedFile.fileOriginalName
        : file.original === deletedFile.original,
    );
  }

  public onBoardSearch(searchKeyword: string): void {
    if (searchKeyword.length >= 40) {
      return;
    }

    this.store.dispatch(ActionTrackerActions.getActionTrackerBoards({ searchKeyword }));
  }

  public onAssigneeSearch(searchKeyword: string): void {
    if (searchKeyword.length >= 40) {
      return;
    }

    this.store.dispatch(
      ActionTrackerActions.getActionTrackerBoardMembers({ id: this.selectedBoards[0].id, searchKeyword }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private dispatchInitialActions(): void {
    this.store.dispatch(ActionTrackerActions.getActionTrackerCurrentUser());
  }

  private createSubscriptions(): void {
    let isInitialCall: boolean = true;

    this.subscriptions.push(
      this.store.select('user').subscribe((state: User): void => {
        this.timezone$ = state.timezone;
        this.dateTimeFormat$ = state.dateTimeFormat;
        this.locale$ = state.locale;
      }),
      CameraModalComponent.filesSubject.subscribe((files: IFile[]): void => {
        this.files = _.concat(this.files, files);
      }),
      this.store
        .select('actionTrackerStore')
        .pipe(
          filter((state: IActionTrackerState): boolean => state.recommendedBoardLoadStatus === ELoadStatus.Success),
          take(1),
        )
        .subscribe((state: IActionTrackerState): void => {
          if (state.recommendedBoard === null) {
            return;
          }

          this.selectedBoards = [{ id: state.recommendedBoard.boardId, name: state.recommendedBoard.boardName }];
          this.store.dispatch(
            ActionTrackerActions.getActionTrackerBoardMembers({ id: state.recommendedBoard.boardId }),
          );
        }),
      this.store.select('actionTrackerStore').subscribe((state: IActionTrackerState): void => {
        this.isBoardMembersLoading = state.boardMembersLoadStatus === ELoadStatus.Loading;
        this.isBoardsLoading = state.boardsLoadStatus === ELoadStatus.Loading;

        if (state.boardsLoadStatus === ELoadStatus.Failure) {
          this.boards$ = [];
        }

        if (state.boardsLoadStatus === ELoadStatus.Success) {
          this.boards$ = state.boards;

          if (!this.isRecommendedBoardSelected && state.boards.length > 0) {
            this.store.dispatch(ActionTrackerActions.getActionTrackerRecommendedBoard());
            this.isRecommendedBoardSelected = true;
          }
        }

        if (state.boardMembersLoadStatus === ELoadStatus.Failure) {
          this.boardMembers$ = [];
        }

        if (state.boardMembersLoadStatus === ELoadStatus.Success) {
          this.boardMembers$ = state.boardMembers;
        }

        if (state.createItemStatus === ELoadStatus.Success && state.createdItem) {
          if (this.files.length > 0) {
            this.store.dispatch(
              ActionTrackerActions.uploadActionTrackerItemAttachments({
                itemId: state.createdItem.id,
                boardId: state.createdItem.boardId,
                files: this.files.map((file: IFile) => this.convertBase64ToFile(file.original, file.fileOriginalName)),
              }),
            );
          }

          this.toast.showPersistentToastMessage(
            true,
            this.translate.instant('general.success'),
            `${this.translate.instant('actionTracker.itemCreated')}`,
            { url: state.createdItem.url, label: state.createdItem.key },
          );

          this.closeModal();
        }

        if (
          state.hasActionTrackerUserLoadStatus === ELoadStatus.Success &&
          state.hasActionTrackerUser !== this.isUserExistInActionTracker &&
          this.boards$.length === 0
        ) {
          if (!isInitialCall) {
            return;
          }

          this.isUserExistInActionTracker = state.hasActionTrackerUser;

          if (this.isUserExistInActionTracker && this.boardMembers$.length === 0) {
            this.store.dispatch(ActionTrackerActions.getActionTrackerBoards({}));
          }

          isInitialCall = false;
        }
      }),
    );
  }

  private convertBase64ToFile(base64DataURI: string, fileName: string): File {
    const arr = base64DataURI.split(',');
    const mimeType = arr[0].match(/:(.*?);/)?.[1] || '';
    const base64 = arr[1];
    const byteString = atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new File([uint8Array], this.generateFileName(mimeType.startsWith('image/') ? 'png' : 'pdf', fileName), {
      type: mimeType,
    });
  }

  private generateFileName(extension: string, fileName?: string): string {
    let isFileNameHasExtension: boolean = false;

    const base: string = '%timestamp%%file_name%%extension%';
    const fileNameParts: string[] | null = fileName?.split('.') ?? null;

    if (
      fileNameParts &&
      fileNameParts.length >= 2 &&
      ['png', 'pdf'].includes(fileNameParts[fileNameParts.length - 1])
    ) {
      isFileNameHasExtension = true;
    }

    return base
      .replace('%timestamp%', fileName ? '' : `${Date.now().toString()}_`)
      .replace('%file_name%', fileName ?? crypto.randomUUID())
      .replace('%extension%', isFileNameHasExtension ? '' : `.${extension}`);
  }
}
