import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Firestore,
  FirestoreError,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Timestamp,
  UpdateData,
  WriteBatch,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscriber, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { INotificationFeedItem, IUpdatedDocument } from './notification-feed.model';
import { OeeAppState } from 'src/app/store/oee.reducer';
import { selectUserFirebaseConfig } from 'src/app/store/user/user.selectors';
import { ScwFirebaseService } from '../firebase/firebase.service';
import * as PushNotificationActions from '../../../store/push-notification/push-notification.actions';
import { FirebaseCredentials } from '../../../store/user/model';

@Injectable({
  providedIn: 'root',
})
export class NotificationFeedService implements OnDestroy {
  public readonly numberOfDaysOfHistory: number = 30;

  private readonly collectionName$: Observable<string> = combineLatest([
    this.firebaseService.getUid(),
    this.store
      .select(selectUserFirebaseConfig)
      .pipe(filter((config: FirebaseCredentials | undefined): config is FirebaseCredentials => config !== undefined)),
  ]).pipe(map(([uid, config]: [string, FirebaseCredentials]) => config.collectionPath.replace('{{uid}}', uid)));

  private readonly firebaseParameters$: Observable<[Firestore, string]> = combineLatest([
    this.firebaseService.getFirestore(),
    this.collectionName$,
  ]);

  private readonly destroy$: Subject<void> = new Subject();

  private readonly notifications$: Subject<readonly INotificationFeedItem[]> = new BehaviorSubject([]);

  constructor(private readonly firebaseService: ScwFirebaseService, private readonly store: Store<OeeAppState>) {
    this.firebaseParameters$
      .pipe(
        switchMap(
          ([firestore, collectionName]: [Firestore, string]) =>
            new Observable<INotificationFeedItem[]>((subscriber: Subscriber<INotificationFeedItem[]>) => {
              const q: Query = query(
                collection(firestore, collectionName),
                where(
                  'createdAt',
                  '>',
                  Timestamp.fromDate(moment.utc().subtract(this.numberOfDaysOfHistory, 'd').toDate()),
                ),
                orderBy('createdAt', 'desc'),
              );
              onSnapshot(q, {
                next(snapshot: QuerySnapshot) {
                  const notifications: INotificationFeedItem[] = snapshot.docs.map((d: QueryDocumentSnapshot) => ({
                    docId: d.id,
                    notification: d.get('notification'),
                    openedAt: d.get('openedAt'),
                    readAt: d.get('readAt'),
                    createdAt: d.get('createdAt'),
                  }));
                  subscriber.next(notifications);
                },
                error(error: FirestoreError) {
                  subscriber.error(error);
                },
              });
            }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(this.notifications$);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getNotifications(): Observable<readonly INotificationFeedItem[]> {
    return this.notifications$;
  }

  public update(id: string, data: UpdateData<INotificationFeedItem>): void {
    this.firebaseParameters$
      .pipe(take(1))
      .subscribe(([firestore, collectionName]: [Firestore, string]) =>
        updateDoc(doc(firestore, `${collectionName}/${id}`), data),
      );
  }

  public bulkUpdate(docs: readonly IUpdatedDocument[]): void {
    this.firebaseParameters$.pipe(take(1)).subscribe(([firestore, collectionName]: [Firestore, string]) => {
      const batch: WriteBatch = writeBatch(firestore);
      docs.forEach((document: IUpdatedDocument) => {
        batch.update(doc(firestore, `${collectionName}/${document.id}`), document.updateData);
      });
      batch.commit();
    });
  }

  public openPushNotificationPermissionModal(): void {
    this.store.dispatch(new PushNotificationActions.OpenPushNotificationPermissionModal());
  }
}
