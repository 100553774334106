import { IObjectWithEntityTranslations } from 'src/app/shared/service/entity-translator/entity-translator.model';
import { EquipmentTaskOutputInterface } from '../../../../store/equipment-task/equipment-task.model';
import { ITreeNodeMetaData } from '../../../../store/settings/tree-nodes/tree-nodes.model';

export interface ITaskTreeNode extends IObjectWithEntityTranslations {
  id: number;
  type: ENodeType;
  title: string;
  order: number;
  icon: string;
  isVirtualGroup: boolean;
  subTitle?: string;
  subIcon?: string;
  taskInfo?: EquipmentTaskOutputInterface;
  treeChartActivityId?: number;
  treeChartParentId?: number;
  meta?: ITreeNodeMetaData;
  hideOnHomePage?: boolean;
  isActive?: boolean;
}

export enum ENodeType {
  Line = 'line',
  Activity = 'activity',
  Folder = 'folder',
  Equipment = 'equipment',
  Task = 'task',
}

export enum ETaskListMode {
  TASK_BASED = 'task_based',
  EQUIPMENT_BASED = 'equipment_based',
}

export interface INodeGroup {
  name: string;
  nodes: ITaskTreeNode[];
}
