import { Action } from '@ngrx/store';
import {
  LaborSchedulerGanttAllItemsResponseInterface,
  ILaborSchedulerItemResponse,
  SaveLaborSchedulerItemInterface,
  ITeamsResponse,
} from '../../shared/service/labor-scheduler/labor-scheduler.interface';
import { TaskType } from '../../view/scheduler/gantt-view/gantt-view.model';
import {
  AllSettledResolvedInterface,
  ResponseArrayInterface,
} from '../../shared/model/interface/generic-api-response.model';
import { CheckInTableQueryParams } from '../../view/reports/check-in-logs/check-in-logs.model';

export enum LaborSchedulerActionTypes {
  LoadLaborSchedulerItems = '[LaborScheduler] Load Labor Scheduler Items',
  LaborSchedulerItemsLoaded = '[LaborScheduler] Labor Scheduler Items Loaded',
  LoadLaborSchedulerGanttAllItems = '[LaborScheduler] Load Labor Scheduler Gantt All Items',
  LaborSchedulerGanttAllItemsLoaded = '[LaborScheduler] Labor Scheduler Gantt All Items Loaded',
  SaveAndDeployLaborSchedulerItems = '[LaborScheduler] Save And Deploy Labor Scheduler Items',
  SaveAndDeployLaborSchedulerItemsCompleted = '[LaborScheduler] Save And Deploy Labor Scheduler Items Completed',
  LoadTeams = '[LaborScheduler] Load Teams',
  TeamsLoaded = '[LaborScheduler] Teams Loaded',
  FetchError = '[LaborScheduler] FetchError',
}

export class LoadLaborSchedulerItems implements Action {
  readonly type = LaborSchedulerActionTypes.LoadLaborSchedulerItems;

  constructor(public scenarioId: number) {}
}

export class LaborSchedulerItemsLoaded implements Action {
  readonly type = LaborSchedulerActionTypes.LaborSchedulerItemsLoaded;

  constructor(public payload: ILaborSchedulerItemResponse[]) {}
}

export class LoadLaborSchedulerGanttAllItems implements Action {
  readonly type = LaborSchedulerActionTypes.LoadLaborSchedulerGanttAllItems;

  constructor(public scenarioId: number, public checkInQuery: CheckInTableQueryParams) {}
}

export class LaborSchedulerGanttAllItemsLoaded implements Action {
  readonly type = LaborSchedulerActionTypes.LaborSchedulerGanttAllItemsLoaded;

  constructor(public payload: LaborSchedulerGanttAllItemsResponseInterface) {}
}

export class SaveAndDeployLaborSchedulerItems implements Action {
  readonly type = LaborSchedulerActionTypes.SaveAndDeployLaborSchedulerItems;

  constructor(
    public scenarioId: number,
    public taskType: TaskType,
    public saveLaborSchedulerItems: SaveLaborSchedulerItemInterface[],
    public lineIds: number[],
    public isDeploy: boolean = false,
  ) {}
}

export class SaveAndDeployLaborSchedulerItemsCompleted implements Action {
  readonly type = LaborSchedulerActionTypes.SaveAndDeployLaborSchedulerItemsCompleted;

  constructor(public payload: AllSettledResolvedInterface[]) {}
}

export class LoadTeams implements Action {
  readonly type = LaborSchedulerActionTypes.LoadTeams;

  constructor(public siteId: number, public lineIds: number[]) {}
}

export class TeamsLoaded implements Action {
  readonly type = LaborSchedulerActionTypes.TeamsLoaded;

  constructor(public payload: ResponseArrayInterface<ITeamsResponse>) {}
}

export class FetchError implements Action {
  readonly type = LaborSchedulerActionTypes.FetchError;

  constructor(public payload: any) {}
}

export type LaborSchedulerActions =
  | LoadLaborSchedulerItems
  | LaborSchedulerItemsLoaded
  | LoadLaborSchedulerGanttAllItems
  | LaborSchedulerGanttAllItemsLoaded
  | SaveAndDeployLaborSchedulerItems
  | SaveAndDeployLaborSchedulerItemsCompleted
  | FetchError;
